import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBInput } from 'mdbreact';
import { FormGroup, Input } from 'reactstrap';
import SidebarComponent from '../../../components/SidebarComponent';
import View from 'react-flux-state';
import ImgAdd from '../../../assets/img/addImage.png';
import DatePicker from 'react-date-picker';
import Navbar from '../../../components/NavBar';
import { toast } from 'react-toastify';
import { Loader } from '../../../components/Loader';
import { calculatePrice } from './promotion-utils';
import ModalComponent from '../../../components/ModalComponent';
import * as R from 'ramda';
import {
  promotionStore,
  PROMOTION_EVENT,
  PROMOTION_ERROR_EVENT,
  PROMOTION_DETAIL_EVENT,
} from './promotion-store';
import { fetchDetailPromo, createPromotion } from './promotion-actions';

class CopyPromotionView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      image: null,
      loading: true,
    };
  }

  componentDidMount() {
    this.subscribe(promotionStore, PROMOTION_EVENT, () => {
      toast.success("you're Promotion will be pending until the admin allows its sale");
      this.props.history.push('/promotion');
    });
    this.subscribe(promotionStore, PROMOTION_ERROR_EVENT, (e) => {
      toast.error(e.messsage);
    });
    this.subscribe(promotionStore, PROMOTION_DETAIL_EVENT, (data) => {
      data.validDate = new Date();
      data.createdDate = new Date();
      data.validDays = [];
      console.log(data);
      this.setState({
        data: data,
        loading: false,
      });
    });
    fetchDetailPromo(this.props.match.params.id);
  }

  onChange = (e) => {
    e.preventDefault();
    const data = this.state.data;
    data[e.target.name] = e.target.value;
    data.salesPrice = calculatePrice(data.originalPrice, data.discount);
    this.setState({
      data: data,
    });
  };

  onImageChange = (e) => {
    let reader = new FileReader();
    const data = this.state.data;
    const image = e.target.files[0];
    const picture = e.target.files[0];
    reader.onloadend = () => {
      data.picture = reader.result;
      this.setState({
        data: data,
        image: picture,
      });
    };
    reader.readAsDataURL(image);
  };

  onFeedChange = (e) => {
    const data = this.state.data;
    data.displayFeed = e.target.checked;
    if (e.target.checked) {
      toast.info(
        'If you want to display in promotions feed, a picture of your promotion is required',
      );
    }
    this.setState({
      data: data,
    });
  };

  onDateChange = (date) => {
    const data = this.state.data;
    data.validDate = date;
    this.setState({
      data,
    });
  };

  onDayChange = (e) => {
    const data = this.state.data;
    if (e.target.checked === false) {
      let index = data.validDays.indexOf(e.target.value);
      data.validDays.splice(index, 1);
    } else {
      data.validDays.push(e.target.value);
    }
  };

  onSubmit = (confirm) => {
    if (confirm) {
      createPromotion(R.clone(this.state.data), this.state.image);
      this.setState({
        loading: true,
      });
    }
  };

  render() {
    const {
      picture,
      name,
      originalPrice,
      discount,
      salesPrice,
      description,
      validDate,
      tags,
    } = this.state.data;

    let image = picture;
    if (picture != null) {
      image = (
        <>
          <label className="imgLabel" htmlFor="upload-photo">
            <img src={picture} alt="default" className="img-promotion" width="auto" />
          </label>
          <input type="file" name="photo" id="upload-photo" onChange={this.onImageChange} />
          <small className="text-center">JPG or PNG format with a maximum of 5mb</small>
        </>
      );
    } else {
      image = (
        <>
          <label className="Customlabel text-center" htmlFor="upload-photo">
            <img src={ImgAdd} alt="default" className="img-fluid img-label" width="20" />
          </label>
          <input type="file" name="photo" id="upload-photo" onChange={this.onImageChange} />
          <small className="text-center">JPG or PNG format with a maximum of 5mb</small>
        </>
      );
    }

    return (
      <React.Fragment>
        <SidebarComponent>
          {this.state.loading === true ? (
            <Loader />
          ) : (
            <>
              <Navbar nameView="New Promotion" />
              <MDBContainer className="body" fluid>
                <MDBRow>
                  <MDBCol md="3">{image}</MDBCol>
                  <MDBCol md="9">
                    <MDBRow>
                      <MDBCol className="display-feed">
                        <div className="check-feed">
                          <label className="checkfeed-container">
                            <small>
                              <input type="checkbox" value="Monday" onClick={this.onFeedChange} />
                              <span className="feed-checkbox" />
                              <p className="font-weight-bold">Display in promotion feed</p>
                            </small>
                          </label>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol>
                        <MDBInput
                          label="name"
                          value={name}
                          name="name"
                          className="mt-0"
                          onChange={this.onChange}
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol>
                        <MDBInput
                          label="Price"
                          name="originalPrice"
                          className="mt-0"
                          value={originalPrice}
                          onChange={this.onChange}
                        />
                      </MDBCol>
                      <MDBCol>
                        <MDBInput
                          label="Discount"
                          className="mt-0"
                          name="discount"
                          value={discount}
                          onChange={this.onChange}
                        />
                      </MDBCol>
                      <MDBCol>
                        <MDBInput
                          label="Customer Price"
                          className="mt-0 total-price"
                          value={salesPrice}
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol>
                        <p className="font-weight-bold">Promotion valid until: </p>
                        <DatePicker
                          className="mt-0"
                          value={validDate}
                          onChange={this.onDateChange}
                        />
                      </MDBCol>
                      <MDBCol>
                        <div>
                          <h6 className="font-weight-bold mb-2">Day Promotion</h6>
                        </div>
                        <div className="d-flex ">
                          <div className="text-center mr-3 ">
                            <h6>MON</h6>
                            <label className="checkbox-container">
                              <small>
                                <input type="checkbox" value="MON" onClick={this.onDayChange} />
                                <span className="custom-checkbox" />
                              </small>
                            </label>
                          </div>
                          <div className="text-center mr-3 ">
                            <h6>TUE</h6>
                            <label className="checkbox-container">
                              <small>
                                <input type="checkbox" value="TUE" onChange={this.onDayChange} />
                                <span className="custom-checkbox" />
                              </small>
                            </label>
                          </div>
                          <div className="text-center mr-3 ">
                            <h6>WED</h6>
                            <label className="checkbox-container">
                              <small>
                                <input type="checkbox" value="WED" onChange={this.onDayChange} />
                                <span className="custom-checkbox" />
                              </small>
                            </label>
                          </div>
                          <div className="text-center mr-3 ">
                            <h6>THU</h6>
                            <label className="checkbox-container">
                              <small>
                                <input type="checkbox" value="THU" onChange={this.onDayChange} />
                                <span className="custom-checkbox" />
                              </small>
                            </label>
                          </div>
                          <div className="text-center mr-3 ">
                            <h6>FRI</h6>
                            <label className="checkbox-container">
                              <small>
                                <input type="checkbox" value="FRI" onChange={this.onDayChange} />
                                <span className="custom-checkbox" />
                              </small>
                            </label>
                          </div>
                          <div className="text-center mr-3 ">
                            <h6>SAT</h6>
                            <label className="checkbox-container">
                              <small>
                                <input type="checkbox" value="SAT" onChange={this.onDayChange} />
                                <span className="custom-checkbox" />
                              </small>
                            </label>
                          </div>
                          <div className="text-center mr-3 ">
                            <h6>SUN</h6>
                            <label className="checkbox-container">
                              <small>
                                <input type="checkbox" />
                                <span
                                  className="custom-checkbox"
                                  value="SUN"
                                  onChange={this.onDayChange}
                                />
                              </small>
                            </label>
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol>
                        <h6 className="font-weight-bold">Description</h6>
                        <FormGroup>
                          <Input
                            type="textarea"
                            name="description"
                            value={description}
                            onChange={this.onChange}
                          />
                        </FormGroup>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol>
                        <MDBInput
                          label="#Tags"
                          name="tags"
                          value={tags}
                          className="mt-0"
                          onChange={this.onChange}
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol className="text-center">
                        <ModalComponent
                          onSubmit={this.onSubmit}
                          picture={this.state.data.picture}
                          displayFeed={this.state.data.displayFeed}
                        />
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </>
          )}
        </SidebarComponent>
      </React.Fragment>
    );
  }
}

export default CopyPromotionView;
