import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';

const TermsConditions = () => (
  <>
    <h1 className="d-flex justify-content-center p-4 ">Terms and Conditions</h1>
    <MDBContainer>
      <MDBRow style={{ textAlign: 'justify' }}>
        <MDBCol md="12" className="justify-content-center">
          <p>
            <u>Terms of Use</u>
          </p>
          <p>
            By entering your email, logging into your account, or accepting notifications, you agree
            to receive personalized Okroo promotions each day.
          </p>
          <p>
            Welcome to the Okroo Site (defined below). By using it, you are agreeing to these Terms
            of Use (defined below). Please read them carefully. If you have any questions,contact us
            at info@okroo.com.
          </p>
          <p>These Terms of Use were last updated on.</p>
          <p>Acceptance of Terms of Use</p>
          <p>
            Okroo, llc. (“<b>Okroo</b>” “<b>we</b>” or “<b>us</b>” or “<b>our</b>”) owns and
            operates the website, www.okroo.com, the mobile and touch versions and any sites we have
            now or in the future that reference these Terms of Use (collectively, “<b>Site</b>”). By
            (a) using the Siteand Okroo’s services through the Site, (b) signing up for an account
            and/or (c) completing a purchase on the Site, you agree to these Terms of Use (defined
            below) and any additional terms applicable to certain programs in which you may elect to
            participate. You also agree to our Privacy Statement,(“<b>Privacy Statement</b>”), and
            acknowledge that you will regularly visit the Terms of Use (defined below) to
            familiarize yourself with any updates. The Privacy Statement, together with these terms
            of use, and any other terms contained herein or incorporated herein by reference, are
            collectively referred to as the “Termsof Use.” The term “using” also includes any person
            or entity that accesses or uses the Site with crawlers, robots, data mining, or
            extraction tools or any other functionality.
          </p>
          <p>
            IF YOU DO NOT AGREE TO THESE TERMS OF USE, IMMEDIATELY STOP USING THE SITE AND DO NOT
            USE ANY OKROO SERVICE, PARTICIPATE IN ANY PROGRAM OR MAKE ANY PURCHASES OFFERED THROUGH
            THE SITE. PLEASE REVIEW THE FOLLOWING SECTIONS OF THESE TERMS OF USE CAREFULLY.
          </p>
          <p>1. About the Site</p>
          <p>
            The Site is a platform through which we market and sell Promotions (“
            <b>Promotions of Merchant offerings</b>”) from participating Merchants (“Merchants”)
            directly to consumers and potential customers to be redeemed at the participating
            merchant venue or place of business. Merchants are the sellers and issuers of the
            Merchant Offerings and are solely responsible to you for the care, quality, and delivery
            of the goods and services provided.
          </p>
          <p>
            Certain Promotions, other available programs and pricing on the Site may change atany
            time in Okroo’s sole discretion, without notice.
          </p>
          <p>2. Ownership of the Site</p>
          <p>
            The Site, any content on the Site, and the infrastructure used to provide the Site
            areproprietary to us, our affiliates, Merchants, and other content providers. By using
            the Site and accepting these Terms of Use: (a) Okroo grants you a limited, personal,
            nontransferable, nonexclusive, revocable license to use the Site pursuant to these Terms
            of Use and to any additional terms and policies set forth by Okroo; and (b) you agree
            not to reproduce, distribute, create derivative works from, publicly display, publicly
            perform, license, sell, or re-sell any content, software, products, or services obtained
            from or through the Site without the express permission of Okroo.
          </p>
          <p>3. Use of the Site</p>
          <p>As a condition of your use of the Site, you agree that:</p>
          <p>
            <ul>
              <li>
                You have reached the age of majority in the state or province in which you reside;
              </li>
              <li>You are able to create a binding legal obligation;</li>
              <li>You are not barred from receiving products or services under applicable law;</li>
              <li>
                You will not attempt to use the Site with crawlers, robots, data mining, or
                extraction tools or any other functionality;
              </li>
              <li>Your use of the Site will at all times comply with these Terms of Use;</li>
              <li>
                You will only make legitimate purchases that comply with the letter and spirit of
                the terms of the respective offers;
              </li>
              <li>
                You will only make purchases on the Site for your own use and enjoyment or as a gift
                for another person;
              </li>
              <li>
                You have the right to provide any and all information you submit to the Site, and
                all such information is accurate, true, current, and complete;
              </li>
              <li>
                You will update and correct information you have submitted to the Site, including
                all account information, and ensure that it is accurate at all times (out-of-date
                information will invalidate your account); and,
              </li>
              <li>
                You will only purchase a Promotion for a Merchant offering or participate in other
                available programs through the Site by creating an account on the Site,and any
                purchase will be subject to the applicable Terms of Sale set forth in these Terms of
                Use.
              </li>
            </ul>
          </p>
          <p>4. Access to the Site</p>
          <p>
            Okroo retains the right, at our sole discretion, to deny service or use of the Site or
            an account to anyone at any time and for any reason. While we use reasonable efforts to
            keep the Site and your account accessible, the Site and/or your account may be
            unavailable from time to time. You understand and agree that there may be interruptions
            in service or events, Site access, or access to your account due to circumstances both
            within our control (e.g., routine maintenance) and outside of our control.
          </p>
          <p>5. Modification</p>
          <p>
            We reserve the right at all times to discontinue or modify any part of these Terms of
            Use in our sole discretion. If we make changes that affect your use of the Site or
            ourservices we will post notice of the change on the Terms of Use page. Any changes
            tothese Terms of Use will be effective upon our posting of the notice; provided that
            these changes will be prospective only and not retroactive. If you do not agree to the
            changes, you may close your account and you should not use the Site or any services
            offered through the Site after the effective date of the changes. We suggestthat you
            revisit our Terms of Use regularly to ensure that you stay informed of any changes. You
            agree that posting notice of any changes on the Terms of Use page is adequate notice to
            advise you of these changes, and that your continued use of the Site or our services
            will constitute acceptance of these changes and the Terms of Use as modified.
          </p>
          <p>6. Your Account</p>
          <p>
            You may only create and hold one account on the Site for your personal use. You
            areresponsible for updating and correcting information you have submitted to create
            ormaintain your account. As part of your account settings, you have the option to: (a)
            save, edit, or delete your personal information, including, without limitation, a valid
            credit card; and (b) opt-out of persistent login. You understand and agree that
            Okrooshall have no responsibility for any incident arising out of, or related to, your
            account settings. You must safeguard your password and supervise the use of your
            account. You are solely responsible for maintaining the security of your account and
            maintaining settings that reflect your preferences. We will assume that anyone using the
            Site or transacting through your account is you. You agree that you are solely
            responsible for any activity that occurs under your account.
          </p>
          <p>
            Your account is non-transferrable. You cannot sell, combine, or otherwise share it with
            any other person. Any violation of these Terms of Use, including, without limitation,
            failure to maintain updated and correct information about your account (e.g., valid
            credit card information) will cause your account to fall out of good standing and we may
            cancel your account in our sole discretion. If your account is cancelled, you may
            forfeit any pending, current, or future account credits and any other forms of
            unredeemed value in your account without notice. Upon termination, the provisions of
            these Terms of Use that are, by their nature, intended to survive termination (e.g., any
            disclaimers, all limitations of liability, and all indemnities) shall survive. We also
            reserve the right to change or discontinue any aspect or feature of our services or the
            Site, including, without limitation, requirements for use.
          </p>
          <p>7. Your Conduct</p>
          <p>
            All interactions on the Site must comply with these Terms of Use. To the extent your
            conduct, in our sole discretion, restricts or inhibits any other user from using or
            enjoying any part of the Site, we may limit or terminate your privileges on the Site and
            seek other remedies, including, without limitation, cancellation of your account or
            forfeiture of any forms of unredeemed value in your account.
          </p>
          <p>
            The following activities areprohibitedon the Site and constitute violations of these
            Terms of Use:
          </p>
          <p>
            <ul>
              <li>
                Submitting any content to the Site that:
                <br />
                <br />
                <ul>
                  <li>
                    Violates applicable laws (including, without limitation, intellectual property
                    laws, laws relating to rights of privacy and rights of publicity, and laws
                    related to defamation);
                  </li>
                  <li>
                    Contains personal information, except when we expressly ask you to provide such
                    information;
                  </li>
                  <li>Contains viruses or malware;</li>
                  <li>
                    Offers unauthorized downloads of any copyrighted, confidential, or private
                    information;
                  </li>
                  <li>Has the effect of impersonating others;</li>
                  <li>
                    Contains messages by non-spokesperson employees of Okroo purporting to speak on
                    behalf of Okroo or provides confidential information concerning Okroo;
                  </li>
                  <li>Contains chain letters of any kind;</li>
                  <li>
                    Is purposely inaccurate, commits fraud, or falsifies information in connection
                    with your Okroo account or to create multiple Okroo accounts; or
                  </li>
                  <li>
                    Is protected by copyright, trademark, or other proprietary right withoutthe
                    express permission of the owner of the copyright, trademark or other proprietary
                    right.
                  </li>
                </ul>
                <br />
              </li>
              <li>
                Attempting to do or actually doing any of the following:
                <br />
                <br />
                <ul>
                  <li>
                    Accessing data not intended for you, such as logging into a server or an account
                    which you are not authorized to access;
                  </li>
                  <li>
                    Scanning or monitoring the Site for data gathering purposes in an effort to
                    track sales, usage, aggregate offering information, pricing information, or
                    similar data;
                  </li>
                  <li>
                    Scanning or testing the security or configuration of the Site or breaching
                    security or authentication measures; or
                  </li>
                  <li>
                    Interfering with service to any user in any manner, including, without
                    limitation,by means of submitting a virus to the Site or attempting to overload,
                    “flood,” “spam,” “mail bomb,” or “crash” the Site.
                  </li>
                </ul>
                <br />
              </li>
              <li>
                Using any of the following:
                <br />
                <br />
                <ul>
                  <li>
                    Frames, framing techniques, or framing technology to enclose any content
                    included on the Site without our express written permission;
                  </li>
                  <li>
                    Any Site content, including, without limitation, User Content (defined below),
                    in any meta tags or any other “hidden text” techniques or technologies without
                    our express written permission;
                  </li>
                  <li>
                    The Site or any of its contents to advertise or solicit, for any commercial,
                    political, or religious purpose or to compete, directly or indirectly, with
                    Okroo; or
                  </li>
                  <li>
                    The Site or any of its resources to solicit consumers, Merchants, or other
                    third-parties to become users or partners of other online or offline services
                    directly or indirectly competitive or potentially competitive with Okroo,
                    including, without limitation, aggregating current or previously offered deals.
                  </li>
                </ul>
                <br />
              </li>
              <li>
                Collecting any of the following:
                <br />
                <br />
                <ul>
                  <li>
                    Content from the Site, including, without limitation, in connection with current
                    or previously offered deals, and featuring such content to consumers in any
                    manner that diverts traffic from the Site without our express written
                    permission; or{' '}
                  </li>
                  <li>
                    Personal Information (defined in our Privacy Statement), User Content (defined
                    in Section 12 below), or content of any consumers or Merchants
                  </li>
                </ul>
                <br />
              </li>
              <li>
                Engaging in any of the following:
                <br />
                <br />
                <ul>
                  <li>
                    Tampering or interfering with the proper functioning of any part, page, or area
                    of the Site or any functions or services provided by Okroo;
                  </li>
                  <li>
                    Taking any action that places excessive demand on our services or imposes, or
                    may impose, an unreasonable or disproportionately large load on our servers or
                    other portion of our infrastructure (as determined in our sole discretion);
                  </li>
                  <li>
                    Reselling or repurposing your access to the Site or any purchases made through
                    the Site;
                  </li>
                  <li>
                    Exceeding or attempting to exceed quantity limits when purchasing Promotions for
                    Merchant Offerings, or otherwise using any Okroo account to purchase Promotions
                    for resale or for speculative, false, fraudulent, or any other purpose not
                    expressly permitted by these Terms of Use and the terms of a specific offer on
                    the Site;
                  </li>
                  <li>
                    Accessing, monitoring, or copying any content from the Site using any “robot,”
                    “spider,” “scraper,” or other automated means or any manual process for any
                    purpose without our express written permission;
                  </li>
                  <li>
                    Violating the restrictions in any robot exclusion headers on the Site or
                    bypassing or circumventing other measures employed to prevent or limit access to
                    the Site;
                  </li>
                  <li>
                    Aggregating any current or previously-offered promotions or content orother
                    information from the Site (whether using links or other technical means or
                    physical records associated with purchases made through the Site) with material
                    from other sites or on a secondary site without our express written permission;
                  </li>
                  <li>
                    Deep-linking to any portion of the Site without our express written permission;
                  </li>
                  <li>
                    Hyperlinking to the Site from any other website without our initial and ongoing
                    consent; or
                  </li>
                  <li>
                    Acting illegally or maliciously against the business interests or reputation of
                    Okroo, our Merchants, or our services.
                  </li>
                </ul>
                <br />
              </li>
            </ul>
          </p>
          <p>8. Your Privacy</p>
          <p>
            We take the privacy of your Personal Information (defined in the Privacy Statement)
            seriously. We encourage you to carefully review our Privacy Statement for
            importantdisclosures about ways that we may collect, use, and share personal data and
            your choices. Our Privacy Statement is incorporated in and subject to these Terms of Use
          </p>
          <p>9. Terms of Sale</p>
          <p>
            By purchasing or obtaining any Promotions via the Site, you agree to these Terms of Use,
            including, without limitation, the Terms of Sale
          </p>
          <p>10. Copyright and Trademarks</p>
          <p>
            The Site contains copyrighted material, trademarks, and other proprietary information,
            including, without limitation, text, software, photos, video, graphics, music, and
            sound, and the entire contents of the Site are protected by copyright, trademark, and
            other intellectual property laws of the United States. Okroo owns a copyright in the
            selection, coordination, arrangement, and enhancement of such content, as well as in the
            content original to it. You may not modify, distribute, publish, transmit, publicly
            display, publicly perform, participate in the transfer or sale, create derivative works,
            or in any way exploit any of the content, in whole or inpart. Except as otherwise
            expressly stated under copyright law, no downloading, copying, redistribution,
            retransmission, publication, or commercial exploitation of the content without the
            express permission of Okroo or the copyright owner is permitted. If downloading,
            copying, redistribution, retransmission, or publication of copyrighted material is
            permitted; you will make independent attribution and/or make no changes in or deletion
            of any author attribution, trademark legend, or copyright notice. You acknowledge that
            you do not acquire any ownership rights by downloading copyrighted material. Any
            violation of these restrictions may result in acopyright, trademark, or other
            intellectual property right infringement that may subject you to civil and/or criminal
            penalties.
          </p>
          <p>
            Other marks on the site not owned by Okroo may be under license from the trademark owner
            thereof, in which case such license is for the exclusive benefit and use of Okroo unless
            otherwise stated, or may be the property of their respective owners. You may not use
            Okroo’s name, logos, trademarks or brands, or trademarksor brands of others on the Site
            without Okroo’s express permission
          </p>
          <p>11. User Content</p>
          <p>
            The Site may provide registered users and visitors various opportunities to submit or
            post reviews, opinions, advice, ratings, discussions, comments, messages,
            surveyresponses, and other communications, as well as files, images, photographs, video,
            sound recordings, musical works, and any other content or material submitted or posted
            to the Site (collectively, “User Content”) through forums, bulletin boards, discussion
            groups, chat rooms, surveys, blogs, or other communication facilities thatmay be offered
            on, through or in connection with the Site from time to time. You may be required to
            have an Okroo account to submit User Content
          </p>
          <p>
            If you contribute any User Content, you will not upload, post, or otherwise make
            available on the Site any material protected by copyright, trademark, or other
            proprietary right without the express permission of the owner of the copyright,
            trademark, or other proprietary right. Okroo does not have any express burden or
            responsibility to provide you with indications, markings, or anything else that may aid
            you in determining whether the material in question is copyrighted or trademarked. You
            will be solely liable for any damage resulting from any infringement of copyrights,
            trademarks, proprietary rights, or any other harm resulting from such a submission. In
            addition, if you contribute any User Content, you represent and warrant that: (a) you
            are the creator of the User Content; or (b) if you are acting on behalf of the creator,
            that you have (i) express, advance authorityfrom the creator to submit or post the User
            Content, and that they have waived any moral rights in such User Content, and (ii) all
            rights necessary to grant the licenses and grants in these Terms of Use. You further
            represent and warrant (or, if you are acting on behalf of the creator of the User
            Content, you have ensured that the creator represents and warrants) that the use and
            sharing of the User Content for the purposes you have selected will not violate or
            infringe any copyrights, trademarks, or any other intellectual property rights or rights
            of third parties, including, without limitation, the rights of publicity or privacy. You
            represent and warrant that you will not upload, post, transmit, or otherwise make
            available User Content that is unlawful, harmful, tortious, threatening, abusive,
            harassing, hateful, racist, infringing, pornographic, obscene, violent, misleading,
            defamatory or libelous, invasive of the privacy of another person, or violate any
            third-party rights; and that you will not upload, post, transmit, or otherwise make
            available User Content that contains any material that harbors viruses or any other
            computer codes, files, or programs designed to intercept, misappropriate, interrupt,
            destroy orlimit the functionality of any software or computer equipment.
          </p>
          <p>
            Okroo shall have the sole and absolute right, but not the obligation, to review, edit,
            post, refuse to post, remove, monitor the User Content, and disclose the User Content
            and the circumstances surrounding its transmission to any third-party, at any time, for
            any reason, including, without limitation, to determine compliance withthese Terms of
            Use and any operating rules established by Okroo, as well as to satisfy any applicable
            law, regulation, or authorized government request. Without limiting the foregoing, Okroo
            shall have the right to remove any material from the Communities or any other Okroo
            controlled sites, in its sole discretion. Okroo assumes no liability for any User
            Content or other information that appears or is removed from the Site or elsewhere.
            Okroo has no obligation to use User Content and may not use it at all.
          </p>
          <p>
            In some instances and from time to time, it may be possible to modify or remove the User
            Content submitted or posted through your account. Okroo makes no representations or
            warranties that the User Content you modify or remove will be modified or removed from
            the Site or elsewhere, or that the User Content will cease to appear on the Internet, in
            search engines, social media websites, or in any other form, media or technology
          </p>
          <p>Public Nature of Your User Content</p>
          <p>
            <ul>
              <li>
                You understand and agree that User Content is public. Any person (whether or not a
                user of Okroo’s services) may read your User Content without your knowledge. Please
                do not include any Personal Information in your User Content unless you wish for it
                to be publicly disclosed. Okroo is not responsible for the use or disclosure of any
                Personal Information that you disclose in connection with User Content.
                <br />
                <br />
              </li>
              <li>
                Any User Content of any kind made by you or any third-party is made by the
                respective author(s) or distributor(s) and not by Okroo. Other users may post User
                Content that is inaccurate, misleading, or deceptive. Okroo does not endorse and is
                not responsible for any User Content, and will not be liable for any loss or damage
                caused by your reliance on such User Content. User Content reflects the opinions of
                the person submitting it and may not reflect the opinion of Okroo. Okroo does not
                control or endorse any User Content, and specifically disclaims any liability
                concerning or relating to your contribution of, use of, or reliance on any User
                Content and any actions resulting from your participation in any part of the Site,
                including, without limitation, any objectionable User Content
              </li>
            </ul>
          </p>
          <p>License Grants</p>
          <p>
            Some User Content you submit to Okroo may be displayed or may give you the option to
            display in connection with your Personal Information, or a portion of your Personal
            Information, including, without limitation, your name, initials, username, social
            networking website user account name, image, likeness, preferences, voice, and location.
            You grant Okroo a royalty-free, perpetual, irrevocable, sub licensable, fully paid-up,
            non-exclusive, transferrable, worldwide license and right to use, commercial use,
            display and distribute any Personal Information in connection with your User Content in
            accordance with these Terms of Use, including, without limitation, a right to offer for
            sale and to sell such rights in Personal Information, whether the User Content appears
            alone or as part of other works, and in any form, media or technology, whether now known
            or hereinafter developed, and to sublicense such rights through multiple tiers of sub
            licensees, all without compensation to you. However, Okroo shall have no obligation to
            use your Personal Information in connection with any User Content.
          </p>
          <p>
            As between you and Okroo, you shall retain all ownership rights in and to the User
            Content you submit or post. However, by contributing User Content or other information
            on or through the Site, you grant Okroo a royalty-free, perpetual, irrevocable, sub
            licensable, fully paid-up, non-exclusive, transferrable, worldwide right and license to
            use, reproduce, create derivative works from, publish, edit, translate, distribute,
            perform, display, transmit, offer for sale, and sell the User Content alone or as part
            of other works in any form, media or technology, whether now known or hereafter
            developed, and to sublicense such rights through multiple tiers of sub licensees and
            without compensation to you. You waive any “moral rights” or other rights with respect
            to attribution of authorship or integrity of materials regarding the User Content that
            you may have under any applicable law under any legal theory. Okroo’s license in any
            User Content or Personal Information submitted includes, without limitation, use for
            promotions, advertising, marketing, market research, merchant feedback, quality control,
            or any other lawful purpose.
          </p>
          <p>
            Contributing User Content or other information on or through the Site is limited to
            individuals who are over the age of majority in the state or province in which they
            reside. The Site is designed and intended for adults. By contributing User Content
            orother content on or through the Communities, you affirm that you are over the age of
            majority in the state or province in which you reside. We will promptly delete
            UserContent or other content associated with any account we obtain actual knowledge of
            that is associated with a registered user who is not at least the age of majority in the
            state or province in which he or she resides.
          </p>
          <p>12. Unsolicited Ideas</p>
          <p>
            We do not accept or consider, directly or through any Okroo employee or agent,
            unsolicited ideas of any kind, including, without limitation, ideas or suggestions
            relating to new or improved products, enhancements, names or technologies, advertising
            and marketing campaigns, plans, or other promotions. Do not send us (or any of our
            employees) any unsolicited ideas, suggestions, material, images, or other work in any
            form (“Unsolicited Materials”). If you send us Unsolicited Materials, you understand and
            agree that the following terms will apply, notwithstanding any cover letter or other
            terms that accompany them:
          </p>
          <p>
            <ul>
              <li>
                Okroo has no obligation to review any Unsolicited Materials, nor to keep any
                Unsolicited Materials confidential; and
              </li>
              <li>
                Okroo will own, and may use and redistribute, Unsolicited Materials for any purpose
                without restriction and free of any obligation to acknowledge or compensate you.
              </li>
            </ul>
          </p>
          <p>
            13. Infringement Reporting Procedures and Digital Millennium Copyright Act (DMCA)
            Procedures
          </p>
          <p>
            Infringement Reporting Procedures. If you own copyright, trademark, patent, or other
            intellectual property rights (“ IP Rights Owner ”), or if you are an agent authorized to
            act on the IP Rights Owner’s behalf (“ Authorized Agent ”), and you have a good faith
            belief that material or products on the Site infringe the IP Rights Owner’s copyright,
            trademark, or other intellectual property right, and you would like to bring it to
            Okroo’s attention, you can report your concern(s) by submitting your complaint to
            info@okroo.com.
          </p>
          <p>
            DMCA Procedures. Okroo reserves the right to terminate your or any third-party, right to
            use the Site if such use infringes the copyrights of another. Okroo may, under
            appropriate circumstances and at its sole discretion, terminate your or any third-party,
            right to access to the Site, if Okroo determines that you are, or a third-party is, a
            repeat infringer. If you believe that any material has been posted via the Site by any
            third-party in a way that constitutes copyright infringement, and you would like to
            bring it to Okroo’ s attention, you must send an email to{' '}
            <u style={{ color: 'blue' }}>info@okroo.com</u> and provide the following information:
            (a) an electronic or physical signature of the person authorized to act on behalf of the
            owner of the copyrighted work; (b) an identification of the copyrighted work and the
            location on the Site of the allegedly infringing work; (c) a written statement that you
            have a good faith belief that the disputed use is not authorized by the owner, its
            agent, or the law; (d)your name and contact information, including, without limitation,
            telephone numberand email address; and (e) a statement by you that the above information
            in your notice is accurate and, under penalty of perjury, that you are the copyright
            owner orauthorized to act on the copyright owner’s behalf.
          </p>
          <p>14. Disclaimer of Warranty</p>
          <p>
            AS PERMITTED BY APPLICABLE LAW, YOU EXPRESSLY AGREE THAT USE OF THE SITE IS AT YOUR SOLE
            RISK. NEITHER OKROO, NOR ITS SUBSIDIARIES OR AFFILIATES OR ANY OF THEIR RESPECTIVE
            EMPLOYEES, AGENTS, MERCHANTS, THIRD-PARTY CONTENT PROVIDERS OR LICENSORS OR ANY OF THEIR
            OFFICERS, DIRECTORS, EMPLOYEES OR AGENTS, WARRANT THAT USE OF THE SITE WILL BE
            UNINTERRUPTED, SECURE, VIRUS-FREE, OR ERROR FREE, NOR DO THEY MAKE ANY WARRANTY AS TO
            (A) THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE SITE,OR (B) THE ACCURACY,
            COMPLETENESS, OR RELIABILITY OF (I) THE CONTENT ON THE SITE, INCLUDING, WITHOUT
            LIMITATION, MERCHANT OFFERINGS, PRODUCTS, OR OTHER AVAILABLE PROGRAMS, (II) DESCRIPTIONS
            OF MERCHANT OFFERINGS, PRODUCTS, OR OTHER AVAILABLE PROGRAMS, OR (III) USER CONTENT
            PROVIDED THROUGH THE SITE. THE SITE AND ALL CONTENT, USER CONTENT AND OTHER INFORMATION
            CONTAINED ON THE SITE, MERCHANT OFFERINGS, PRODUCTS AND OTHER AVAILABLE PROGRAMS
            ACCESSIBLE OR AVAILABLE THROUGH THE SITE, ARE MADE ACCESSIBLE OR AVAILABLE ON AN “AS IS”
            AND “AS AVAILABLE” BASIS. TO THE EXTENT ALLOWED BY APPLICABLE LAW, OKROO HEREBY
            DISCLAIMS ANY AND ALL REPRESENTATIONS, WARRANTIES, AND CONDITIONS, WHETHER EXPRESS,
            IMPLIED, OR STATUTORY, AS TO THE OPERATION OF THE SITE OR THE CONTENT, USER CONTENT, OR
            OTHER INFORMATION CONTAINED ON THE SITE OR THE MERCHANT OFFERINGS, PRODUCTS, OR OTHER
            AVAILABLE PROGRAMS ACCESSIBLE OR AVAILABLE THROUGH THE SITE, INCLUDING, WITHOUT
            LIMITATION, THOSE OF TITLE, NON-INFRINGEMENT, NON-INTERFERENCE, MERCHANTABILITY,
            SUITABILITY, AND FITNESS FOR A PARTICULAR PURPOSE, AS WELL AS WARRANTIES IMPLIED FROM A
            COURSE OF PERFORMANCE OR COURSE OF DEALING. THE WARRANTY LIMITATIONS IN THIS SECTION ARE
            NOT INTENDED TO LIMIT ANY WARRANTY PROVIDED DIRECTLY BY A MERCHANT OR BY THE APPLICABLE
            MANUFACTURER OF PHYSICAL PRODUCTS OR ANY EXPRESS REPRESENTATIONS OR WARRANTIES BY OKROO
            THAT ARE INCLUDED IN OTHER APPLICABLE TERMS.
          </p>
          <p>15. Limitation of Liability</p>
          <p>
            AS PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL OKROO, ITS SUBSIDIARIES OR AFFILIATES
            OR ANY OF THEIR RESPECTIVE EMPLOYEES, OFFICERS, DIRECTORS, AGENTS, MERCHANTS, PARTNERS,
            THIRD-PARTY CONTENT PROVIDERS OR LICENSORS, OR ANY OF THEIR OFFICERS, DIRECTORS,
            EMPLOYEES, OR AGENTS, BE LIABLE FOR ANY DIRECT OR INDIRECT LOST PROFITS OR LOST BUSINESS
            DAMAGES, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT
            OF, RELATED TO, OR IN CONNECTION WITH ANY OF THE FOLLOWING: (A) YOUR USE OF THE SITE,
            THE CONTENT, USER CONTENT, INCLUDING, WITHOUT LIMITATION, ANY PERSONAL INFORMATION, AND
            ANY OTHER INFORMATION EITHER CONTAINED IN THE SITE OR SUBMITTED BY YOU TO THE SITE; (B)
            YOUR INABILITY TOUSE THE SITE; (C) MODIFICATION OR REMOVAL OF CONTENT SUBMITTED ON THE
            SITE; (D) THE MERCHANT OFFERINGS, PRODUCTS, AND OTHER AVAILABLE PROGRAMS ACCESSIBLE OR
            AVAILABLE THROUGH THE SITE; (E) ANY PRODUCTS OR SERVICES PURCHASED OR OBTAINED DIRECTLY
            FROM A MERCHANT; (F) THESE TERMS OF USE; OR (G) ANY IMPROPER USE OF INFORMATION YOU
            PROVIDE TO THE SITE, INCLUDING, WITHOUT LIMITATION, ANY PERSONAL INFORMATION. IN NO
            EVENTWILL OKROO’S LIABILITY IN CONNECTION WITH A MERCHANT OFFERING, PRODUCT, AND OTHER
            AVAILABLE PROGRAMS EXCEED THE AMOUNTS PAID FOR THE APPLICABLE VOUCHER, PRODUCT, OR
            SERVICE. THE LIABILITY LIMITATIONS IN THIS SECTION ARE NOT INTENDED TO LIMIT ANY
            WARRANTY PROVIDED DIRECTLY BY A MERCHANT OR BY THE APPLICABLE MANUFACTURER OF PHYSICAL
            PRODUCTS OR ANY EXPRESS REPRESENTATIONS OR WARRANTIES BY OKROO THAT ARE INCLUDED IN
            OTHER APPLICABLE TERMS, NOR ARE THEY INTENDED TO LIMIT REMEDIES YOU MIGHT HAVE FOR
            PRODUCT-RELATED INJURY.
          </p>
          <p>16. Electronic Communications</p>
          <p>
            When you use the Site or send emails to Okroo, you are communicating with us
            electronically and consent to receive electronic communications related to your use of
            the Site. We will communicate with you by email or by posting notices on the Site. You
            agree that all agreements, notices, disclosures, and other communications that are
            provided to you electronically satisfy any legal requirement that such communications be
            in writing. Notices from us will be considered delivered to you and effective when sent
            to the email address you provide on the Site or from which you otherwise email us.
          </p>
          <p>17. Websites of Others</p>
          <p>
            The Site contains links to websites maintained by other parties. These links are
            provided solely as a convenience to you and not because we endorse or have an opinion
            about the contents on such websites. We expressly disclaim any representations regarding
            the content or accuracy of materials on such websites or the privacy practices of those
            websites. If you decide to access websites maintainedby other parties, you do so at your
            own risk. We are not responsible or liable, directly or indirectly, for any damage,
            loss, or liability caused or alleged to be caused by or in connection with any use of or
            reliance on any content, Products, or services available on or through any such linked
            site or resource.
          </p>
          <p>18. Indemnification/Release</p>
          <p>
            You agree to defend, indemnify, and hold harmless Okroo, its subsidiaries and
            affiliates, and their respective directors, officers, employees and agents from and
            against all claims and expenses, including, without limitation, attorneys’ fees, arising
            out of, related to, or in connection with any of the following: (a) any User Content
            submitted or posted by you, in connection with the Site, or any use of the Site in
            violation of these Terms of Use; (b) fraud you commit or your intentional misconduct or
            gross negligence; or (c) your violation of any applicable U.S., Canadian, or foreign law
            or rights of a third-party.
          </p>
          <p>
            You are solely responsible for your interactions with Merchants and other users of the
            Site. To the extent permitted under applicable laws, you hereby release Okroo from any
            and all claims or liability related to any product or service of a Merchant, regardless
            of whether such product or service is a Merchant Offering available through the Site,
            any action or inaction by a Merchant, including, without limitation, but not limited to
            any harm caused to you by action or inaction of a Merchant, a Merchant’s failure to
            comply with applicable law and/or failure to abide by the termsof a Merchant Offering or
            any other product or service purchased or obtained by youfrom the Merchant, and any
            conduct, speech or User Content, whether online or offline, of any other third-party.
          </p>
          <p>19. Force Majeure</p>
          <p>
            Okroo shall be excused from performance under these Terms of Use, to the extent it or a
            Merchant is prevented or delayed from performing, in whole or in part, as a result of an
            event or series of events caused by or resulting from: (a) weather conditions or other
            elements of nature or acts of God; (b) acts of war, acts of terrorism, insurrection,
            riots, civil disorders, or rebellion; (c) quarantines or embargoes; (d) labor strikes;
            (e) error or disruption to major computer hardware or networks or software failures; or
            (g) other causes beyond the reasonable control of Okroo or a Merchant, as applicable.
          </p>
          <p>20. Assignment</p>
          <p>
            You may not assign these Terms of Use, or any rights, benefits, or obligations
            hereunder, by operation of law or otherwise, without the express written permission of
            Okroo. Any attempted assignment that does not comply with these Terms of Use shall be
            null and void. Okroo may assign these Terms of Use, in whole or in part, to any
            third-party in its sole discretion
          </p>
          <p>21. Entire Agreement</p>
          <p>
            The Terms of Use, including, without limitation, the incorporated Terms of Sale, Special
            Programs, Privacy Statement, and other terms incorporated by reference, constitute the
            entire agreement and understanding between you and Okroo with respect to the subject
            matter hereof and supersedes all prior or contemporaneous communications and proposals,
            whether oral or written, between you and Okroo with respect to such subject matter.
          </p>
          <p>22. Choice of Law</p>
          <p>
            Any disputes arising out of or related to these Terms of Use and/or any use by you ofthe
            Site or Okroo’s services shall be governed by the laws of the State of Florida, without
            regard to its choice of law rules and without regard to conflicts of laws principles.
          </p>
          <p>23. Additional Disclosures</p>
          <p>
            No waiver by either you or Okroo of any breach or default or failure to exercise any
            right allowed under these Terms of Use is a waiver of any preceding or subsequent breach
            or default or a waiver or forfeiture of any similar or future rights under our Terms of
            Use. The section headings used herein are for convenience only and shall be of no legal
            force or effect. If a court of competent jurisdiction holds any provision of our Terms
            of Use invalid, such invalidity shall not affect the enforceability of any other
            provisions contained in these Terms of Use, and the remaining portions of our Terms of
            Use shall continue in full force and effect.The provisions of these Terms of Use apply
            equally to, and are for the benefit of, Okroo, its subsidiaries, affiliates, Merchants,
            and its third-party content providers and licensors, and each shall have the right to
            assert and enforce such provisions directly.
          </p>
          <p>Terms of Sale</p>
          <p>
            By purchasing any Promotions, Merchant Offering, Product or participating in other
            available programs via the Site, you agree to the Terms of Use, including, without
            limitation, the Terms of Sale specified below.
          </p>
          <p>
            <b>(a) In General</b>
          </p>
          <p>
            Descriptions of the Promotions, Merchant Offerings, and Products advertised on the Site
            are provided by the Merchant or other referenced third parties. Okroo does not
            investigate or vet Merchants. Okroo is not responsible for any claims associated with
            the description of the Promotions, Merchant Offerings or Products. Pricing relating to
            certain Promotions, Merchant Offerings, Products, and other available programs on the
            Site may change at any time in Okroo’s sole discretion without notice.
          </p>
          <p>
            A Merchant may advertise goods, services or experiences on the Site, or with respect to
            Products, supply products to Okroo, that require Merchants to have an up-to-date
            regulatory authorization, license, or certification. Okroo does not verify, validate, or
            collect evidence of any regulatory authorization, license or certification from any
            Merchant. You should make whatever investigation you deem necessary orappropriate before
            purchasing any Promotions, Merchant Offering, or Product to determine whether: (i)
            Merchant is qualified to provide the advertised good or perform the advertised service;
            or (ii) the Product is of the care and quality required.Merchant is solely responsible
            for the care and quality of the goods and services being provided or the care and
            quality of the Products.
          </p>
          <p>
            Okroo is not a health or wellness provider and does not, will not, and cannot refer,
            recommend, or endorse any specific professional services, products, or procedures that
            are advertised on the Site. The Site is not a substitute for professional advice,
            including, without limitation, medical advice, diagnosis or treatment. Always seek the
            advice of your physician or other qualified health provider with any questions you may
            have regarding a health condition. Never neglect to seek out or delay or disregard
            professional advice relating to your health because of something you have read on the
            Site.
          </p>
          <p>
            Okroo may, in its sole discretion, verify a user’s identity prior to processing a
            purchase. Okroo may also refuse to process a purchase, may cancel a purchase, or may
            limit quantities, as reasonably deemed necessary, to comply with applicable law or to
            respond to a case of misrepresentation, fraud or known or potential violations of the
            law or these Terms of Use. Refunds for cancelled orders may be issued where appropriate.
          </p>
          <p>
            If an offer becomes unavailable between ordering and processing, Okroo will either
            cancel or not process the order and will notify you by email.
          </p>
          <p>
            Okroo does not guarantee that it offers best available rates or prices and does not
            guarantee against pricing errors. Okroo reserves the right, in its sole discretion, to
            not process or to cancel any orders placed, including, without limitation, if the price
            was incorrectly posted on the Site. If this occurs, Okroo will attempt to notify you by
            email. In addition, Okroo reserves the right, in its sole discretion, to correct any
            error in the stated retail price of the Promotion, Merchant Offering, or Product.
          </p>
          <p>
            <b>(b) Merchant Offerings</b>
          </p>
          <p>(i) Promotions</p>
          <p>
            Promotions are distributed by Okroo and are redeemable for certain goods, services or
            experiences offered by, or facilitated through, the Merchant identified on the
            Promotion. The Merchant is solely responsible for redeeming the Promotion. Furthermore,
            the Merchant is fully and solely responsible for the care and quality of all goods and
            services it provides to you and for any and all injuries, illnesses, damages, claims,
            liabilities and costs (“Liabilities”) it may cause you to suffer, directly or
            indirectly, in full or in part, whether related to the use or redemption of a Promotion
            or not. You waive and release Okroo and its subsidiaries, affiliates, partners,
            officers, directors, employees and agents from any Liabilities arising from or related
            to any act or omission of a Merchant in connection with your use of a Promotion or the
            goods, services or experiences a Merchant provides in connection with the Promotion.
          </p>
          <p>
            By purchasing, viewing a mobile version, printing, accepting, using or attempting to use
            any Promotion, you agree specifically to the terms on the Promotion and any additional
            deal-specific terms advertised in connection with and on the Promotion atthe time of
            purchase, the Terms of Use and these Terms of Sale. These rules apply toall Promotions
            that we make available, unless otherwise disclosed in connection with the purchase of a
            particular Promotion, and except as otherwise required by law. In the event of a
            conflict between these rules and the terms advertised in connection with and on the
            Promotion at the time of purchase, the terms advertisedin connection with and on the
            Promotion at the time of purchasewill control. Any attempt to redeem a Promotion in
            violation of these Terms of Use (including, withoutlimitation, the Terms of Sale) will
            render the Promotion void.
          </p>
          <p>
            The Merchant is the sole issuer of the Promotion. Promotions are not redeemable forcash,
            unless required by law. Unauthorized or unlawful reproduction, resale, modification, or
            trade of a Promotion is prohibited. Pricing relating to certain Merchant Offerings and
            Products on the Site may change at any time in Okroo’s discretion, without notice.
          </p>
          <p>
            Promotions are offered by Merchants to consumers throughout the Site. Promotions are
            subject to the terms and conditions as specified by the Merchant. By obtaining or
            purchasing a Promotion through the Site, you acquire the right to redeem the Promotion
            directly with the Merchant at the Merchant venue or place of business. Any service fee
            retained by Okroo from the sale of a Promotion is compensation to Okroo for marketing,
            advertising, and distributing the Promotion on behalf of the Merchant. As the issuer of
            the Promotion, the Merchant shall be fully responsible for any and all Liabilities,
            caused in whole or in part by the Merchant, as well as for any Liabilities arising from
            any regulatory action. Promotions have no cash or residual value. Okroo does not make
            any warranty in relation to the Promotions, including, without limitation, their
            validity and/or value. Okroo is not a party to any transactionthat the Merchant and you
            may enter into as a result of you purchasing any Promotion.
          </p>
          <p>
            The Merchant is solely responsible for the sale of alcoholic beverages. Any offer
            thatfeatures alcohol will contain information about restrictions.
          </p>
          <p>
            Admission Promotions. Some Promotions are redeemable for an admission to a specific
            event at a named venue. Promotions redeemable for admission to venues or events
            (collectively, “Admission Promotions”) have no value once the event date and time has
            passed. Admission Promotions arefinal saleandnon-refundable, subject to the
            Extraordinary Event Policy, unless otherwise stated. Admission Promotions do not include
            any applicable gratuity. Unless a specific exception is made, Admission Promotions are
            not eligible for upgrades and are not transferable. Certain resale premiums and
            restrictions may apply. Admission Promotions obtainedfrom unauthorized sources may be
            lost, stolen, or counterfeit, and if so are void.
          </p>
          <p>
            Venues and Events. Opening acts, as well as individual performers, are subject to change
            or cancellation at any time without notice or recourse by you. Venues may search you as
            a condition of admission and ban or restrict certain items from being brought to the
            event and impose other rules on Promotion-holders. You agree to all such rules and
            conditions and waive any related claims that may arise in conjunction with their
            imposition or execution. A Promotion is a revocable license and admission may be refused
            for violation of the venue’s rules without refund, or for any or no reason upon
            refunding the amount paid. You voluntarily assume all risks and danger incidental to any
            event for which any Admission Promotions are issued, whether occurring before, during or
            after the event, and you waive any claims for personal injury or death against Okroo,
            the venue, the issuer of the Admission Promotion, and all of their respective
            affiliates, agents, officers, directors,owners, and employees on behalf of yourself and
            any accompanying minor. You agree not to record or transmit or aid in recording or
            transmitting, any description, account, picture or reproduction of any event for which
            you purchase Admission Promotions. You agree that any event for which you purchase
            Admission Promotions is a public event, that your appearance and actions inside and
            outside the venue where the event occurs are public in nature and that you have no
            expectation of privacy with regard to your actions or conduct at the event.
          </p>
          <p className="text-center">
            &nbsp; &nbsp; &nbsp; &nbsp; In IOS for Payments we use Apple Pay, and this are the
            <a
              href="https://www.apple.com/legal/privacy/en-ww/"
              target="_blank"
              rel="noopener noreferrer">
              {' '}
              policy of use{' '}
            </a>
            |
            <a
              href="https://support.apple.com/en-us/HT203027"
              target="_blank"
              rel="noopener noreferrer">
              {' '}
              terms and conditions
            </a>
          </p>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  </>
);

export default TermsConditions;
