import React from 'react';
import { MDBRow, MDBCol, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import moment from 'moment';
import PropTypes from 'prop-types';

class TableRedemption extends React.Component {
  render() {
    const { exchanges } = this.props;
    let listExchanges = [];
    exchanges.forEach((exchange) => {
      exchange.promos.forEach((promo) => {
        let objExchange = {};
        objExchange.createdDate = exchange.createdDate;
        objExchange.order = exchange.order;
        objExchange.redeemCode = exchange.redeemCode;
        objExchange.user = exchange.user;
        objExchange.name = promo.name;
        objExchange.qty = promo.quantity;
        objExchange.prt = promo.quantityAvailable;
        listExchanges.push(objExchange);
      });
    });
    const TableRedemptions = listExchanges.map((exchange, index) => {
      return (
        <>
          <MDBTableBody>
            <tr key={index}>
              <td>{exchange.name}</td>
              <td>{exchange.user}</td>
              <td>{moment(exchange.createdDate).format('MMM DD, YYYY hh:mm A')}</td>
              <td>{exchange.order}</td>
              {/* <td>{exchange.redeemCode}</td> */}
              <td>{exchange.qty}</td>
              <td>{exchange.prt}</td>
            </tr>
          </MDBTableBody>
        </>
      );
    });
    return (
      <MDBRow>
        <MDBCol md="12" className="p-0">
          <MDBTable
            hover
            borderless
            responsiveSm
            responsiveMd
            responsiveLg
            responsiveXl
            className="text-center">
            <MDBTableHead>
              <tr>
                <th>NAME</th>
                <th>USER</th>
                <th>DATE REDEEM</th>
                <th>#ORDER</th>
                {/* <th>#REDEEM</th> */}
                <th>QTY</th>
                <th>PENDING QTY</th>
              </tr>
            </MDBTableHead>
            {TableRedemptions}
          </MDBTable>
        </MDBCol>
      </MDBRow>
    );
  }
}

TableRedemption.propTypes = {
  exchanges: PropTypes.array.isRequired,
};

export default TableRedemption;
