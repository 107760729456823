import firebase from 'firebase';
import { createAction } from '@cobuildlab/react-simple-state';
import { ON_ORDER_REQUEST, ON_ORDER_REQUEST_ERROR } from './transaction-history-event';
import { authStore, USER_EVENT } from '../../auth/auth-store';

/**
 * fetchOrders can be use to obtain the order that the user logged have
 * @return fetchOrders brings array of orders
 */

export const fetchOrders = createAction(ON_ORDER_REQUEST, ON_ORDER_REQUEST_ERROR, async () => {
  const DB = firebase.firestore();
  const userData = authStore.getState(USER_EVENT);
  let ordersCollection = DB.collection('orders').where('merchant', '==', userData.email);
  let ordersRef;
  let ordersList = [];

  try {
    ordersRef = await ordersCollection.get();
  } catch (e) {
    throw new Error(e);
  }

  ordersRef.forEach((element) => {
    if (!ordersList.find((item) => item.id === element.data().id)) {
      ordersList.push(element.data());
    }
  });

  const payments = await Promise.all(
    ordersList.map((item) =>
      DB.collection('payments')
        .where('idPayment', '==', `${item.idPayment}`)
        .get(),
    ),
  );

  let result = [];
  if (payments.length) {
    payments.forEach((item) => {
      if (!item.empty) {
        item.forEach((element) => {
          const data = element.data();
          if (!result.find((payment) => payment.idPayment === data.idPayment)) {
            const order = ordersList.find((order) => order.idPayment === data.idPayment);
            result.push({
              ...order,
              totalPricePayment: data.totalVenue,
            });
          }
        });
      }
    });
  }

  // const data = await Promise.all(
  //   ordersList.map(async (item) => {
  //     let ordersPayment = DB.collection('payments').where('idPayment', '==', `${item.idPayment}`);
  //     let arrayResponse = [];
  //     let response = await ordersPayment.get();
  //     console.log('response', response);
  //     response.forEach((element) => {
  //       arrayResponse.push(element.data());
  //     });
  //     return {
  //       totalPricePayment: arrayResponse[0].totalVenue,
  //       ...item,
  //     };
  //   }),
  // );
  return result;
});
