import React from 'react';
import PropTypes from 'prop-types';
import Sidebar from 'react-sidebar';
import ContentSidebar from './ContentSidebar';
import View from 'react-flux-state';
import { menu } from 'react-icons-kit/iconic/menu';
import Icon from 'react-icons-kit';
import { authStore, USER_EVENT } from '../modules/auth/auth-store';

const mql = window.matchMedia(`(min-width: 800px)`);

const publicRoutes = [
  // { path: '/exchange', name: 'Exchange', className: 'title-list-item' },
  { path: '/promotion', name: 'Promotions', className: 'title-list-item' },
  { path: '/promotion-redeemption', name: 'Promotions Redeemed', className: 'title-list-item' },
  { path: '/transaction-history', name: 'Transaction History', className: 'title-list-item' },
  { path: '/profile', name: 'Profile', className: 'title-list-item', required: true },
  { path: '/add-bank', name: 'Account information', className: 'title-list-item', required: true },
  // { path: '/terms', name: 'Terms and Conditions', className: 'title-list-item' },
];

const privateRoutes = [
  { path: '/promotion-administrator', name: 'Promotion Admin', className: 'title-list-item' },
  // { path: '/sign-up', name: 'Add Venue', className: 'title-list-item' },
];

class SidebarComponent extends View {
  constructor(props) {
    super(props);
    this.state = {
      sidebarDocked: mql.matches,
      sidebarOpen: false,
      admin: false,
      location: null,
      stripeAccount: null,
    };

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }

  componentDidMount() {
    mql.addListener(this.mediaQueryChanged);

    const userEvent = authStore.getState(USER_EVENT);
    const { admin, location, stripeAccount } = userEvent;
    this.setState({
      admin,
      location,
      stripeAccount,
    });

    this.subscribe(authStore, USER_EVENT, (data) => {
      this.setState({
        stripeAccount: data.stripeAccount,
        location: data.location,
      });
    });
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  mediaQueryChanged() {
    this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
  }

  render() {
    const { admin, location, stripeAccount } = this.state;
    const routeProfile = publicRoutes.filter((res) => res.required);
    let route;
    if (admin) {
      route = privateRoutes;
    } else {
      route = location && stripeAccount ? publicRoutes : routeProfile;
    }
    return (
      <React.Fragment>
        <Sidebar
          sidebar={<ContentSidebar routes={route} />}
          open={this.state.sidebarOpen}
          docked={this.state.sidebarDocked}
          onSetOpen={this.onSetSidebarOpen}
          sidebarClassName={'sidebarStyles'}
          transitions={false}>
          {this.props.children}

          <div
            className="pointer d-block d-md-none view-menu-bar"
            onClick={() => this.onSetSidebarOpen(true)}>
            <Icon icon={menu} size={28} className="text-white" />
          </div>
        </Sidebar>
      </React.Fragment>
    );
  }
}

SidebarComponent.propTypes = {
  children: PropTypes.any,
};

export default SidebarComponent;
