import Flux from 'flux-state';
/**
 * @property {string} FETCH_REEDEM_EVENT triggers when a user tries to get all their promotions
 * @property {string} REEDEM_ERROR_EVENT shows an error when there's an error fetching the promotion info
 * @property {string} FETCH_REEDEM_TRANSACTION_EVENT triggers when a user tries to get all their promotions and not clamied promotion
 * @property {string} REEDEM_TRANSACTION_ERROR_EVENT shows an error when there's an error fetching the promotion info
 */

export const REEDEM_ERROR_EVENT = 'REEDEM_ERROR_EVENT';
export const FETCH_REEDEM_EVENT = 'FETCH_REEDEM_EVENT';
export const REEDEM_TRANSACTION_ERROR_EVENT = 'REEDEM_TRANSACTION_ERROR_EVENT';
export const FETCH_REEDEM_TRANSACTION_EVENT = 'FETCH_REEDEM_TRANSACTION_EVENT';

class PromotionStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(REEDEM_ERROR_EVENT);
    this.addEvent(FETCH_REEDEM_EVENT);
    this.addEvent(REEDEM_TRANSACTION_ERROR_EVENT);
    this.addEvent(FETCH_REEDEM_TRANSACTION_EVENT);
  }
}
const promotionRedeemStore = new PromotionStore();

export { promotionRedeemStore };
