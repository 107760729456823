import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import LandingView from './modules/landing/Landing';
import AuthView from './modules/auth/AuthView';
import RecoveryPasswordView from './modules/auth/components/RecoverPassword';
// import ExchangeScreen from './modules/admin/exchange/Exchange';
// import ExchangeSuccessScreen from './modules/admin/exchange/components/ExchangeSuccess';
import PromotionView from './modules/admin/promotion/PromotionView';
import DetailPromotionView from './modules/admin/promotion/DetailPromotionView';
import DetailPromotionAdminView from './modules/admin/promotion/DetailPromotionAdminView';
import PromotionRedeemScreen from './modules/admin/promotion-reedem/PromotionRedeem';
import TransactionHistoryView from './modules/admin/transaction-history/transaction-history';
import ProfileView from './modules/admin/profile/ProfileView';
import BankAccountView from './modules/admin/profile/BankAccountView';
import EditProfileView from './modules/admin/profile/EditProfileView';
import { ChangePasswordView } from './modules/auth/components/ChangePasswordView';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NewPromotionView from './modules/admin/promotion/NewPromotionView';
import Session from './components/Session';
import AdminView from './modules/admin/admin/AdminView';
import CopyPromotionView from './modules/admin/promotion/CopyPromotionView';
import TermsAndConditionsView from './modules/admin/terms/TermsAndConditionsView';
import TermsMerchantView from './modules/admin/terms/TermsMerchant';
import PrivacyAndPolicyView from './modules/admin/terms/PrivacyAndPolicyView';
import TermsView from './modules/admin/terms/TermsConditionsView';
import SignUpView from './modules/auth/SignUpView';

/**
 * @typedef {[ component: any, pathname: string ]} publicRoutes
 * Defines all public routes
 */

const publicRoutes = [
  { pathname: '/', component: LandingView },
  { pathname: '/login', component: AuthView },
  { pathname: '/sign-up', component: SignUpView },
  { pathname: '/recovery-password', component: RecoveryPasswordView },
  { pathname: '/change-password', component: ChangePasswordView },
  { pathname: '/terms-and-conditions', component: TermsAndConditionsView },
  { pathname: '/terms-merchant', component: TermsMerchantView },
  { pathname: '/privacy-policy', component: PrivacyAndPolicyView },
];

const privateRoutes = [
  // { pathname: '/exchange', component: ExchangeScreen },
  // { pathname: '/exchange-success', component: ExchangeSuccessScreen },
  { pathname: '/promotion', component: PromotionView },
  { pathname: '/detail-promotion/:id', component: DetailPromotionView },
  { pathname: '/detail-promotion-admin/:id', component: DetailPromotionAdminView },
  { pathname: '/promotion-Redeemption', component: PromotionRedeemScreen },
  { pathname: '/transaction-history', component: TransactionHistoryView },
  { pathname: '/profile', component: ProfileView },
  { pathname: '/add-bank', component: BankAccountView },
  { pathname: '/edit-profile', component: EditProfileView },
  { pathname: '/terms', component: TermsView },
  { pathname: '/new-promotion/', component: NewPromotionView },
  { pathname: '/copy-promotion/:id', component: CopyPromotionView },
  { pathname: '/promotion-administrator', component: AdminView },
];
toast.configure();

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <Switch>
          {publicRoutes.map((route, index) => (
            <Route exact path={route.pathname} component={route.component} key={index} />
          ))}
          ;
          <Session>
            {privateRoutes.map((route, index) => (
              <Route exact path={route.pathname} component={route.component} key={index} />
            ))}
            ;
          </Session>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
