import React from 'react';
import View from 'react-flux-state';
import { toast } from 'react-toastify';
import { Icon } from 'react-icons-kit';
import { MDBContainer, MDBView, MDBMask, MDBRow, MDBCol, MDBBtn, MDBBtnGroup } from 'mdbreact';
import { authStore, REQUEST_PASSWORD_RESET, USER_ERROR_EVENT } from '../auth-store';
import { requestPasswordReset } from '../auth-actions';
import bgLanding from '../../../assets/img/background.png';
import { ic_mail_outline } from 'react-icons-kit/md/ic_mail_outline';
import { ic_keyboard_arrow_right } from 'react-icons-kit/md/ic_keyboard_arrow_right';
// import { ic_keyboard_arrow_left } from 'react-icons-kit/md/ic_keyboard_arrow_left';
import { Loader } from '../../../components/Loader';

class RecoveryPasswordView extends View {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      loading: false,
    };
  }

  componentDidMount() {
    this.subscribe(authStore, REQUEST_PASSWORD_RESET, (send) => {
      console.log('send', send);
      toast.info('Request for Recover Password Processed');
      this.setState({ loading: false }, () => {
        this.props.history.push('/');
      });
    });
    this.subscribe(authStore, USER_ERROR_EVENT, (err) => {
      toast.error('Email address not found');
      console.log('err', err);
      this.setState({ loading: false });
    });
  }

  onSubmit = (e) => {
    const { email } = this.state;
    console.log('email', email);
    e.preventDefault();
    this.setState({ loading: true, forgot: true }, () => {
      requestPasswordReset(email);
    });
  };

  onChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value,
    });
  };

  backLogin = () => {
    this.props.history.push('/login');
  };

  onKeyPress = (e) => {
    if (e.which === 13) {
      this.onSubmit(e);
    }
  };

  render() {
    let { loading } = this.state;
    return (
      <MDBView src={bgLanding}>
        <MDBMask className="d-flex justify-content-center align-items-center">
          <MDBContainer>
            <MDBRow className="justify-content-center">
              <MDBCol md="4">
                <h4 className="text-primary text-center">Recover your password</h4>
                <MDBRow>
                  <MDBCol md="12" className="p-0">
                    <form className="p-3">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="basic-addon1">
                            <Icon icon={ic_mail_outline} />
                          </span>
                        </div>
                        <input
                          type="email"
                          name="email"
                          onChange={this.onChange}
                          className="form-control"
                          placeholder="Email"
                          aria-label="Email"
                          aria-describedby="basic-addon1"
                          onKeyPress={this.onKeyPress}
                        />
                      </div>
                    </form>
                    {loading ? (
                      <Loader />
                    ) : (
                      <div className="text-center">
                        {/* <MDBBtn className="btn-auth btn-circle" onClick={this.backLogin}>
                            <Icon size={20} icon={ic_keyboard_arrow_left} />
                            Login
                          </MDBBtn>
                          <MDBBtn className="btn-auth btn-circle" onClick={this.onSubmit}>
                            Recovery
                            <Icon size={20} icon={ic_keyboard_arrow_right} />
                          </MDBBtn> */}
                        <MDBBtnGroup size="sm" className="mb-4 btn-auth" style={{ padding: '0px' }}>
                          {/* <MDBBtn className="btn-circle" onClick={this.backLogin}>
                            <Icon size={20} icon={ic_keyboard_arrow_left} />
                            Home
                          </MDBBtn> */}
                          <MDBBtn className="btn-circle" onClick={this.onSubmit}>
                            Recover password
                            <Icon size={20} icon={ic_keyboard_arrow_right} />
                          </MDBBtn>
                        </MDBBtnGroup>
                      </div>
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBMask>
      </MDBView>
    );
  }
}

export default RecoveryPasswordView;
