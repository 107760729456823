import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import IconUser from '../assets/img/User.png';
import { authStore, USER_EVENT } from '../modules/auth/auth-store';

class LinkUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      admin: false,
    };
  }

  componentWillMount() {
    const userEvent = authStore.getState(USER_EVENT);
    if (userEvent) {
      const { email, admin } = userEvent;
      this.setState({
        user: email,
        admin,
      });
    }
  }

  render() {
    const { user, admin } = this.state;
    const { termsPlace } = this.props;
    return (
      <React.Fragment>
        {termsPlace ? (
          <></>
        ) : !admin ? (
          <>
            <Link to="/profile">{user}</Link>{' '}
            <img src={IconUser} alt="user" className="ml-3" width="18" />
          </>
        ) : (
          <></>
        )}
      </React.Fragment>
    );
  }
}

LinkUser.defaultProps = {
  termsPlace: null,
};

LinkUser.propTypes = {
  termsPlace: PropTypes.bool,
};

export default LinkUser;
