import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';

const PrivacyPolicy = () => (
  <>
    <h1 className="d-flex justify-content-center p-4 ">Privacy Policy</h1>
    <MDBContainer>
      <MDBRow style={{ textAlign: 'justify' }}>
        <MDBCol md="12" className="justify-content-center">
          <p>
            <u>Privacy Statement</u>
          </p>
          <p>
            This Privacy Statement (“<b>Privacy Statement</b>”) explains how Okroo, llc., (“
            <b>Okroo,</b>”)use your information and applies to all who use our websites and
            platforms – including mobile applications, touch technologies, electronic services,
            social networking sites, interactive features, online services or any of our described
            online activities we own or control (collectively, the “<b>Service</b>”).
          </p>
          <p>
            <b>
              By using the Service, you acknowledge you have read the terms of this Privacy
              Statement. If you do not want your information handled as described in this Privacy
              Statement, please do not provide us with your information or interact with the
              Service.
            </b>
          </p>
          <p>
            We may modify this Privacy Statement at any time. If we make any material changes, we
            will notify you by email (sent to the email address specified in your account) or by
            means of notice on the Service prior to the change becoming effective. You can determine
            when this Privacy Statement was last revised by referring to the “Effective Date.”
          </p>
          <p>
            <b>1. Types of Information We Collect</b>
          </p>
          <p>We may collect the following categories of personal information from and about you:</p>
          <p>
            <ul>
              <li>
                <b>Identifiers</b>: such as your name, postal addresses, email addresses, social
                networking website user account names, telephone numbers, or other addresses at
                which you are able to receive communications.
              </li>
              <li>
                <b>Demographic information</b>: such as your age, birthdate, and gender.{' '}
              </li>
              <li>
                <b>Commercial information</b>: such as information that enables us to
                determinelifestyle, interests, and activities, including purchasing tendencies and
                order history;areas of interest; information collected through your interactions
                with social networks; and information about friends who refer you or whom you have
                referred.{' '}
              </li>
              <li>
                <b>Location information</b>: such as information related to your state/province,
                city, or neighborhood, and, if you agree, more specific location information that is
                provided through the GPS functionality on mobile devices used to access the Service.{' '}
              </li>
              <li>
                <b>Financial information</b>: such as information collected from you as needed
                toprocess payments, or as provided by you to administer your participation in
                optionalservices and programs, such as your payment card number, expiration date,
                and card verification number.{' '}
              </li>
              <li>
                <b>Internet and network activity information</b>: such asinformation about your
                browsing behavior, search history, and interactions with websites and
                advertisements, including data from cookies, pixel tags, and web beacons.{' '}
              </li>
              <li>
                <b>Inferences regarding preferences and other characteristics</b>: such as our
                assessment of the types of products or services you may have an interest in.{' '}
              </li>
            </ul>
          </p>
          <p>
            <b>2. How Okroo Collects Information</b>
          </p>
          <p>We may collect personal information about you from a variety of sources, including:</p>
          <p>
            <ul>
              <li>
                From you: We collect information that you submit to us. For example, when you use
                the Service; make a purchase; register to receive information, products, or services
                available through the Service; or interact with us in other ways. We will collect
                any information you voluntarily provide, and we may also request optional
                information to support your use of the Service. We also collect any information that
                you voluntarily enter into any postings, comments, or forums within the Service. If
                you send an e-mail to us, we will collect your e-mail address and the full content
                of your e-mail, including attached files and other information you choose to provide
                to us.
              </li>
              <li>
                From your device: When you use the Service, we may collect information about the
                devices you use to access the Service, including hardware model, operating system
                and version, Internet Protocol (“IP”) address, and other unique device identifiers,
                mobile network information, and information about the device’s interaction with our
                Service. We also collect information about how you use our Service, including your
                access times, browser types, and language. This type of dataenables us to understand
                how often individuals use parts of the Service so we can analyze and improve it. To
                collect this information, we use cookies and web beacons, and other similar
                technologies. Note that this Privacy Statement covers only how we use information
                collected via cookies; it does not cover the use of suchinformation by third
                parties.
              </li>
              <li>
                Location information: We may collect different types of information about your
                location, including general information, such as the city, state, and/or zip code
                associated with your IP Address, and, if you agree, more specific location
                information that is provided through the GPS functionality on mobile devices used
                toaccess the Service. We may use such location information to customize the Service;
                including advertising that is presented to you. In order to do this, your location
                information may be passed along to our affiliates, agents, vendors or advertisers.
              </li>
              <li>
                Social media networks and other third parties: We may obtain information about you
                or your use of the Service from third party sources, such as our vendors, like web
                hosting providers, analytics providers, or advertisers. You may give us permission
                to access your information from services offered by third parties, including social
                media networks. The information we obtain from third party services depends on your
                account/privacy settings with those third parties and the third parties’ privacy
                policies, so be sure to check those policies and to adjust your settings to meet
                your preferences. When you access the Service through social media networks and
                other third party platforms, you are authorizing Okroo to collect, store, and use
                such information and content in accordance with this Privacy Statement. Please keep
                in mind that any information provided to us by a third party may also be subject to
                that third party’s privacy policy.
              </li>
            </ul>
          </p>
          <p>4. Disclosing Information</p>
          <p>We may share your personal information as follows:</p>
          <p>
            <ul>
              <li>
                as required to comply with the law or legal obligations, such as to comply with
                legal orders and government requests, or as needed to support auditing, compliance,
                and corporate governance functions.
              </li>
              <li>
                in response to a subpoena, or similar legal process, including to law enforcement
                agencies, regulators, and courts in the United States and other countries where we
                operate;
              </li>
              <li>
                with our vendors who perform a variety of services and functions for us, suchas data
                storage, order fulfillment, transmitting emails, and managing digital content. We
                may share your information with such vendors subject to confidentialityobligations
                consistent with this Privacy Statement and on the condition that the service
                providers use your information only on our behalf and pursuant to our instructions;
              </li>
              <li>
                in the event we go through a business transition such as a merger, acquisition by
                another company, bankruptcy, reorganization, or sale of all or a portion of our
                assets;
              </li>
              <li>
                to combat fraud or criminal activity, and to protect our rights, users, and business
                partners, or as part of legal proceedings affecting Okroo;
              </li>
              <li>With our Merchant partners</li>
              <li>
                with our affiliates and subsidiaries who may only use the personal information for
                the purposes described in this Privacy Statement;
              </li>
              <li>
                if the information has been aggregated, de-identified, or otherwise does not
                identify you personally; and
              </li>
              <li>otherwise with your consent.</li>
            </ul>
          </p>
          <p>
            We may disclose the following categories of personal information for our own business
            and operational purposes:
          </p>
          <p>
            <ul>
              <li>Identifiers;</li>
              <li>Demographics;</li>
              <li>Commercial information;</li>
              <li>Location information;</li>
              <li>Financial information;</li>
              <li>Internet and network activity information; and</li>
              <li>Inferences regarding preferences or characteristics.</li>
            </ul>
          </p>
          <p>
            We may disclose certain personal information in exchange for services, insights, or
            other valuable consideration. These disclosures may involve:
          </p>
          <p>
            <ul>
              <li>Identifiers;</li>
              <li>Demographics;</li>
              <li>Commercial information;</li>
              <li>Location information;</li>
              <li>Financial information (not including payment card numbers);</li>
              <li>Internet and network activity information; and</li>
              <li>Inferences regarding preferences or characteristics.</li>
            </ul>
          </p>
          <p>5. Your Rights Regarding Personal Information</p>
          <p>You have certain rights with regard to your personal information:</p>
          <p>
            <ul>
              <li>
                You have the right to know and request information about the categories and specific
                pieces of personal information we have collected about you within the last 12
                months, as well as the categories of sources from which such information is
                collected, the purpose for collecting such information, and the categories of third
                parties with whom we share such information. You also have the right to know if we
                have sold or disclosed your personal information for business purposes.
              </li>
              <li>You have the right to update your personal information.</li>
              <li>You have the right to request a portable copy of your personal information.</li>
              <li>
                You have the right to request that Okroo delete your personal information, subject
                to certain exceptions allowed under applicable law.
              </li>
              <li>
                You have the right to opt-out of certain disclosures of your personal information
                for valuable consideration.{' '}
              </li>
              <li>
                You have the right to not be discriminated against for exercising any of the
                above-listed rights.
              </li>
            </ul>
          </p>
          <p>
            You may exercise these rights by sending an email to info@okroo.com. We may
            takereasonable steps to verify your identity prior to responding to your requests. In
            addition to the above rights, you can update or remove financial account information.
            You can also deactivate your Okroo account.
          </p>
          <p>6. Your Choices</p>
          <p>
            We think that you benefit from a more personalized experience when we know moreabout you
            and what you like. However, you can limit the information you provide and you can limit
            the communications that Okroo sends to you. In particular:
          </p>
          <p>
            <ul>
              <li>
                <b>Commercial E-mails</b>: You may choose not to receive commercial e-mails from us
                by following the instructions contained in any of the commercial e-mails we send or
                by logging into your account and adjusting your e-mail preferences. Please note that
                even if you unsubscribe from commercial email messages, we may still e-mail you
                non-commercial e-mails related to your account and your transactions on the Service.
                You may update your subscription preferences at any time.
              </li>
              <li>
                <b>Cookies and Other Technologies</b>: You may manage how your browser handles
                cookies by adjusting its privacy and security settings. Browsers are different, so
                refer to instructions related to your browser to learn about cookie-related and
                other privacy and security settings that may be available. Currently, we do not
                alter our data collection and use practices in response to Do Not Track signals.
              </li>
              <li>
                <b>Device Data</b>: You may manage how your mobile device and mobile browser share
                certain device data with Okroo, as well as how your mobile browser handles cookies
                by adjusting the privacy and security settings on your mobile device. Pleaserefer to
                the instructions provided by your mobile service provider or the manufacturer of
                your device to learn how to adjust your settings.
              </li>
            </ul>
          </p>
          <p>
            When you first visit or use the Service we may request permission to collect and useyour
            device’s precise geolocation. You can opt not to permit the collection of this
            information, or permit it only when using the mobile app, but it may limit certain
            functions or features of the Service. You can control how and whether we collect your
            precise geolocation information through your device’s settings.
          </p>
          <p>7. Privacy Practices of Third Parties</p>
          <p>
            This Privacy Statement only addresses the use and disclosure of information by Okroo
            through your interaction with the Service. Other websites that may be accessible through
            links from the Service may have their own privacy statements and personal information
            collection, use, and disclosure practices. Our business partners may also have their own
            privacy statements. We encourage you to familiarize yourself with the privacy statements
            provided by these other parties prior to providing them with information or taking
            advantage of a sponsored offer orpromotion.
          </p>
          <p>8. Business Transfers and Corporate Changes</p>
          <p>
            Okroo reserves the right to disclose, transfer, or license any and all information
            related to the Service, including personal information:
          </p>
          <p>
            <ul>
              <li>
                to a subsequent owner, co-owner, or operator of the Service or any portion
                oroperation related to part of Service; or
              </li>
              <li>
                in connection with a corporate merger, consolidation, or restructuring, the sale of
                substantially all of our stock and/or assets, or other corporate change, including,
                without limitation, during the course of any due diligence process.
              </li>
            </ul>
          </p>
          <p>12. Children’s Privacy</p>
          <p>
            The Service is a general audience site not directed at children under the age of 16.
          </p>
          <p>
            By using Okroo, you represent that you are at least eighteen years old and understand
            that you must be at least eighteen years old in order to create an account and purchase
            the goods or services advertised through the Service. You also represent that you are at
            least twenty-one years old when purchasing alcohol.
          </p>
          <p>13. Contact Us</p>
          <p>
            Please contact us if you have any questions or comments about our privacy practices or
            this Privacy Statement. You can reach us online at info@okroo.com.
          </p>
          <p className="text-center">
            &nbsp; &nbsp; &nbsp; &nbsp; In IOS for Payments we use Apple Pay, and this are the
            <a
              href="https://www.apple.com/legal/privacy/en-ww/"
              target="_blank"
              rel="noopener noreferrer">
              {' '}
              policy of use{' '}
            </a>
            |
            <a
              href="https://support.apple.com/en-us/HT203027"
              target="_blank"
              rel="noopener noreferrer">
              {' '}
              terms and conditions
            </a>
          </p>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  </>
);

export default PrivacyPolicy;
