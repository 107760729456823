import firebase from 'firebase';
import Flux from 'flux-state';
import {
  FETCH_REEDEM_EVENT,
  REEDEM_ERROR_EVENT,
  REEDEM_TRANSACTION_ERROR_EVENT,
  FETCH_REEDEM_TRANSACTION_EVENT,
} from './promotionRedeem-store';
import { authStore, USER_EVENT } from '../../auth/auth-store';

/**
 *
 * @return {Promise<ProfileModel>}
 */

export const fetchPromotions = async () => {
  const DB = firebase.firestore();
  const exchangesCollection = DB.collection('exchanges');
  const userData = authStore.getState(USER_EVENT);

  const ownerRef = exchangesCollection.where('ownerPromotion', '==', userData.email);
  const userRef = exchangesCollection.where('user', '==', userData.email);

  let unionMap = new Map();
  let ownerPromos;
  let userPromos;

  try {
    ownerPromos = await ownerRef.get();
    userPromos = await userRef.get();
  } catch (err) {
    Flux.dispatch(REEDEM_ERROR_EVENT, err);
  }

  ownerPromos.forEach((doc) => {
    const { name, order, redeemDate, user, quantityAvailable, change } = doc.data();
    unionMap.set(doc.id, {
      name,
      order,
      redeemDate,
      user,
      quantityAvailable,
      change,
    });
  });

  userPromos.forEach((doc) => {
    const { name, order, redeemDate, user, quantityAvailable, change } = doc.data();
    unionMap.set(doc.id, {
      name,
      order,
      redeemDate,
      user,
      quantityAvailable,
      change,
    });
  });

  const unionList = unionMap.values();

  let listRedeem = [];
  for (let item of unionList) {
    listRedeem.push(item);
  }

  Flux.dispatchEvent(FETCH_REEDEM_EVENT, listRedeem);
};

/**
 *
 * @return {Promise<ProfileModel>}
 */

export const fetchExchanges = async (email = null, promoOnly = null) => {
  const DB = firebase.firestore();
  const userData = authStore.getState(USER_EVENT);
  let exchangesCollection = DB.collection('exchanges').where(
    'userMerchant',
    '==',
    email ? email : userData.email,
  );

  let exchangesRef;
  let exchangesList = [];
  const countDetail = []; //exchanges of a promo

  try {
    exchangesRef = await exchangesCollection.get();
    if (promoOnly) {
      for (const exchange of exchangesRef.docs) {
        const dataExchage = exchange.data();
        if (dataExchage) {
          dataExchage.promos.forEach((res) => {
            if (res.id === promoOnly) {
              return countDetail.push(res);
            }
          });
        }
      }
    }
  } catch (err) {
    Flux.dispatch(REEDEM_ERROR_EVENT, err);
  }

  exchangesRef.forEach((element) => {
    exchangesList.push(element.data());
  });

  Flux.dispatchEvent(FETCH_REEDEM_EVENT, promoOnly ? countDetail : exchangesList);
  return exchangesList;
};

/**
 *
 * @return {Promise<ProfileModel>}
 */

export const fetchExchangesTransaction = async () => {
  let exchanges;
  try {
    exchanges = await fetchExchanges();
  } catch (err) {
    console.log(err);
    Flux.dispatch(REEDEM_TRANSACTION_ERROR_EVENT, err);
  }
  Flux.dispatchEvent(FETCH_REEDEM_TRANSACTION_EVENT, exchanges);
};
