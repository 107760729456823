import React from 'react';
import { Redirect } from 'react-router-dom';
// import { Link } from 'react-router-dom';
// import { MDBContainer, MDBView, MDBMask, MDBRow, MDBCol } from 'mdbreact';
// import bgLanding from '../../assets/img/background.png';
// import bgImgOne from '../../assets/img/h-1.png';
// import bgImgTwo from '../../assets/img/h-2.png';
// import bgImgThree from '../../assets/img/h-3.png';
// import Logo from '../../assets/img/okrooBeta.svg';
// import Footer from '../../components/Footer';

class Landing extends React.Component {
  render() {
    if (window.location.host === 'localhost:3000' || window.location.host === 'main.okroo.com') {
      return <Redirect to="login" />;
    } 
    window.location.href = 'https://okroo.com';
    return;
    // if(redirect){
    //   return 
    // }
    // return (
    //   <MDBView src={bgLanding}>
    //     <MDBMask className="d-flex justify-content-start align-items-center padding-landing">
    //       <MDBContainer fluid className="cover-landing">
    //         <MDBRow>
    //           <MDBCol md="6" className="p-5">
    //             <div className="d-flex justify-content-end">
    //               <Link to="/login" className="text-white mr-3">
    //                 Sign in
    //               </Link>
    //               <Link to="/sign-up" className="text-white">
    //                 Sign up
    //               </Link>
    //             </div>
    //             <div className="p-2">
    //               <img src={Logo} alt="Okroo" width="210" />
    //               <div className="view-text-landing">
    //                 <h1 className="display-4 text-white font-weight-bold">
    //                   {/* Lorem ipsum dolor sit amet */}A new way to drive sales
    //                 </h1>
    //                 <p className="text-white">
    //                   Increase the likelihood of customer spend. Okroo customers can pre-purchase
    //                   promotions at happy hour prices from their favorite venues directly from the
    //                   app.
    //                 </p>
    //               </div>
    //             </div>
    //           </MDBCol>
    //           <MDBCol md="6" className="d-none d-sm-block">
    //             <MDBRow>
    //               <MDBCol md="5" className="p-0">
    //                 <div className="p-2 box-1">
    //                   <h6 className="text-white font-weight-bold">
    //                     {/* Lorem ipsum dolor sit amet consectetur */}
    //                     Profitable marketing with no upfront cost
    //                   </h6>
    //                   <small className="text-white">
    //                     {/* sed do eiusmod tempor incididunt ut labore et dolore
    //                     magna aliqua. */}
    //                     Gain exposure and add value for your customers without spending a dime
    //                   </small>
    //                 </div>
    //                 <div className="p-2 box-2" style={{ background: `url(${bgImgTwo})` }} />
    //               </MDBCol>
    //               <MDBCol md="7" className="pl-0">
    //                 <div className="p-2 box-3" style={{ background: `url(${bgImgOne})` }} />
    //                 <div className="p-2 box-4" style={{ background: `url(${bgImgThree})` }} />
    //                 <div className="p-2">
    //                   <h5 className="text-white text-center mt-3">
    //                     {' '}
    //                     {/* Lorem ipsum dolor sit amet consectetur */}
    //                     Contact us at info@okroo.com
    //                   </h5>
    //                 </div>
    //               </MDBCol>
    //             </MDBRow>
    //           </MDBCol>
    //         </MDBRow>
    //         <Footer />
    //       </MDBContainer>
    //     </MDBMask>
    //   </MDBView>
    // );
  }
}

export default Landing;
