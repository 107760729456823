import firebase from 'firebase';
import Flux from 'flux-state';
import {
  EDIT_PROFILE_EVENT,
  EDIT_PROFILE_ERROR,
  FETCH_SALES_EVENT,
  REMOVE_ACCOUNT_STRIPE,
  ERROR_REMOVE_ACCOUNT_STRIPE,
  EDIT_ACCOUNT_STRIPE_EVENT,
  EDIT_ACCOUNT_STRIPE_ERROR,
  DISABLED_PAYMENT_METHOD_EVENT,
  DISABLED_PAYMENT_METHOD_ERROR,
} from './profile-store';
import { authStore, USER_EVENT } from '../../auth/auth-store';

const ENDPOINT_FUNCTIONS = process.env.REACT_APP_ENDPOINT_FUNCTIONS;

export const editProfile = async (data) => {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users');
  const userData = authStore.getState(USER_EVENT);
  const userRef = usersCollection.doc(userData.email);
  const geoLocation = data.location;
  let location = null;
  if (geoLocation) {
    if (geoLocation.lat) {
      location = new firebase.firestore.GeoPoint(geoLocation.lat, geoLocation.lng);
    } else {
      location = new firebase.firestore.GeoPoint(geoLocation._lat, geoLocation._long);
    }
  } else {
    location = userRef.location;
  }
  userRef
    .set(
      {
        ...data,
        location,
      },
      { merge: true },
    )
    .then((doc) => {
      Flux.dispatchEvent(EDIT_PROFILE_EVENT, doc);
      Flux.dispatchEvent(USER_EVENT, {
        ...data,
        location,
      });
    })
    .catch((e) => {
      console.log(e);
      Flux.dispatchEvent(EDIT_PROFILE_ERROR, e);
    });
};

export const createAccountStripe = async (data, info, typeCard = false) => {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users');
  const userData = authStore.getState(USER_EVENT);
  const userRef = usersCollection.doc(userData.email);
  const { accountNumber, routingNumber, cardNumber, cvv, expDate } = info;
  const { nameInfo, lastNameInfo, phone, dateBirth, address } = data;
  const dataAccout = {
    holderName: `${nameInfo} ${lastNameInfo}`,
    accountNumber,
    routingNumber,
    emailVenue: data.email,
    nameVenue: data.name,
    name: nameInfo,
    lastName: lastNameInfo,
    place: address,
    phone,
    dateBirth,
    paymentMethod: typeCard ? 'debitCard' : 'bankAccount',
    cardNumber: cardNumber ? cardNumber.replace('-', '') : null,
    expDate,
    cvv,
  };
  try {
    const createAccount = await fetch(`${ENDPOINT_FUNCTIONS}createAccountVenue`, {
      body: JSON.stringify(dataAccout),
      headers: {
        Accept: 'application/json',
        'Accept-Language': 'en',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });
    const res = await createAccount.json();
    console.log('create account DATA ', res);
    if (res && res.success) {
      await userRef.set(
        {
          ...data,
          visibleMethod: true,
          stripeAccount: res.accountVenue.id,
          methodId: res.payoutMethod.methodId,
          bankName: res.payoutMethod.detailsMethod.bankName,
          brand: res.payoutMethod.detailsMethod.brand,
          last4Debit: res.payoutMethod.detailsMethod.last4,
        },
        { merge: true },
      );
      Flux.dispatchEvent(EDIT_PROFILE_EVENT, {
        message: 'Successfully registered account',
      });
      Flux.dispatchEvent(USER_EVENT, {
        ...data,
        visibleMethod: true,
        stripeAccount: res.accountVenue.id,
        methodId: res.payoutMethod.methodId,
        bankName: res.payoutMethod.detailsMethod.bankName,
        brand: res.payoutMethod.detailsMethod.brand,
        last4Debit: res.payoutMethod.detailsMethod.last4,
      });
    } else {
      Flux.dispatchEvent(EDIT_PROFILE_ERROR, {
        message: res.err
          ? res.err.raw.message
          : 'An error occurred on the server when registering the account',
      });
    }
  } catch (err) {
    Flux.dispatchEvent(EDIT_PROFILE_ERROR, {
      message: 'An error occurred on the server when registering the account',
    });
  }
};

export const updateAccountStripe = async (data) => {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users');
  const userData = authStore.getState(USER_EVENT);
  const userRef = usersCollection.doc(userData.email);
  const { nameInfo, lastNameInfo, phone, dateBirth, address, stripeAccount } = data;
  const dataAccout = {
    name: nameInfo,
    lastName: lastNameInfo,
    place: address,
    phone,
    dateBirth,
    accountId: stripeAccount,
  };

  try {
    const editAccount = await fetch(`${ENDPOINT_FUNCTIONS}updateAccountVenue`, {
      body: JSON.stringify(dataAccout),
      headers: {
        Accept: 'application/json',
        'Accept-Language': 'en',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    const res = await editAccount.json();
    console.log('UPDATE ACCOUTTT ', res);

    if (res && res.success && res.accountEdit) {
      await userRef.set(
        {
          ...data,
        },
        { merge: true },
      );
      Flux.dispatchEvent(EDIT_ACCOUNT_STRIPE_EVENT);
      Flux.dispatchEvent(USER_EVENT, {
        ...data,
      });
    } else {
      Flux.dispatchEvent(EDIT_ACCOUNT_STRIPE_ERROR, {
        message: res.err
          ? res.err.raw.message
          : 'An error occurred while editing account information',
      });
    }
  } catch (err) {
    Flux.dispatchEvent(EDIT_ACCOUNT_STRIPE_ERROR, {
      message: 'An error occurred while editing account information',
    });
  }
};

export const addPaymentMethod = async (data, info, typeCard = false) => {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users');
  const userData = authStore.getState(USER_EVENT);
  const userRef = usersCollection.doc(userData.email);
  const { cardNumber, expDate, cvv, accountNumber, routingNumber } = info;
  const { nameInfo, lastNameInfo, stripeAccount } = data;
  const dataAccout = {
    accountId: stripeAccount,
    holderName: `${nameInfo} ${lastNameInfo}`,
    accountNumber,
    routingNumber,
    paymentMethod: typeCard ? 'debitCard' : 'bankAccount',
    cardNumber: cardNumber ? cardNumber.replace('-', '') : null,
    expDate,
    cvv,
  };

  try {
    const addPayMethod = await fetch(`${ENDPOINT_FUNCTIONS}addPaymentMethodVenue`, {
      body: JSON.stringify(dataAccout),
      headers: {
        Accept: 'application/json',
        'Accept-Language': 'en',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    const res = await addPayMethod.json();
    console.log('ADD Method ', res);
    if (res && res.success) {
      await userRef.set(
        {
          ...data,
          visibleMethod: true,
          methodId: res.methodId,
          bankName: res.detailsMethod.bankName,
          brand: res.detailsMethod.brand,
          last4Debit: res.detailsMethod.last4,
        },
        { merge: true },
      );
      Flux.dispatchEvent(EDIT_PROFILE_EVENT, {
        message: typeCard
          ? 'Debit card successfully registered'
          : 'Bank account successfully registered',
      });
      Flux.dispatchEvent(USER_EVENT, {
        ...data,
        visibleMethod: true,
        methodId: res.methodId,
        bankName: res.detailsMethod.bankName,
        brand: res.detailsMethod.brand,
        last4Debit: res.detailsMethod.last4,
      });
    } else {
      Flux.dispatchEvent(EDIT_PROFILE_ERROR, {
        message: res.err
          ? res.err.raw.message
          : `An error occurred on the server when registering the ${
            typeCard ? 'Debit card' : 'Bank account'
          } `,
      });
    }
  } catch (err) {
    Flux.dispatchEvent(EDIT_PROFILE_ERROR, {
      message: `An error occurred on the server when registering the ${
        typeCard ? 'Debit card' : 'Bank account'
      } `,
    });
  }
};
const pauseAllPromotions = async (userEmail) => {
  const DB = firebase.firestore();
  const batch = DB.batch();
  const promotionsCollection = DB.collection('promotions');
  let data = [];
  promotionsCollection
    .where('user', '==', userEmail)
    .get()
    .then((promo) => {
      promo.forEach((doc) => {
        data.push(doc.id);
      });
      data.forEach((doc) => {
        console.log(doc);
        let promoRef = promotionsCollection.doc(doc);
        batch.update(promoRef, { statusUser: 'paused' });
      });
      batch
        .commit()
        .then((doc) => {
          return true;
        })
        .catch((e) => {
          console.log(e);
          throw e;
        });
    });
};

export const disabledPaymentMethod = (data, typeCard) => {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users');
  const userData = authStore.getState(USER_EVENT);
  const userRef = usersCollection.doc(userData.email);

  userRef
    .update({
      ...data,
      visibleMethod: false,
    })
    .then(() => {
      Flux.dispatchEvent(USER_EVENT, {
        ...data,
        visibleMethod: false,
      });
      Flux.dispatchEvent(DISABLED_PAYMENT_METHOD_EVENT, {
        message: `${typeCard ? 'Debit card' : 'Bank account'} has been successfully deleted`,
      });
      pauseAllPromotions(userData.email).then(() => {
        Flux.dispatchEvent(DISABLED_PAYMENT_METHOD_EVENT, {
          message: 'All your promotions have been paused until you add banking information.',
        });
      });
    })
    .catch(() => {
      Flux.dispatchEvent(DISABLED_PAYMENT_METHOD_ERROR, {
        message: `An error occurred while deleting the ${typeCard ? 'Debit card' : 'bank account'}`,
      });
    });
};

export const deletePaymentMethod = async (data) => {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users');
  const userData = authStore.getState(USER_EVENT);
  const userRef = usersCollection.doc(userData.email);
  const typeMethod = data.methodId ? (data.methodId.startsWith('card') ? 'card' : 'bank') : null;
  const dataClear = {
    methodId: '',
    bankName: '',
    brand: '',
    last4Debit: '',
  };

  try {
    const deletedMethod = await fetch(`${ENDPOINT_FUNCTIONS}deletePaymentMethod`, {
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Accept-Language': 'en',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    const res = await deletedMethod.json();

    console.log('DELETE ACCOUTTT ', res);

    if (res.success && res.deletedMethod.deleted) {
      const userClean = { ...userData, ...dataClear };
      userRef
        .update({
          ...dataClear,
        })
        .then(() => {
          Flux.dispatchEvent(USER_EVENT, userClean);
          Flux.dispatchEvent(REMOVE_ACCOUNT_STRIPE, {
            message: `${
              typeMethod === 'card' ? 'Debit card' : 'Bank account'
            } successfully deleted`,
          });
        })
        .catch(() => {
          Flux.dispatchEvent(ERROR_REMOVE_ACCOUNT_STRIPE, {
            message: `An error occurred while deleting the ${
              typeMethod === 'card' ? 'Debit card' : 'bank account'
            }`,
          });
        });
    } else {
      Flux.dispatchEvent(ERROR_REMOVE_ACCOUNT_STRIPE, {
        message: res.err
          ? res.err.raw.message
          : `An error occurred deleting the ${
            typeMethod === 'card' ? 'Debit card' : 'Bank account'
          }`,
      });
    }
  } catch (err) {
    Flux.dispatchEvent(EDIT_PROFILE_ERROR, {
      message: `An error occurred while deleting the ${
        typeMethod === 'card' ? 'Debit card' : 'bank account'
      }`,
    });
  }
};

export const fetchTotalSales = async (email = null, promoOnly = null) => {
  const DB = firebase.firestore();
  const userData = authStore.getState(USER_EVENT);
  const ordersCollection = DB.collection('orders').where(
    'merchant',
    '==',
    email ? email : userData.email,
  );
  let ordersData = [];
  const countDetail = [];
  try {
    ordersData = await ordersCollection.get();
    if (promoOnly) {
      for (const order of ordersData.docs) {
        const dataOrder = order.data();
        if (dataOrder) {
          dataOrder.promos.forEach((res) => {
            if (res.id === promoOnly) {
              return countDetail.push(res);
            }
          });
        }
      }
    }
  } catch (err) {
    console.log('err', err);
  }

  Flux.dispatchEvent(FETCH_SALES_EVENT, promoOnly ? countDetail : ordersData.size);
};
