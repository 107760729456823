import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MDBBtn, MDBModal, MDBModalBody, MDBRow, MDBCol, MDBInput } from 'mdbreact';

class RankModalComponent extends Component {
  state = {
    modal: false,
    rankValue: null,
    flagDisabled: false,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      rankValue: nextProps.promotion.rank || 0,
    });
  }
  closeAction = () => {
    this.props.closeModal();
  };

  confirmAction = () => {
    this.setState({
      flagDisabled: true,
    });
    this.props.onSubmit(this.state.rankValue, this.props.promotion.promoID);
    this.closeAction();
  };

  onChange = (e) => {
    e.preventDefault();
    this.setState({
      rankValue: e.target.value,
    });
  };
  render() {
    const { flagDisabled } = this.state;
    return (
      <>
        <MDBModal size="sm" centered className="modal-notify" isOpen={this.props.open}>
          <MDBModalBody>
            Would You Like To Change The Rank Of This Promotion?
            <MDBInput
              label=""
              className="mt-0"
              onChange={this.onChange}
              value={this.state.rankValue}
              type="number"
              maxLength="2"
            />
          </MDBModalBody>

          <MDBRow middle="true">
            <MDBCol>
              <MDBBtn
                className="btn btn-circle-danger mt-4 mb-6"
                onClick={this.closeAction}
                disabled={flagDisabled}>
                {' '}
                Cancel{' '}
              </MDBBtn>
              <MDBBtn
                className="btn btn-circle mt-4  mb-6"
                onClick={this.confirmAction}
                disabled={flagDisabled}>
                {' '}
                Change{' '}
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBModal>
      </>
    );
  }
}

RankModalComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  open: PropTypes.bool,
  promotion: PropTypes.object.isRequired,
};

export default RankModalComponent;
