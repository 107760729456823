import React from 'react';
import { MDBContainer, MDBCol, MDBRow, MDBInput, MDBBtn } from 'mdbreact';
import { FormGroup, Input } from 'reactstrap';
import Autocomplete from 'react-google-autocomplete';
import { Loader } from '../../../components/Loader';
import SidebarComponent from '../../../components/SidebarComponent';
import Navbar from '../../../components/NavBar';
import View from 'react-flux-state';
import { editProfile } from './profile-actions';
import {
  profileStore,
  EDIT_PROFILE_EVENT,
  EDIT_PROFILE_ERROR,
  REMOVE_ACCOUNT_STRIPE,
  ERROR_REMOVE_ACCOUNT_STRIPE,
  // LOCATION_MAP_EVENT
} from './profile-store';
import { authStore, USER_EVENT } from '../../auth/auth-store';
import { toast } from 'react-toastify';
import MapContainer from './components/MapContainer';

const API_KEY = process.env.REACT_APP_API_KEY;
class EditProfileView extends View {
  constructor(props) {
    super(props);
    this.state = {
      user: authStore.getState(USER_EVENT),
      place: null,
      loading: false,
      targetMap: null,
      errors: {},
    };
  }

  componentDidMount() {
    this.subscribe(profileStore, EDIT_PROFILE_EVENT, () => {
      this.setState({
        loading: false,
      });
      setTimeout(() => {
        toast.success('Profile Saved');
        this.props.history.push('/profile');
      }, 300);
    });

    this.subscribe(authStore, USER_EVENT, (data) => {
      this.setState({
        user: data,
      });
    });

    this.subscribe(profileStore, EDIT_PROFILE_ERROR, (e) => {
      this.setState({
        loading: false,
      });
      setTimeout(() => {
        toast.error(e.message);
      }, 300);
    });

    this.subscribe(profileStore, REMOVE_ACCOUNT_STRIPE, (data) => {
      this.setState({
        loading: false,
      });
      setTimeout(() => {
        toast.success(data.message);
      }, 300);
    });

    this.subscribe(profileStore, ERROR_REMOVE_ACCOUNT_STRIPE, (e) => {
      this.setState({
        loading: false,
      });
      setTimeout(() => {
        toast.error(e.message);
      }, 300);
    });

    if (this.state.user && this.state.user.location) {
      setTimeout(() => {
        this.setState({
          place: {
            place: this.state.user.locationName,
            coordinates: {
              lat: this.state.user.location.latitude,
              lng: this.state.user.location.longitude,
            },
          },
          targetMap: {
            lat: this.state.user.location.latitude,
            lng: this.state.user.location.longitude,
          },
        });
      }, 400);
    }
  }

  onChange = (e) => {
    const data = { ...this.state.user }; // copy state
    data[e.target.name] = e.target.value;
    this.setState({
      user: data,
    });
  };

  onClick = () => {
    const { place, user } = this.state;
    const dataCopy = { ...user };
    if (
      !Object.keys(this.validate({ ...dataCopy, coordinates: place && place.coordinates })).length
    ) {
      dataCopy.locationName = this.state.place.place;
      dataCopy.location = this.state.place.coordinates;
      this.setState({
        loading: true,
      });
      return editProfile(dataCopy);
    }
    return this.setState({
      errors: this.validate({ ...dataCopy, coordinates: place && place.coordinates }),
    });
  };

  validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Venue name is required';
    }
    if (!values.coordinates) {
      errors.coordinates = 'Location is required';
    }
    if (!values.description) {
      errors.description = 'Venue description is required';
    }

    return errors;
  };

  render() {
    const { targetMap, errors } = this.state;
    return (
      <React.Fragment>
        <SidebarComponent>
          <Navbar nameView="Edit Profile" />
          {this.state.loading ? (
            <Loader />
          ) : (
            <MDBContainer className="body">
              <MDBRow className="d-flex justify-content-center">
                <MDBCol md="9">
                  <MDBRow>
                    <MDBCol md="9" style={{ paddingRight: '0px', paddingLeft: '0px' }}>
                      <div>
                        <h3 className="font-weight-bold d-inline-block mb-4">
                          {this.state.user.name}
                        </h3>
                        <MDBBtn
                          className=" d-inline-block float-right m-0 btn btn-circle btn-circle-link"
                          disabled={this.state.flag}
                          onClick={() => this.onClick()}>
                          Save
                        </MDBBtn>
                      </div>
                      <div>
                        <MDBInput
                          label="Venue name"
                          name="name"
                          value={this.state.user.name}
                          className="mt-0"
                          onChange={this.onChange}
                        />
                        {errors.name && <p className="is-red-valid">{errors.name}</p>}
                        <label
                          className="active disabled"
                          style={{ fontWeight: 'bold', fontSize: 14 }}>
                          Location
                        </label>
                        <Autocomplete
                          apiKey={API_KEY}
                          style={{
                            width: '100%',
                            borderWidth: 0,
                            borderBottomWidth: 1,
                          }}
                          onChange={(e) => {
                            this.setState({
                              place: {
                                place: e.target.value,
                              },
                            });
                          }}
                          onPlaceSelected={(place) => {
                            const data = {
                              place: place.formatted_address,
                              coordinates: {
                                lat: place.geometry.location.lat(),
                                lng: place.geometry.location.lng(),
                              },
                            };
                            this.setState({
                              place: data,
                              targetMap: {
                                lat: place.geometry.location.lat(),
                                lng: place.geometry.location.lng(),
                              },
                            });
                          }}
                          value={this.state.place ? this.state.place.place : ''}
                          types={['geocode', 'establishment']}
                        />
                        {errors.coordinates && <p className="is-red">{errors.coordinates}</p>}
                      </div>
                      <br />
                      <label
                        className="active disabled"
                        style={{ fontWeight: 'bold', fontSize: 14 }}>
                        Venue description (This is what will be displayed in the app)
                      </label>
                      <FormGroup>
                        <Input
                          type="textarea"
                          name="description"
                          id="description"
                          value={this.state.user.description}
                          onChange={this.onChange}
                        />
                        {errors.description && <p className="is-red">{errors.description}</p>}
                      </FormGroup>
                      <div
                        style={{
                          boxShadow: '0px 2px 10px 1px #00000040',
                        }}>
                        {targetMap ? <MapContainer coordinates={targetMap} /> : null}
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          )}
        </SidebarComponent>
      </React.Fragment>
    );
  }
}

export default EditProfileView;
