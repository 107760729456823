import Flux from 'flux-state';
/**
 * @property {string} FETCH_PROMOTION_EVENT triggers when a user tries to get all their promotions
 * @property {string} PROMOTION_ERROR_EVENT shows an error when there's an error fetching the promotion info
 */
export const PROMOTION_EVENT = 'PROMOTION_EVENT';
export const PROMOTION_ERROR_EVENT = 'PROMOTION_ERROR_EVENT';
export const FETCH_PROMOTION_EVENT = 'FETCH_PROMOTION_EVENT';
export const PROMOTION_DETAIL_EVENT = 'PROMOTION_DETAIL_EVENT';
export const PAUSE_PROMOTION_EVENT = 'PAUSE_PROMOTION_EVENT';
export const ARCHIEVE_PROMOTION_EVENT = 'ARCHIEVE_PROMOTION_EVENT';
export const COPY_PROMOTION_EVENT = 'COPY_PROMOTION_EVENT';
export const FETCH_USER_EVENT = 'FETCH_USER_EVENT';
export const FETCH_ALL_USER_EVENT = 'FETCH_ALL_USER_EVENT';
export const CHANGE_PROMOTION_EVENT = 'CHANGE_PROMOTION_EVENT';
export const FETCH_PROMOTION_ENDS_ADMIN_EVENT = 'FETCH_PROMOTION_ENDS_ADMIN_EVENT';

class PromotionStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(PROMOTION_EVENT);
    this.addEvent(PROMOTION_ERROR_EVENT);
    this.addEvent(FETCH_PROMOTION_EVENT);
    this.addEvent(PROMOTION_DETAIL_EVENT);
    this.addEvent(ARCHIEVE_PROMOTION_EVENT);
    this.addEvent(PAUSE_PROMOTION_EVENT);
    this.addEvent(COPY_PROMOTION_EVENT);
    this.addEvent(FETCH_USER_EVENT);
    this.addEvent(FETCH_ALL_USER_EVENT);
    this.addEvent(CHANGE_PROMOTION_EVENT);
    this.addEvent(FETCH_PROMOTION_ENDS_ADMIN_EVENT);
  }
}
const promotionStore = new PromotionStore();

export { promotionStore };
