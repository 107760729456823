import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';

const TermsMerchant = () => (
  <>
    <h1 className="d-flex justify-content-center p-4 ">Okroo merchant</h1>
    <MDBContainer>
      <MDBRow style={{ textAlign: 'justify' }}>
        <MDBCol md="12" className="justify-content-center">
          <p>
            These Merchant Terms and Conditions (the “Terms and Conditions”) govern and are
            incorporated into the Okroo Merchant Agreement between Okroo and Merchant (collectively,
            the “Agreement”). Okroo, subject to the provisions of this paragraph, may amend the
            Terms and Conditions in its sole discretion and at any time.
          </p>
          <p>
            <u>
              <b>1) Promotions</b>
            </u>
          </p>
          <p>
            a. Developer, Okroo, LLC. (hereinafter “Okroo” “Developer” or “we” or “us” or “our”) is
            authorized to promote and sell Promotions on Merchant’s behalf subject to the terms of
            this Agreement. The Promotion will evidence the Merchant Offering and will be sent to
            the purchaser via mobile app once payment is received. The purchaser will then redeem
            the Promotion with the Merchant by presenting the Promotion confirmation screen on their
            mobile app. Merchant is the issuer of the Promotion and seller of the Merchant Offering.
          </p>
          <p>
            b. Okroo is authorized to promote and sell Promotions on Merchant’s behalf through any
            Okroo platform, affiliates, business partner network, marketplace, or referral network.
            The Promotion may be offered to all or part of Okroo’s subscriber base or its affiliate
            subscriber base or referral network and segmented by various variables including gender,
            age, location, and consumer preferences. The features may be offered through a variety
            of distribution channels, including, the Internet, the Website, affiliate websites,
            business partner network, email, mobile applications, other types of electronic
            offerings and other platforms or distribution channels owned, controlled, or operated by
            Okroo, its affiliates or business partners.
          </p>
          <p>
            c. For appointment-based Merchant Offerings, Okroo may require that Merchant provide
            Okroo with a calendar of available appointment times and allow Okroo purchasers to
            schedule appointments with the Merchant through Okroo and any third-party service Okroo
            may use. Okroo may audit Merchant response times using various methods, If Okroo, in its
            sole discretion, determines Merchant response times or the quality of service provided
            to purchasers is unsatisfactory, Okroo may terminate the Agreement and return the Amount
            Paid to purchasers for unredeemed Promotion.
          </p>
          <p>
            d. Okroo is authorized to promote and sell up to the Maximum Number of Promotions in
            multiple markets and on dates in its discretion. If Merchant elects to offer recurring
            month-to-month features, then Okroo will promote and sell up to the Maximum Number of
            Promotions for the initial feature, then promote and sell up to the Monthly Maximum
            Number of Promotions for subsequent features. Merchant shall specify the Maximum Number
            of Promotions and, if applicable, specify the Monthly Maximum Number of Promotions, and
            may increase either number in its discretion.
          </p>
          <p>
            e. Okroo reserves the continuing right to reject, revise, or discontinue any Merchant
            Offering, at any time and for any reason in Okroo’s sole discretion, and to terminate
            the Merchant Offering and to remove all references to the Merchant Offering and
            Promotion from the Website and/or mobile app; and redirect or delete any URL used in
            connection with the Merchant Offering.
          </p>
          <p>
            f. Merchant shall honor the Promotions for the Merchant Offering through the Promotional
            Value Expiration Date. After the Promotional Value Expiration Date, MERCHANT AGREES TO
            REDEEM THE PROMOTION FOR THE AMOUNT PAID WITHIN A REASONABLE TIME FRAME AS AGREED
            BETWEEN THE MERCHANT AND OKROO OR AS STATED ON THE PROMOTIONS DETAILS SECTION. Okroo the
            developer shall be held harmless should the Merchant fail to accept promotional purchase
            agreement.
          </p>
          <p>
            g. After the Promotional Value Expiration Date, Merchant must allow the purchaser to
            redeem the Promotion for the full Value toward the Merchant Offering. If the goods and
            services constituting the Merchant Offering and stated on the Promotion are no longer
            available, the Merchant must allow the purchaser to redeem the Promotion toward any
            goods or services then offered by the Merchant equivalent to at least the Value of the
            Promotion.
          </p>
          <p>
            h. Partial redemptions: If applicable, and if a purchaser redeems a Promotion for less
            than the full value, the Merchant is responsible for handling any unredeemed value as
            required by applicable law.
          </p>
          <p>
            i. Merchant agrees that in providing the Merchant Offering, Merchant will not inflate
            prices or impose any additional fees, charges, conditions or restrictions that
            contradict or are inconsistent with the terms stated on the Promotion, including the
            details section and Fine Print. Unless disclosed in the details section, Merchant
            further agrees not to impose different terms or a different cancellation policy than
            what is imposed on its non-Okroo customers.
          </p>
          <p>
            j. Merchant agrees that so long as an appointment or reservation is made to redeem a
            Promotion, or purchaser has made an attempt to make an appointment, before the
            Promotional Value Expiration Date, the Promotion will be honored for the Full Offer
            Value without restriction, even though the services may be provided after the
            Promotional Value Expiration Date.
          </p>
          <p>
            k. For any seasonal Merchant Offering(s), following the initial Promotional Value
            Expiration Date specified in the Okroo Merchant Agreement the: (i) Promotional Value
            Expiration Date will reset to be the end of the immediately following season specified
            by Merchant; and (ii) Maximum Number of Promotions will reset for the immediately
            following season. Throughout the Term (as defined herein), the Promotional Value
            Expiration Date and the Maximum Number of Promotions for the seasonal Merchant
            Offering(s) will continue to reset after each season.
          </p>
          <p>
            l. Merchant is responsible for all customer service in connection with the Merchant
            Offering and for supplying all goods and services stated in the Merchant Offering.
            Merchant is also responsible for any customer loyalty programs associated with the
            Merchant Offering.
          </p>
          <p>
            m. If applicable, Merchant will hold the Merchant Offering for pick-up by each purchaser
            at the Redemption Site. The “Redemption Site” is the complete and accurate address
            provided by Merchant to Okroo where purchasers can redeem the Promotion to pick-up the
            Merchant Offering. Merchant also agrees to provide Okroo with the hours and dates of
            operation, complete with any exceptions, and a valid phone number for the Redemption
            Site. If any of the information related to a Redemption Site changes, Merchant agrees to
            notify Okroo immediately of such change.
          </p>
          <p>
            n. Okroo will be sole and exclusive distributor of Promotions provided by Merchant on
            Okroo Site, Mobile app, and/or any affiliate platform owned or operated by Okroo.
            Promotions offered on any authorized Okroo platform will be exclusively distributed by
            Okroo. Violation of this term will result in immediate removal of Merchant Promotion(s)
            on all Okroo platforms and/or subject to termination of this Agreement by Okroo.
          </p>
          <p>
            <u>
              <b>2) Customer Data Restrictions</b>
            </u>
          </p>
          <p>
            a. Merchant shall use Customer Data only to fulfill its redemption obligations in
            connection with the Merchant Offering as authorized by this Agreement. Merchant
            expressly agrees that any Customer Data shall be used only for this purpose (including,
            but not limited to, the redemption of Promotions and provision of goods and services to
            purchasers), and not to enhance a file or list owned by Merchant, or any third party.
            Merchant represents, warrants and covenants that it will not resell, broker or otherwise
            disclose any Customer Data to any third party, in whole or in part, for any purpose,
            unless required by applicable law. If Merchant engages any third party to facilitate its
            redemption obligations hereunder, Merchant shall ensure that such third party implements
            and complies with reasonable security measures in handling any Customer Data. If any
            Customer Data is collected directly by Merchant or a third party engaged by Merchant to
            facilitate its redemption obligations hereunder, Merchant shall ensure that it or such
            third party adopts, posts and processes the Customer Data in conformity with its posted
            privacy policy and all applicable laws.
          </p>
          <p>
            b. As long as Merchant uses Customer Data in compliance with applicable law and
            Merchant’s posted privacy policy, restrictions stated in this Agreement on Merchant’s
            use of Customer Data do not apply to: (i) data from any purchaser who is already a
            customer of Merchant before the Effective Date, if such data was provided to Merchant by
            such purchaser independent of this Agreement or any transaction hereunder; or (ii) data
            supplied by a purchaser directly to Merchant who becomes a customer of Merchant in
            connection with such purchaser explicitly opting in to receive communications from
            Merchant.
          </p>
          <p>
            c. Merchant shall immediately notify Okroo if Merchant becomes aware of or suspects any
            unauthorized access to or use of Customer Data or any confidential information of Okroo
            and shall cooperate with Okroo in the investigation of such breach and the mitigation of
            any damages. Merchant will bear all associated expenses incurred by Okroo to comply with
            applicable laws (including, but not limited to, any data breach laws) or arising from
            any unauthorized access or acquisition of Customer Data while such data is in Merchant’s
            reasonable possession or control. Upon termination or expiration of this Agreement,
            Merchant shall, as directed by Okroo, destroy or return to Okroo all the Customer Data
            in Merchant’s or any agent of Merchant’s possession.
          </p>
          <p>
            <u>
              <b>3) Promotional Programs</b>
            </u>
          </p>
          <p>
            To incentivize Promotion sales, Merchant authorizes Okroo, at any time and in Okroo’s
            sole discretion, to increase or decrease the Amount Paid for the Promotion (any such
            effort, “Promotional Program(s)”).
          </p>
          <p>
            <u>
              <b>4) Term and Termination</b>
            </u>
          </p>
          <p>
            This Agreement will continue in effect until terminated by either party in accordance
            with this Section (“Term”). Okroo is authorized to terminate this Agreement, at any time
            for any reason, upon written notice to Merchant. Merchant is authorized to terminate
            this Agreement upon seven (7) business days prior written notice to Okroo. Termination
            of this Agreement will not in any way affect Merchant’s obligation to redeem any
            Promotion according to the terms of this Agreement, including the obligation to honor
            the Promotion for the full value after the Promotional Value Expiration Date. Provisions
            in this Agreement that are intended to survive termination will continue in full force
            and effect after the Term.
          </p>
          <p>
            <u>
              <b>5) Compliance with Gift Card, Gift Certificate and Abandoned Property Laws</b>
            </u>
          </p>
          <p>
            Merchant agrees to comply with the terms and conditions as stated on the Website,
            including but not limited to the “Terms of Use” of the Website, and to ensure that the
            Promotions comply with all laws that govern vouchers, gift cards, coupons, and gift
            certificates, including but not limited to the United States Credit CARD Act of 2009 (if
            applicable) and any laws governing the imposition of expiration dates, service charges
            or dormancy fees and all Fine Print related to the Merchant Offering stated on the
            Promotion details section. Merchant agrees that, regardless of the payment terms,
            Merchant, and not Okroo, maintains any obligation for unredeemed Promotions under
            applicable escheat or abandoned or unclaimed property laws.
          </p>
          <p>
            <u>
              <b>6) Marketing</b>
            </u>
          </p>
          <p>
            Okroo and its business partners may communicate with Merchant about products,
            promotions, and other services that may be of interest to Merchant. This may include
            email or other communications. Okroo may also solicit Merchant’s opinion for market
            research purposes.
          </p>
          <p>
            <u>
              <b>7) Intellectual Property Rights</b>
            </u>
          </p>
          <p>
            a. Merchant grants to Okroo a non-exclusive, worldwide, royalty free, paid-up,
            perpetual, irrevocable, transferable and sub-licensable license and right to use,
            modify, reproduce, sublicense, publicly display, distribute, broadcast, transmit,
            stream, publish and publicly perform: (a) Merchant’s name, logos, trademarks, service
            marks, domain names, and any audiovisual content, video recordings, audio recordings,
            photographs, graphics, artwork, text and any other content provided, specified,
            recommended, directed, authorized or approved to use by Merchant (collectively,
            “Merchant IP”); and (b) any third party’s name, logos, trademarks, service marks, domain
            names, audiovisual recordings, video recordings, audio recordings, photographs,
            graphics, artwork, text and any other content provided, specified, recommended,
            directed, authorized or approved for use by Merchant (collectively, “Third Party IP”),
            in each case in connection with the promotion, sale/resale (as may be applicable) or
            distribution of the Merchant Offering in all media or formats now known or hereinafter
            developed (“License”). Any use of the Merchant IP or Third-Party IP as contemplated in
            this Agreement is within Okroo’s sole discretion.
          </p>
          <p>
            b. Merchant acknowledges and agrees that, as between the parties, Okroo owns all
            interest in and to the Website, Customer Data, Okroo trade names, logos, trademarks,
            service marks, domain names, social media identifiers, all data collected through or
            from the Website, all audiovisual content, video recordings, audio recordings,
            photographs, graphics, artwork, text or any other content created by Okroo or at Okroo’s
            direction, or assigned to Okroo, and any materials, software, technology or tools used
            or provided by Okroo to promote, sell/resell (as may be applicable) or distribute the
            Merchant Offering and conduct its business in connection therewith (collectively “Okroo
            IP”). Merchant shall not use, sell, rent, lease, sublicense, distribute, broadcast,
            transmit, stream, place shift, transfer, copy, reproduce, download, time shift, display,
            perform, modify or timeshare the Okroo IP or any portion thereof, or use such Okroo IP
            as a component of or a base for products or services prepared for commercial use, sale,
            sublicense, lease, access or distribution, except that Okroo grants Merchant a limited,
            non-exclusive, revocable, non-transferable, non-sub licensable license during the Term
            to use one copy of Okroo’s mobile merchant software application on a single mobile
            computer, tablet computer, or other device, solely for the purposes permitted by that
            software, and to make one copy of the software for back-up purposes. Merchant shall keep
            the Okroo IP confidential and shall not prepare any derivative work based on the Okroo
            IP or translate, reverse engineer, decompile or disassemble the Okroo IP. Merchant shall
            not take any action to challenge or object to the validity of Okroo’s rights in the
            Okroo IP or Okroo’s ownership or registration thereof. Except as specifically provided
            in this Agreement, Merchant and any third-party assisting Merchant with its obligations
            in this Agreement, are not authorized to use Okroo’s IP in any medium without prior
            written approval from an authorized representative of Okroo. Merchant shall not include
            any trade name, trademark, service mark, domain name, social media identifier, of Okroo
            or its affiliates, or any variant or misspelling thereof, in any trademark, domain name,
            email address, social network identifier, metadata or search engine keyword. Merchant
            shall not use or display any Okroo IP in a manner that could reasonably imply an
            endorsement, relationship, affiliation with, or sponsorship between Merchant or a third
            party and Okroo. All rights to the Okroo IP not expressly granted in this Agreement are
            reserved by Okroo.
          </p>
          <p>
            c. If Merchant provides Okroo or any of its affiliates with feedback, suggestions,
            reviews, modifications, data, images, text, or other information or content about a
            Okroo product or service or otherwise in connection with this Agreement, any Okroo IP,
            or Merchant’s participation in the Merchant Offering or Promotion, (collectively,
            “Feedback”), Merchant irrevocably assigns to Okroo all right, title, and interest in and
            to Feedback. In the event your assignment to Okroo is invalid for any reason, you hereby
            irrevocably grant Okroo and its affiliates a perpetual, paid-up, royalty-free,
            nonexclusive, worldwide, irrevocable, freely transferable right and license to (i) use,
            reproduce, perform, display, and distribute Feedback; (ii) adapt, modify, re-format, and
            create derivative works of Feedback for any purpose and sublicense the foregoing rights
            to any other person or entity. Merchant warrants that: (A) Feedback is Merchant’s
            original work, or Merchant obtained Feedback in a lawful manner; and (B) Okroo and its
            sublicensees’ exercise of rights under the license above will not violate any person’s
            or entity’s rights, including any copyright rights. Merchant agrees to provide Okroo
            such assistance as Okroo might require documenting, perfect, or maintain Okroo’s rights
            in and to Feedback.
          </p>
          <p>
            <u>
              <b>8) Representations and Warranties</b>
            </u>
          </p>
          <p>
            Merchant represents and warrants that: (a) Merchant has the right, power and authority
            to enter into this Agreement; (b) Merchant, if required by applicable law, is registered
            for sales and use tax collection purposes in all jurisdictions where Merchant’s goods
            and services will be provided; (c) the Promotion, upon being delivered by Okroo, will be
            available immediately for redemption and Merchant will have sufficient goods and/or
            services available for redemption through the Promotional Value Expiration Date (i.e., a
            number of goods and/or services sufficient to fulfill its redemption obligations in
            connection with the applicable Maximum Number of Promotions); (d) the terms and
            conditions of the Promotion, including any discounts or goods and services offered
            thereunder do not and will not violate any, local, state, provincial, territorial or
            federal law, statute, rule, regulation, or order, including but not limited to, any law
            or regulation governing the use, sale, and distribution of alcohol and any laws
            governing vouchers, gift cards, coupons, and gift certificates; (e) the Merchant’s
            redemption of the Promotion will result in the bona fide provision of goods and/or
            services by Merchant to the purchaser; (f) Merchant owns all interest in and to the
            Merchant IP and has licensing rights in (with the right to sublicense to Okroo) the
            Third Party IP, and has the right to grant the License stated in this Agreement; (g) the
            Merchant IP and the Third Party IP, the Merchant Offering, Okroo’s use and promotion
            thereof, and the results of such Merchant Offerings, will not infringe, dilute,
            misappropriate, or otherwise violate, anywhere in the world, any patent, copyright,
            logo, trademark, service mark, trade name, rights in designs, or other intellectual
            property right or right of privacy or publicity of any third party or any applicable
            law, and does not and will not result from the misappropriation of any trade secret or
            the breach of any confidentiality obligations to any person or entity; (h) the Merchant
            IP and Third Party IP does not include any material that is unlawful, threatening,
            abusive, defamatory, vulgar, obscene, profane or otherwise objectionable, or that
            encourages conduct that constitutes a criminal offense, gives rise to civil liability or
            otherwise violates any law; (i) the Promotions and any advertising of Merchant’s goods
            and services relating thereto will not constitute false, deceptive or unfair advertising
            or disparagement under any applicable law; (j) Merchant and its employees, contractors
            and agents have had the proper education and training and hold all required and
            up-to-date regulatory authorization, licenses and certifications relating to any
            Merchant Offering to provide the goods or services described in this Agreement; (l)
            Merchant is not authorized to resell, broker or otherwise disclose any Customer Data (as
            defined in this Agreement) to any third party, in whole or in part, for any purpose, and
            Merchant is not authorized to copy or otherwise reproduce any Customer Data other than
            for the purpose of redeeming or verifying the validity of Promotions in connection with
            this Agreement and (m) the Merchant Offering is: (i) free from defects in workmanship,
            materials and design, (ii) merchantable and suitable for the purposes, if any, stated in
            the Agreement, and (iii) genuine, bona fide products, as described herein and does not
            violate the rights of any third party.
          </p>
          <p>
            <u>
              <b>9) Indemnification</b>
            </u>
          </p>
          <p>
            To the extent allowed under applicable law, Merchant agrees to defend, indemnify and
            hold Okroo, its affiliated and related entities, and any of its respective officers,
            directors, agents and employees, harmless from and against any claims, lawsuits,
            investigations, penalties, damages, losses or expenses (including but not limited to
            reasonable attorneys’ fees and costs) arising out of or relating to any of the
            following: (a) any breach or alleged breach by Merchant of this Agreement, or the
            representations and warranties made in this Agreement; (b) any claim for state sales,
            use, or similar tax obligations of Merchant arising from the sale and redemption of a
            Promotion; (c) any claim by any local, state, provincial, territorial or federal
            governmental entity for unredeemed Promotions or unredeemed promotional values or any
            other amounts under any applicable abandoned or unclaimed property or escheat law,
            including but not limited to any claims for penalties and interest; (d) any claim
            arising out of a violation of any law or regulation by Merchant or governing Merchant’s
            goods and/or services; (e) any claim arising out of Merchant’s violation of law or
            regulation governing the use, sale, and distribution of alcohol; (f) any claim by a
            purchaser or anyone else arising out of or relating to the goods and services provided
            by Merchant and/or pick up of the goods and services at the Redemption Site, including
            but not limited to, any claims for false advertising, product defects, personal injury,
            death, or property damages; (g) any claim by a purchaser for the Amount Paid; (h) any
            claim arising out of Merchant’s misuse of Customer Data, or any violation of an
            applicable data privacy or security law; and (i) any claim arising out of Merchant’s
            negligence, fraud or willful misconduct. Okroo maintains the right to control its own
            defense and to choose and appoint its own defense counsel, regardless of the presence or
            absence of a conflict of interest between Okroo and Merchant. Merchant’s duty to defend
            and indemnify Okroo includes the duty to pay Okroo’s reasonable attorneys’ fees and
            costs, including any expert fees.
          </p>
          <p>
            <u>
              <b>10) Confidentiality</b>
            </u>
          </p>
          <p>
            The terms for the Merchant Offering described in this Agreement are confidential, and
            Merchant agrees not to disclose the terms described in this Agreement to any party
            (other than to its employees, parent companies, shareholders, lawyers and accountants on
            a strict need-to-know basis or as required by applicable public records and other law,
            if Merchant has taken the necessary precautions of the kind generally taken with
            confidential information to preserve the confidentiality of the information made
            available to such parties). In the event of a breach, Okroo is entitled to injunctive
            relief and a decree for specific performance, and any other relief allowed under
            applicable law (including monetary damages if appropriate).
          </p>
          <p>
            <u>
              <b>11) Limitation of Liability</b>
            </u>
          </p>
          <p>
            EXCEPT FOR MERCHANT’S INDEMNIFICATION OBLIGATIONS HEREUNDER, IN NO EVENT IS EITHER PARTY
            LIABLE OR OBLIGATED TO THE OTHER PARTY OR ANY THIRD PARTY FOR ANY LOST PROFITS, LOST
            BUSINESS, SPECIAL, INCIDENTAL, EXEMPLARY, CONSEQUENTIAL, PUNITIVE, OR INDIRECT DAMAGES
            REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT OR OTHERWISE, EVEN IF
            INFORMED OF THE POSSIBILITY OF ANY SUCH DAMAGES IN ADVANCE.
          </p>
          <p>
            <u>
              <b>12) Other</b>
            </u>
          </p>
          <p>
            a. The parties are independent contractors. Nothing in this Agreement is to be construed
            to create a joint venture, partnership, franchise, or an agency relationship between the
            parties. Neither party has the authority, without the other party’s prior written
            approval, to bind or commit the other in any way.
          </p>
          <p>
            b. This Agreement constitutes the entire agreement between the parties relating to its
            subject matter and supersedes all prior or contemporaneous oral or written agreements
            concerning such subject matter.
          </p>
          <p>
            c. Merchant is not authorized to transfer or assign its rights or obligations under this
            Agreement, whether by operation of law or otherwise, without Okroo’s prior written
            consent. Any waiver must be in writing and signed by an authorized signatory of Okroo.
            Okroo is authorized to transfer or assign this Agreement to a present or future
            affiliate or pursuant to a merger, consolidation, reorganization or sale of all or
            substantially all the assets or business, or by operation of law, without notice to
            Merchant.
          </p>
          <p>
            d. If any provision of this Agreement should be held to be invalid or unenforceable, the
            validity and enforceability of the remaining provisions of this Agreement are not
            affected.
          </p>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  </>
);

export default TermsMerchant;
