export const UserModel = {
  id: null,
  admin: false,
  name: '',
  email: '',
  location: null,
  locationName: '',
  description: '',
  picture: null,
  nameInfo: '',
  lastNameInfo: '',
  phone: '',
  stripeAccount: '',
  methodId: '',
  bankName: '',
  brand: '',
  last4Debit: '',
  address: '',
  dateBirth: '',
  visibleMethod: false,
};

export const loginModel = {
  email: '',
  password: '',
};
