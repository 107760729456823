import React from 'react';
import View from 'react-flux-state';
import { toast } from 'react-toastify';
import { MDBContainer } from 'mdbreact';
import {
  promotionRedeemStore,
  REEDEM_TRANSACTION_ERROR_EVENT,
  FETCH_REEDEM_TRANSACTION_EVENT,
} from './promotionRedeem-store';
import moment from 'moment';
import {
  // fetchPromotions,
  fetchExchangesTransaction,
} from './promotionRedeem-action';
import SidebarComponent from '../../../components/SidebarComponent';
import TableRedemption from './components/TableRedemption';
import Navbar from '../../../components/NavBar';
import { Loader } from '../../../components/Loader';

class PromotionRedemptionScreen extends View {
  constructor(props) {
    super(props);
    this.state = {
      exchanges: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.subscribe(promotionRedeemStore, FETCH_REEDEM_TRANSACTION_EVENT, (data) => {
      const exchangeDate = data.map((res) => {
        res.createdDate = moment(res.createdDate.seconds * 1000).format('MM/DD/YYYY, h:mm:ss a');
        return res;
      });
      const dataExchange = exchangeDate.sort(
        (a, b) => new Date(b.createdDate) - new Date(a.createdDate),
      );
      this.setState({
        exchanges: dataExchange,
        loading: false,
      });
    });
    this.subscribe(promotionRedeemStore, REEDEM_TRANSACTION_ERROR_EVENT, (e) => {
      toast.error(e.message);
    });
    fetchExchangesTransaction();
  }

  render() {
    let { exchanges, loading } = this.state;
    return (
      <React.Fragment>
        <SidebarComponent>
          <Navbar nameView="Promotion Redeem" />
          {loading ? (
            <Loader />
          ) : (
            <MDBContainer fluid className="body">
              {/* <div className="text-right mb-4">
                <Link to="/new-promotion" className="btn btn-circle btn-circle-link mr-3">
                  Add Promotion
                </Link>
              </div> */}
              <TableRedemption exchanges={exchanges} />
            </MDBContainer>
          )}
        </SidebarComponent>
      </React.Fragment>
    );
  }
}

export default PromotionRedemptionScreen;
