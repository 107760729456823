import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MDBBtn, MDBModal, MDBModalBody, MDBRow, MDBCol } from 'mdbreact';

class ModalComponent extends Component {
  state = {
    modal: false,
    flagDisabled: false,
  };

  toggle = () => {
    if (this.props.flag) {
      if (this.props.displayFeed === true && this.props.picture !== null) {
        this.setState((prevState) => ({
          modal: !prevState.modal,
        }));
      }
      if (this.props.displayFeed === false) {
        this.setState((prevState) => ({
          modal: !prevState.modal,
        }));
      }
    }
    this.props.actionValidate();
  };

  confirmAction = () => {
    const isConfirmed = true;
    this.props.onSubmit(isConfirmed);
    this.setState({
      flagDisabled: true,
    });
  };

  render() {
    const { flagDisabled } = this.state;
    const submitBtn = (
      <MDBBtn className="btn btn-circle mt-4 mb-5" onClick={this.toggle}>
        {' '}
        Publish{' '}
      </MDBBtn>
    );

    return (
      <>
        {submitBtn}
        <MDBModal
          size="sm"
          centered
          className="modal-notify"
          isOpen={this.state.modal}
          toggle={this.toggle}>
          <MDBModalBody>Would You Like To Publish This Promotion?</MDBModalBody>
          <MDBRow middle="true">
            <MDBCol>
              <MDBBtn
                className="btn btn-circle-danger mt-4 mb-6"
                onClick={this.toggle}
                disabled={flagDisabled}>
                {' '}
                Cancel{' '}
              </MDBBtn>
              <MDBBtn
                className="btn btn-circle mt-4  mb-6"
                onClick={this.confirmAction}
                disabled={flagDisabled}>
                {' '}
                Publish{' '}
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBModal>
      </>
    );
  }
}

ModalComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  picture: PropTypes.string,
  displayFeed: PropTypes.bool.isRequired,
  flag: PropTypes.bool.isRequired,
  actionValidate: PropTypes.func.isRequired,
};

export default ModalComponent;
