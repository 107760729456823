import Flux from 'flux-state';

export const REMOVE_ACCOUNT_STRIPE = 'REMOVE_ACCOUNT_STRIPE';
export const ERROR_REMOVE_ACCOUNT_STRIPE = 'ERROR_REMOVE_ACCOUNT_STRIPE';
export const EDIT_PROFILE_EVENT = 'EDIT_PROFILE_EVENT';
export const EDIT_PROFILE_ERROR = 'EDIT_PROFILE_ERROR';
export const EDIT_ACCOUNT_STRIPE_EVENT = 'EDIT_ACCOUNT_STRIPE_EVENT';
export const EDIT_ACCOUNT_STRIPE_ERROR = 'EDIT_ACCOUNT_STRIPE_ERROR';
export const LOCATION_MAP_EVENT = 'LOCATION_MAP_EVENT';
export const FETCH_SALES_EVENT = 'FETCH_SALES_EVENT';
export const DISABLED_PAYMENT_METHOD_EVENT = 'DISABLED_PAYMENT_METHOD_EVENT';
export const DISABLED_PAYMENT_METHOD_ERROR = 'DISABLED_PAYMENT_METHOD_ERROR';

class ProfileStore extends Flux.DashStore {
  constructor(props) {
    super(props);
    this.addEvent(REMOVE_ACCOUNT_STRIPE);
    this.addEvent(ERROR_REMOVE_ACCOUNT_STRIPE);
    this.addEvent(EDIT_PROFILE_EVENT);
    this.addEvent(EDIT_PROFILE_ERROR);
    this.addEvent(EDIT_ACCOUNT_STRIPE_EVENT);
    this.addEvent(EDIT_ACCOUNT_STRIPE_ERROR);
    this.addEvent(LOCATION_MAP_EVENT);
    this.addEvent(FETCH_SALES_EVENT);
    this.addEvent(DISABLED_PAYMENT_METHOD_EVENT);
    this.addEvent(DISABLED_PAYMENT_METHOD_ERROR);
  }
}

const profileStore = new ProfileStore();

export { profileStore };
