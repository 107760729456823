export const calculatePrice = (price, discount) => {
  if (price !== '' && discount !== '') {
    price = price.replace(/[%$]/g, '');
    discount = discount.replace(/[%$]/g, '') / 100;
    let totalDiscount = price * discount;
    // return price - totalDiscount + '$';
    if (discount === 1) {
      return '0';
    }
    return (price - totalDiscount).toFixed(2);
  } else {
    return '0';
  }
};

export const roundWithPrecision = (commision) => {
  return Math.ceil(parseFloat(commision.toString().substr(0, 5)) * 100) / 100;
};

export const stripeFeeCalculator = (amount) => {
  var fee = amount * 0.029 + 0.3;

  return fee;
};

export const calculateVenueProfit = (salesPrice, okrooCommission) => {
  const okrooPercentage = okrooCommission > 0 ? okrooCommission - 2.9 : 0;
  const okrooCommissionAmount = salesPrice * (okrooPercentage / 100);
  const okrooCommissionAmountRounded = roundWithPrecision(okrooCommissionAmount);
  const stripeFeeAmount = stripeFeeCalculator(salesPrice);
  const venueProfit = salesPrice - (stripeFeeAmount + okrooCommissionAmountRounded);

  return venueProfit.toFixed(2);
};

export const orderWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
