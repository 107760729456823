import React from 'react';
import View from 'react-flux-state';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBMask, MDBView } from 'mdbreact';
import { toast } from 'react-toastify';
import { Icon } from 'react-icons-kit';
// import { ic_mail_outline } from 'react-icons-kit/md/ic_mail_outline';
import { ic_keyboard_arrow_right } from 'react-icons-kit/md/ic_keyboard_arrow_right';
import { ic_lock_outline } from 'react-icons-kit/md/ic_lock_outline';
import bgLanding from '../../../assets/img/background.png';
import { updatePassword, getUrlParam } from '../auth-actions';
import { Loader } from '../../../components/Loader';
import { authStore, PASSWORD_UPDATE_EVENT, PASSWORD_UPDATE_ERROR } from '../auth-store';

class ChangePasswordView extends View {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      firstInput: '',
      secondInput: '',
      loading: false,
      code: getUrlParam(this.props.location.search, 'oobCode'),
    };
  }

  componentDidMount() {
    this.subscribe(authStore, PASSWORD_UPDATE_EVENT, () => {
      this.setState({ loading: false });
      toast.success('You have set your password successfully');
      this.props.history.push('/');
    });
    this.subscribe(authStore, PASSWORD_UPDATE_ERROR, (err) => {
      toast.error(err.message);
      console.log('err', err);
      console.log('err.message', err.message);
      console.log('PASSWORD_UPDATE_ERROR dispach');

      this.setState({ loading: false });
    });
    // this.subscribe(authStore, PASSWORD_UPDATE_ERROR, this.onError);
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { firstInput, secondInput, code, email } = this.state;

    if (firstInput !== secondInput) {
      return toast.error('Passwords do not match');
    } else if (firstInput.length < 6) {
      return toast.error('The Password Must Contain at Least 6 Characters');
    } else {
      this.setState(
        {
          loading: true,
        },
        () => {
          let newPassword = firstInput;
          updatePassword(code, newPassword, email);
        },
      );
    }
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  backLogin = () => {
    this.props.history.push('/login');
  };

  onKeyPress = (e) => {
    if (e.which === 13) {
      this.onSubmit(e);
    }
  };

  render() {
    const { loading } = this.state;
    return (
      <MDBView src={bgLanding}>
        <MDBMask className="d-flex justify-content-center align-items-center">
          <MDBContainer>
            <MDBRow className="justify-content-center">
              <MDBCol md="4">
                <h4 className="text-primary text-center">Recover your password</h4>
                <MDBRow>
                  <MDBCol md="12" className="p-0">
                    <form className="p-3">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="basic-addon1">
                            <Icon icon={ic_lock_outline} />
                          </span>
                        </div>
                        <input
                          type="password"
                          name="firstInput"
                          disabled={loading}
                          onChange={this.onChange}
                          className="form-control"
                          placeholder="New password"
                          aria-label="Password"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="basic-addon1">
                            <Icon icon={ic_lock_outline} />
                          </span>
                        </div>
                        <input
                          type="password"
                          disabled={loading}
                          name="secondInput"
                          onChange={this.onChange}
                          className="form-control"
                          placeholder="Confirm password"
                          aria-label="Password"
                          aria-describedby="basic-addon1"
                          onKeyPress={this.onKeyPress}
                        />
                      </div>
                    </form>
                    {loading ? (
                      <Loader />
                    ) : (
                      <div
                        className="text-center"
                        style={{ display: 'flex', justifyContent: 'center' }}>
                        <MDBBtn className="btn-auth" onClick={this.onSubmit}>
                          Recover password
                          <Icon size={20} icon={ic_keyboard_arrow_right} />
                        </MDBBtn>
                      </div>
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBMask>
      </MDBView>
    );
  }
}

export { ChangePasswordView };
