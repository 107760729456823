import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { MDBRow, MDBCol, MDBTable, MDBTableBody, MDBTableHead, MDBBtn } from 'mdbreact';
import ImgEmpty from '../assets/img/empty.png';
import { orderWeek } from '../modules/admin/promotion/promotion-utils';
import RankModalComponent from './RankModalComponent';

class TablePromotionAdmin extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpenModal: false,
      promotionSelected: {},
    };
  }
  detailPromotion = (promotion) => {
    this.props.history.push(`/detail-promotion-admin/${promotion.promoID}`);
  };

  openModal = (promotion) => {
    this.setState({
      isOpenModal: true,
    });
    this.setState({
      promotionSelected: promotion,
    });
  };

  render() {
    const { data, changeStatus } = this.props;
    let daysValid = {};

    const TablePromotionAdmin = data.map((promotion) => {
      const promoValid = new Date(promotion.validDate.seconds * 1000) >= new Date();
      if (promotion.validDays.length === 0) {
        daysValid = 0;
      } else {
        const validDays = [];
        orderWeek.forEach((elem1) => {
          promotion.validDays.forEach((elem2) => {
            if (elem2 === elem1) {
              validDays.push(elem2);
            }
          });
        });
        daysValid = validDays.map((day) => {
          return (
            <>
              {day + ', '}
              <br />
            </>
          );
        });
      }
      return (
        <>
          <MDBTableBody>
            <tr>
              <td
                align="center"
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => this.openModal(promotion)}>
                {promotion.rank || 0}
              </td>
              <td className="cur" onClick={() => this.detailPromotion(promotion)}>
                {promotion.picture ? (
                  <img
                    src={promotion.picture}
                    alt="default"
                    className="img-promotion"
                    width="auto"
                    style={{ maxWidth: '80px', minWidth: '70px', height: '100px' }}
                  />
                ) : (
                  <img
                    src={ImgEmpty}
                    alt="default"
                    className="img-fluid"
                    width="auto"
                    style={{ maxWidth: '80px', minWidth: '70px', height: '100px' }}
                  />
                )}
              </td>
              <td>{moment.unix(promotion.createdDate.seconds, 'DD/MM/YYYY').format('LL')}</td>
              <td>{promotion.nameUser}</td>
              <td>{moment.unix(promotion.validDate.seconds, 'DD/MM/YYYY').format('LL')}</td>
              <td title={promotion.validDays}>{daysValid}</td>
              <td>{promotion.name}</td>
              <td>{promotion.displayFeed ? 'Yes' : 'No'}</td>
              <td className="p-0 text-right">
                {promotion.status === 'active' ? (
                  <MDBBtn
                    disabled={!promoValid}
                    style={{ padding: '5px 17px' }}
                    color={promoValid ? 'warning' : null}
                    className={`btn ${
                      !promoValid ? 'btn-disabled-large' : ''
                    } btn-circle btn-circle-link m-0 mb-2`}
                    onClick={() =>
                      promoValid ? changeStatus(promotion, promotion.promoID) : null
                    }>
                    Reject
                  </MDBBtn>
                ) : (
                  <MDBBtn
                    disabled={!promoValid}
                    style={{ padding: '5px 10px' }}
                    className={`btn ${
                      !promoValid ? 'btn-disabled-large' : ''
                    } btn-circle btn-circle-link m-0 mb-2`}
                    onClick={() =>
                      promoValid ? changeStatus(promotion, promotion.promoID) : null
                    }>
                    Accept
                  </MDBBtn>
                )}
              </td>
              <td className="p-0 text-right">
                <MDBBtn
                  color="primary"
                  style={{ padding: '5px 17px' }}
                  className="btn btn-circle btn-circle-link m-0 mb-2"
                  onClick={() => this.detailPromotion(promotion)}>
                  Detail
                </MDBBtn>
              </td>
            </tr>
          </MDBTableBody>
        </>
      );
    });

    return (
      <>
        <MDBRow>
          <MDBCol md="12" className="p-0">
            <div className="text-right mb-4">
              <Link to="/new-promotion" className="btn btn-circle btn-circle-link">
                Add Promotion
              </Link>
            </div>
            <MDBTable responsiveSm responsiveMd responsiveLg responsiveXl hover>
              <MDBTableHead>
                <tr>
                  <th>RANK ORDER</th>
                  <th />
                  <th>CREATED DATE</th>
                  <th>VENUE NAME</th>
                  <th>VALID UNTIL</th>
                  <th>VALID DAYS</th>
                  <th>PROMOTION NAME</th>
                  <th>DISPLAY</th>
                </tr>
              </MDBTableHead>
              {TablePromotionAdmin}
            </MDBTable>
          </MDBCol>
        </MDBRow>
        <RankModalComponent
          onSubmit={this.props.changeRank}
          open={this.state.isOpenModal}
          promotion={this.state.promotionSelected}
          closeModal={() =>
            this.setState({
              isOpenModal: false,
            })
          }
        />
      </>
    );
  }
}

TablePromotionAdmin.propTypes = {
  data: PropTypes.array.isRequired,
  changeStatus: PropTypes.func.isRequired,
  changeRank: PropTypes.func.isRequired,
  history: PropTypes.any,
};

export default TablePromotionAdmin;
