import React from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import LogoMarker from '../../../../assets/img/location-pin.svg';

const API_KEY = process.env.REACT_APP_API_KEY;

const Marker = () => <img src={LogoMarker} alt="Logo" height="30" width="30" />;

const MapContainer = ({ coordinates }) => {
  const latCoord = coordinates ? coordinates.lat : 59.95;
  const lngCoord = coordinates ? coordinates.lng : 30.33;
  return (
    <div style={{ height: '50vh', width: '100%', paddingBottom: 20 }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: API_KEY }}
        defaultCenter={{
          lat: 59.95,
          lng: 30.33,
        }}
        center={{
          lat: latCoord,
          lng: lngCoord,
        }}
        yesIWantToUseGoogleMapApiInternals
        defaultZoom={15}>
        {coordinates ? <Marker lat={latCoord} lng={lngCoord} /> : null}
      </GoogleMapReact>
    </div>
  );
};

MapContainer.propTypes = {
  coordinates: PropTypes.object,
};

export default MapContainer;
