import React from 'react';
import { Link } from 'react-router-dom';
import { MDBContainer, MDBAnimation } from 'mdbreact';
import SidebarComponent from '../../../components/SidebarComponent';
import TablePromotion from '../../../components/TablePromotion';
import View from 'react-flux-state';
import Navbar from '../../../components/NavBar';
import {
  promotionStore,
  PROMOTION_ERROR_EVENT,
  FETCH_PROMOTION_EVENT,
  ARCHIEVE_PROMOTION_EVENT,
  PAUSE_PROMOTION_EVENT,
  FETCH_USER_EVENT,
} from './promotion-store';
import { toast } from 'react-toastify';
import {
  fetchPromotions,
  pausePromo,
  archievePromo,
  archievePromotions,
  fetchUser,
} from './promotion-actions';
import { Loader } from '../../../components/Loader';

class PromotionView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      userAdmin: {},
      loading: true,
    };
  }
  componentDidMount() {
    this.subscribe(promotionStore, FETCH_PROMOTION_EVENT, (data) => {
      this.setState({
        data: data,
        loading: false,
      });
    });
    this.subscribe(promotionStore, PROMOTION_ERROR_EVENT, (e) => {
      toast.error(e.message);
    });
    this.subscribe(promotionStore, ARCHIEVE_PROMOTION_EVENT, () => {
      toast.success('Promotion Archieved. Promotion archieved cannot be recovered');
      this.setState({
        loading: false,
      });
      fetchPromotions();
    });
    this.subscribe(promotionStore, PAUSE_PROMOTION_EVENT, () => {
      toast.success('Operation Successfully Done');
      this.setState({
        loading: false,
      });
      fetchPromotions();
    });
    this.subscribe(promotionStore, FETCH_USER_EVENT, (user) => {
      this.setState({
        userAdmin: user,
      });
    });
    fetchUser();
    fetchPromotions();
  }

  pausedPromotion = (promo, id) => {
    const data = promo;

    if (data.statusUser === 'active') {
      data.statusUser = 'paused';
    } else if (data.statusUser === 'paused') {
      data.statusUser = 'active';
    }
    this.setState({
      loading: false,
    });
    pausePromo(data, id);
  };

  archievePromotion = (promo, id) => {
    const data = promo;

    if (data.statusUser === 'active' || data.statusUser === 'paused') {
      if (data.remainRedeem !== '0') {
        toast.error('Error, this promotion still has ' + data.remainRedeem + ' remain redemptions');
      } else if (data.status === 'pending') {
        toast.error('Error, Wait until the admin approve this promotion');
      } else {
        this.setState({
          loading: true,
        });
        data.status = 'archieved';
        archievePromo(id);
      }
    } else {
      toast.error('Error, Promotion already archieved');
    }
  };

  archievePromos = (confirm) => {
    if (confirm === true) {
      archievePromotions();
      this.setState({
        loading: true,
      });
      toast.success('Promotions Archived');
    }
  };

  render() {
    const { userAdmin } = this.state;
    return (
      <React.Fragment>
        <SidebarComponent>
          <MDBAnimation type="fadeIn">
            <Navbar nameView="Promotions" />
            <MDBContainer className="body">
              {this.state.loading === true ? (
                <Loader />
              ) : (
                <>
                  {userAdmin.admin ? (
                    <div className="text-right mb-4">
                      <Link to="/new-promotion" className="btn btn-circle btn-circle-link">
                        Add Promotion
                      </Link>
                    </div>
                  ) : (
                    <></>
                  )}
                  <TablePromotion
                    data={this.state.data}
                    user={userAdmin}
                    pausePromo={this.pausedPromotion}
                    archievePromo={this.archievePromotion}
                    archievePromotions={this.archievePromos}
                  />
                </>
              )}
            </MDBContainer>
          </MDBAnimation>
        </SidebarComponent>
      </React.Fragment>
    );
  }
}

export default PromotionView;
