import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import { stack } from 'react-icons-kit/icomoon/stack';
import { MDBRow, MDBCol, MDBTable, MDBTableBody, MDBTableHead, MDBBtn } from 'mdbreact';
import { Link } from 'react-router-dom';
import { ic_pause } from 'react-icons-kit/md/ic_pause';
import { ic_play_arrow } from 'react-icons-kit/md/ic_play_arrow';
// import { ic_content_copy } from 'react-icons-kit/md/ic_content_copy';
// import { ic_folder } from 'react-icons-kit/md/ic_folder';
// import ModalArchived from './ModalArchived';
import ImgEmpty from '../assets/img/empty.png';
import { orderWeek } from '../modules/admin/promotion/promotion-utils';

class TablePromotion extends React.Component {
  onConfirm = (confirm) => {
    if (confirm === true) {
      this.props.archievePromotions(confirm);
    }
  };
  render() {
    const { data, user } = this.props;
    const { visibleMethod } = user;
    let daysValid = {};
    const TablePromotion = data
      .filter((promotion) => promotion.user === user.email)
      .map((promotion) => {
        const promoValid = new Date(promotion.validDate.seconds * 1000) >= new Date();
        if (promotion.validDays.length === 0) {
          daysValid = 0;
        } else {
          const validDays = [];
          orderWeek.forEach((elem1, i) => {
            promotion.validDays.forEach((elem2, i) => {
              if (elem2 === elem1) {
                validDays.push(elem2);
              }
            });
          });
          daysValid = validDays.map((day) => {
            return (
              <>
                {day + ', '}
                <br />
              </>
            );
          });
        }

        return (
          <React.Fragment key={promotion.promoID}>
            <MDBTableBody>
              <tr>
                <td>
                  {promotion.picture ? (
                    <img
                      src={promotion.picture}
                      alt="default"
                      className="img-promotion"
                      width="auto"
                      style={{ maxWidth: '80px', minWidth: '70px', height: '100px' }}
                    />
                  ) : (
                    <img
                      src={ImgEmpty}
                      alt="default"
                      className="img-fluid"
                      width="auto"
                      style={{ maxWidth: '80px', minWidth: '70px', height: '100px' }}
                    />
                  )}
                </td>
                <td>{moment.unix(promotion.createdDate.seconds, 'DD/MM/YYYY').format('LL')}</td>
                <td>{promotion.nameUser}</td>
                <td>{moment.unix(promotion.validDate.seconds, 'DD/MM/YYYY').format('LL')}</td>
                <td title={promotion.validDays}>{daysValid}</td>
                <td>{promotion.name}</td>

                <td className="p-0 text-right">
                  <Link
                    to={`/detail-promotion/${promotion.promoID}`}
                    color="default"
                    className="btn btn-detail"
                    title="Promotion Details">
                    <Icon icon={stack} />
                  </Link>

                  {promoValid ? (
                    promotion.status === 'active' && promotion.statusUser === 'active' ? (
                      <MDBBtn
                        color="info"
                        className="btn btn-pause m-0"
                        onClick={() => this.props.pausePromo(promotion, promotion.promoID)}>
                        <Icon icon={ic_pause} title="Pause Promotion" />
                      </MDBBtn>
                    ) : promotion.status === 'active' && promotion.statusUser === 'paused' ? (
                      <MDBBtn
                        color="info"
                        className="btn btn-pause m-0"
                        onClick={() => this.props.pausePromo(promotion, promotion.promoID)}
                        disabled={!visibleMethod}>
                        <Icon icon={ic_play_arrow} title="Activate Promotion" />
                      </MDBBtn>
                    ) : promotion.status === 'pending' && promotion.statusUser === 'active' ? (
                      <MDBBtn
                        disabled
                        color="info"
                        className="btn btn-pause m-0"
                        onClick={() => this.props.pausePromo(promotion, promotion.promoID)}>
                        <Icon icon={ic_pause} title="Pause Promotion" />
                      </MDBBtn>
                    ) : (
                      <MDBBtn
                        disabled
                        color="info"
                        className="btn btn-pause m-0"
                        onClick={() => this.props.pausePromo(promotion, promotion.promoID)}>
                        <Icon icon={ic_play_arrow} title="Activate Promotion" />
                      </MDBBtn>
                    )
                  ) : (
                    <MDBBtn disabled className="btn btn-disabled m-0">
                      <Icon icon={ic_pause} title="Pause Promotion" />
                    </MDBBtn>
                  )}
                  {/* <Link
                    to={`/copy-promotion/${promotion.promoID}`}
                    className="btn btn-copy"
                    title="Copy Promotion">
                    <Icon icon={ic_content_copy} />
                  </Link> */}

                  {/* <MDBBtn
                    color="alert"
                    className="btn btn-erase m-0"
                    onClick={() => this.props.archievePromo(promotion, promotion.promoID)}
                  >
                    <Icon icon={ic_folder} title="Archive Promotion" />
                  </MDBBtn> */}
                </td>
              </tr>
            </MDBTableBody>
          </React.Fragment>
        );
      });

    return (
      <MDBRow>
        <MDBCol md="12" className="p-0">
          <MDBTable responsiveSm responsiveMd responsiveLg responsiveXl hover>
            <MDBTableHead>
              <tr>
                <th />
                <th>CREATE DATE</th>
                <th>VENUE NAME</th>
                <th>VALID UNTIL</th>
                <th>VALID DAYS</th>
                <th>PROMOTION NAME</th>
                {/* <th>Total sales</th>
                <th>Remaining redemption</th> */}
                {/* <th className="text-right">
                  <ModalArchived
                    onConfirm={this.onConfirm}
                    title="Promotions will be archived. Archived promotions cannot be recovered"
                  />
                </th> */}
                <th style={{ paddingRight: '170px' }}></th>
              </tr>
            </MDBTableHead>
            {TablePromotion}
          </MDBTable>
        </MDBCol>
      </MDBRow>
    );
  }
}

TablePromotion.propTypes = {
  data: PropTypes.array.isRequired,
  pausePromo: PropTypes.func.isRequired,
  archievePromo: PropTypes.func.isRequired,
  archievePromotions: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default TablePromotion;
