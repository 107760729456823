import React from 'react';
import { MDBRow, MDBCol, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import moment from 'moment';
import PropTypes from 'prop-types';

class TableRedemption extends React.Component {
  render() {
    const { exchanges } = this.props;
    const listExchanges = [];
    exchanges.forEach((exchange) => {
      exchange.promos.forEach((promo) => {
        const objExchange = {
          id: promo.idPayment,
          createdDate: exchange.createdDate,
          order: exchange.number,
          qty: promo.purchasedQuantity,
          user: exchange.user,
          price: promo.salesPrice,
          name: promo.name,
          tip: promo.tip || '-',
        };
        listExchanges.push(objExchange);
      });
    });
    const TableRedemptions = listExchanges.map((exchange, index) => {
      return (
        <MDBTableBody key={exchange.id}>
          <tr key={index}>
            <td>{exchange.name}</td>
            <td>{exchange.user}</td>
            <td>{moment(exchange.createdDate).format('MMM DD, YYYY hh:mm A')}</td>
            <td>{exchange.order}</td>
            <td>${exchange.price}</td>
            <td>{exchange.qty}</td>
            <td>{exchange.tip}</td>
          </tr>
        </MDBTableBody>
      );
    });
    return (
      <MDBRow>
        <MDBCol md="12" className="p-0">
          <MDBTable hover borderless responsive className="text-center">
            <MDBTableHead>
              <tr>
                <th>NAME</th>
                <th>USER</th>
                <th>DATE PURCHASED</th>
                <th>#ORDER</th>
                <th>PRICE</th>
                <th>QTY</th>
                <th>TIP</th>
              </tr>
            </MDBTableHead>
            {TableRedemptions}
          </MDBTable>
        </MDBCol>
      </MDBRow>
    );
  }
}

TableRedemption.propTypes = {
  exchanges: PropTypes.array.isRequired,
};

export default TableRedemption;
