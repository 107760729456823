import firebase from 'firebase';
import Flux from 'flux-state';
import moment from 'moment';
import {
  PROMOTION_EVENT,
  PROMOTION_ERROR_EVENT,
  FETCH_PROMOTION_EVENT,
  PROMOTION_DETAIL_EVENT,
  PAUSE_PROMOTION_EVENT,
  ARCHIEVE_PROMOTION_EVENT,
  FETCH_USER_EVENT,
  FETCH_ALL_USER_EVENT,
  CHANGE_PROMOTION_EVENT,
} from './promotion-store';
import { authStore, USER_EVENT } from '../../auth/auth-store';

/**
 *
 * @return {Promise<>} Returns all users
 *
 */

export const fetchAllUsers = async () => {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users')
    .where('privilege', '==', 'merchant')
    .where('admin', '==', false);
  usersCollection.onSnapshot((querySnapshot) => {
    const usersEmail = [];
    querySnapshot.forEach((user) => {
      const objUser = user.data();
      if (objUser.location && objUser.stripeAccount && objUser.visibleMethod) {
        const obj = {};
        obj.name = objUser.name;
        obj.email = objUser.email;
        usersEmail.push(obj);
      }
    });
    Flux.dispatchEvent(FETCH_ALL_USER_EVENT, usersEmail);
  });
};

/**
 *
 * @return {Promise<>} The current user returns
 *
 */

export const fetchUser = async () => {
  const userData = authStore.getState(USER_EVENT);
  setTimeout(() => {
    Flux.dispatchEvent(FETCH_USER_EVENT, userData);
  }, 0);
};

/**
 *
 * @param image The image of promotions
 * @param promo The objet promotion
 * @return {Promise<>}
 *
 */

export const createPromotion = async (promo) => {
  const DB = firebase.firestore();
  const promotionCollection = DB.collection('promotions');
  let imageUrl = null;
  if (promo.image === undefined) {
    imageUrl = promo.picture;
  } else {
    imageUrl = promo.image;
  }

  const storage = firebase.storage();
  let location = '';
  let locationName = '';
  let merchantName = '';

  const usersCollection = DB.collection('users').doc(promo.user);
  let usersRef;
  try {
    usersRef = await usersCollection.get();
  } catch (err) {
    Flux.dispatchEvent(PROMOTION_ERROR_EVENT, err);
  }

  const userData = usersRef.data();

  location = userData.location;
  locationName = userData.locationName;
  merchantName = userData.name;

  if (imageUrl) {
    const ext = imageUrl.name.split('.').pop();
    const name = moment().valueOf() + '.' + ext.toLowerCase();
    const storageRef = storage.ref(`/promotionsImages/${name}`);
    const task = await storageRef.put(imageUrl);
    imageUrl = await task.ref.getDownloadURL();
  }

  const {
    displayFeed,
    isArchieved,
    status,
    statusUser,
    name,
    originalPrice,
    discount,
    salesPrice,
    description,
    totalSales,
    totalRedeem,
    remainRedeem,
    createdDate,
    validDate,
    validDays,
    okrooCommission,
    venueProfit,
    user,
  } = promo;

  let { tags } = promo;

  tags = '#' + tags;
  tags = tags.split('####').join(' ');
  tags = tags.split('###').join(' ');
  tags = tags.split('##').join(' ');
  tags = tags.split('#').join(' ');
  tags = tags.split('    ').join('#');
  tags = tags.split('   ').join('#');
  tags = tags.split('  ').join('#');
  tags = tags.split(' ').join('#');
  const statusDate = '';

  await promotionCollection
    .add({
      picture: imageUrl,
      displayFeed,
      isArchieved,
      status,
      statusDate,
      statusUser,
      name,
      originalPrice,
      discount,
      okrooCommission,
      venueProfit,
      salesPrice,
      description,
      totalSales,
      totalRedeem,
      remainRedeem,
      createdDate,
      validDate,
      validDays,
      tags,
      location,
      locationName,
      user,
      merchantName,
      rank: 0,
    })
    .then((doc) => {
      console.log('Document writen with ID: ', doc.id);
      Flux.dispatchEvent(PROMOTION_EVENT, doc);
    })
    .catch((e) => {
      console.log('Error Adding document: ', e);
      Flux.dispatchEvent(PROMOTION_ERROR_EVENT, e);
    });
};
/**
 *
 * @return {Promise<>}
 *
 */

export const fetchPromotions = () => {
  const DB = firebase.firestore();
  const promotionsCollection = DB.collection('promotions');
  let data = [];

  promotionsCollection
    .orderBy('createdDate', 'desc')
    .get()
    .then((promo) => {
      promo.forEach((doc) => {
        const {
          picture,
          displayFeed,
          status,
          statusDate,
          statusUser,
          name,
          originalPrice,
          discount,
          salesPrice,
          description,
          createdDate,
          validDate,
          validDays,
          totalSales,
          totalRedeem,
          remainRedeem,
          tags,
          user,
        } = doc.data();

        data.push({
          picture,
          displayFeed,
          status,
          statusDate,
          statusUser,
          name,
          originalPrice,
          discount,
          salesPrice,
          description,
          createdDate,
          validDate,
          validDays,
          totalSales,
          totalRedeem,
          remainRedeem,
          tags,
          user,
          promoID: doc.id,
        });
      });
      // console.log(data);
      fetchPromotionsEnds(data);
      // Flux.dispatchEvent(FETCH_PROMOTION_EVENT, data);
    })
    .catch((e) => {
      console.log('Error getting documents', e);
      Flux.dispatchEvent(PROMOTION_ERROR_EVENT, e);
    });
};

export const fetchPromotionsEnds = async (data) => {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users');

  let usersData = [];
  let users = [];
  try {
    usersData = await usersCollection.get();
  } catch (err) {
    Flux.dispatchEvent(PROMOTION_ERROR_EVENT, err);
  }

  usersData.forEach((element) => {
    users.push(element.data());
  });

  data.forEach((doc) => {
    if (users.find((element) => element.email === doc.user)) {
      const user = users.find((element) => element.email === doc.user);
      doc.nameUser = user.name;
    }
  });

  Flux.dispatchEvent(FETCH_PROMOTION_EVENT, data);
};

/**
 *
 * @return {Promise<>}
 *
 */

export const fetchDetailPromo = (id) => {
  const DB = firebase.firestore();
  const promoDoc = DB.collection('promotions').doc(id);

  let data = [];
  promoDoc.get().then((doc) => {
    if (doc.exists) {
      data = doc.data();
      data.id = doc.id;
      Flux.dispatchEvent(PROMOTION_DETAIL_EVENT, data);
    } else {
      console.log('No such Document');
      Flux.dispatchEvent(PROMOTION_ERROR_EVENT, data);
    }
  });
};

export const pausePromo = (data, id) => {
  const DB = firebase.firestore();
  const promoDoc = DB.collection('promotions').doc(id);

  const { statusUser } = data;

  promoDoc
    .update({
      statusUser: statusUser,
    })
    .then((doc) => {
      Flux.dispatchEvent(PAUSE_PROMOTION_EVENT, doc);
    })
    .catch((e) => {
      console.log(e);
      Flux.dispatchEvent(PROMOTION_ERROR_EVENT, e);
    });
};

export const activePromoDetail = (data, id) => {
  const DB = firebase.firestore();
  const promotionsCollection = DB.collection('promotions').doc(id);

  const { status } = data;

  promotionsCollection
    .update({
      status: status,
    })
    .then((doc) => {
      Flux.dispatchEvent(CHANGE_PROMOTION_EVENT, doc);
    })
    .catch((e) => {
      console.log(e);
      Flux.dispatchEvent(PROMOTION_ERROR_EVENT, e);
    });
  // fetchPromotions();
};

export const archievePromo = (id) => {
  const DB = firebase.firestore();
  const promoDoc = DB.collection('promotions').doc(id);

  promoDoc
    .update({
      isArchieved: true,
    })
    .then((doc) => {
      Flux.dispatchEvent(ARCHIEVE_PROMOTION_EVENT, doc);
    })
    .catch((e) => {
      console.log(e);
      Flux.dispatchEvent(PROMOTION_ERROR_EVENT, e);
    });
};

/**
 *
 * @return {Promise<>}
 *
 */

export const archievePromotions = () => {
  const DB = firebase.firestore();
  const promotionsCollection = DB.collection('promotions');
  const userData = authStore.getState(USER_EVENT);
  const batch = DB.batch();
  let data = [];
  promotionsCollection
    .where('user', '==', userData.email)
    .where('isArchieved', '==', false)
    .where('remainRedeem', '==', '0')
    .get()
    .then((promo) => {
      promo.forEach((doc) => {
        data.push(doc.id);
      });
      data.forEach((doc) => {
        console.log(doc);
        let promoRef = promotionsCollection.doc(doc);
        batch.update(promoRef, { isArchieved: true });
      });
      batch
        .commit()
        .then((doc) => {
          Flux.dispatchEvent(ARCHIEVE_PROMOTION_EVENT, doc);
          fetchPromotions();
          return doc;
        })
        .catch((e) => {
          console.log(e);
          Flux.dispatchEvent(PROMOTION_ERROR_EVENT, e);
          throw e;
        });
    });
};
