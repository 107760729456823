import React from 'react';
import { MDBContainer, MDBAnimation } from 'mdbreact';
// import SidebarComponent from '../../../components/SidebarComponent';
import PrivacyPolicy from '../../../components/PrivacyPolicyComponent';
import Navbar from '../../../components/NavBar';
// import logo from '../../../assets/img/logoTerms.png';

class PrivacyAndPolicyView extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <SidebarComponent> */}
        <Navbar nameView="Privacy Policy" />
        <MDBAnimation type="fadeIn">
          <MDBContainer fluid className="body">
            {/* <div className="d-flex justify-content-center p-4">
              <img src={logo} alt="Okroo" className="img-fluid" width="250" />
            </div> */}
          </MDBContainer>
          <PrivacyPolicy />
        </MDBAnimation>
        {/* </SidebarComponent> */}
      </React.Fragment>
    );
  }
}

export default PrivacyAndPolicyView;
