import React from 'react';
import View from 'react-flux-state';
import { Link } from 'react-router-dom';
import { error } from 'pure-logger';
import { toast } from 'react-toastify';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBBtnGroup } from 'mdbreact';
import { Icon } from 'react-icons-kit';
import { ic_mail_outline } from 'react-icons-kit/md/ic_mail_outline';
import { ic_lock_outline } from 'react-icons-kit/md/ic_lock_outline';
import { ic_keyboard_arrow_right } from 'react-icons-kit/md/ic_keyboard_arrow_right';
import { ic_keyboard_arrow_left } from 'react-icons-kit/md/ic_keyboard_arrow_left';
import {
  authStore,
  LOGIN_EVENT,
  LOGIN_ERROR_EVENT,
  REQUEST_PASSWORD_RESET,
  USER_ERROR_EVENT,
} from '../auth-store';
import { onLogin } from '../auth-actions';
import { Loader } from '../../../components/Loader';

class FormLogin extends View {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      loading: false,
      forgot: true,
      rememberMe: false,
    };
    this.onError = error.bind(this);
  }

  componentDidMount() {
    this.subscribe(authStore, LOGIN_EVENT, (user) => {
      const msjEdit = !user.admin && (!user.stripeAccount || !user.location);
      toast.info(`Welcome: ${user.name} ${msjEdit ? 'complete all your information.' : ''}`);
      if (user.admin) {
        return this.props.history.push('/promotion-administrator');
      }
      if (user.location && user.stripeAccount) {
        return this.props.history.push('/promotion');
      }
      return this.props.history.push('/edit-profile');
    });
    this.subscribe(authStore, LOGIN_ERROR_EVENT, (err) => {
      // toast.error(err.message);
      this.setState({ loading: false });
    });
    this.subscribe(authStore, REQUEST_PASSWORD_RESET, () => {
      toast.info('Request for Recover Password Processed');
      this.setState({ loading: false }, () => {
        this.props.history.push('/login');
      });
    });
    this.subscribe(authStore, USER_ERROR_EVENT, (err) => {
      toast.error(err.message);
      this.setState({ loading: false });
    });
  }

  onSubmit = (e) => {
    const { email, password, rememberMe } = this.state;
    e.preventDefault();
    this.setState({ loading: true }, () => {
      onLogin({ email, password, rememberMe });
    });
  };

  onChange = ({ target: { name, value } }) => {
    if (name === 'rememberMe') {
      this.setState((prevState) => ({
        rememberMe: !prevState.rememberMe,
      }));
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  backHome = () => {
    this.props.history.push('/');
  };

  onKeyPress = (e) => {
    if (e.which === 13) {
      this.onSubmit(e);
    }
  };

  render() {
    let { loading, rememberMe } = this.state;
    return (
      <MDBContainer>
        <MDBRow>
          <MDBCol md="12" className="p-0">
            <div className="p-3">
              {/* <MDBRow>
                <MDBCol md="5" xs="5">
                  <hr className="hr-dark" />
                </MDBCol>
                <MDBCol md="2" xs="2" className="text-center">
                  OR
                </MDBCol>
                <MDBCol md="5" xs="5">
                  <hr className="hr-dark" />
                </MDBCol>
              </MDBRow> */}
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <Icon icon={ic_mail_outline} />
                  </span>
                </div>
                <input
                  type="email"
                  name="email"
                  onChange={this.onChange}
                  className="form-control"
                  placeholder="Email"
                  aria-label="Email"
                  aria-describedby="basic-addon1"
                />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <Icon icon={ic_lock_outline} />
                  </span>
                </div>
                <input
                  type="password"
                  name="password"
                  onChange={this.onChange}
                  className="form-control"
                  placeholder="Password"
                  aria-label="Password"
                  aria-describedby="basic-addon1"
                  onKeyPress={this.onKeyPress}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <input
                  type="checkbox"
                  name="rememberMe"
                  value={rememberMe}
                  style={{ marginRight: '5px' }}
                  onChange={this.onChange}
                />
                <h6 style={{ margin: '0px' }}>Keep Session Active?</h6>
              </div>
              <div className="text-center">
                <Link to="recovery-password">{`Don't remember your password?`}</Link>
              </div>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <div className="text-center">
                <MDBBtnGroup size="sm" className="mb-4 btn-auth" style={{ padding: '0px' }}>
                  <MDBBtn className="btn-circle" onClick={this.backHome}>
                    <Icon size={20} icon={ic_keyboard_arrow_left} />
                    Home
                  </MDBBtn>
                  <MDBBtn className="btn-circle" onClick={this.onSubmit}>
                    Login
                    <Icon size={20} icon={ic_keyboard_arrow_right} />
                  </MDBBtn>
                </MDBBtnGroup>
              </div>
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default FormLogin;
