import React from 'react';
import View from 'react-flux-state';
import { error } from 'pure-logger';
import { toast } from 'react-toastify';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBBtnGroup } from 'mdbreact';
import { Icon } from 'react-icons-kit';
import { hashtag } from 'react-icons-kit/fa/hashtag';
import { ic_mail_outline } from 'react-icons-kit/md/ic_mail_outline';
import { ic_lock_outline } from 'react-icons-kit/md/ic_lock_outline';
import { ic_keyboard_arrow_right } from 'react-icons-kit/md/ic_keyboard_arrow_right';
import { ic_keyboard_arrow_left } from 'react-icons-kit/md/ic_keyboard_arrow_left';
import {
  authStore,
  SIGNUP_EVENT,
  LOGIN_ERROR_EVENT,
  USER_ERROR_EVENT,
  ERROR_ACCESS_CODE_EVENT,
} from '../auth-store';
import { fetchValidateAccess } from '../auth-actions';
import { Loader } from '../../../components/Loader';

class FormSignUp extends View {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      checkTerms: false,
      loading: false,
      accessCode: '',
    };
    this.onError = error.bind(this);
  }

  componentDidMount() {
    this.subscribe(authStore, SIGNUP_EVENT, (user) => {
      this.setState({ loading: false });
      const msjEdit = !user.admin && (!user.stripeAccount || !user.location);
      toast.info(`Welcome: ${user.name} ${msjEdit ? 'complete all your information.' : ''}`);
      if (user.location && user.stripeAccount) {
        return this.props.history.push('/promotion-redeemption');
      }
      return this.props.history.push('/edit-profile');
    });
    this.subscribe(authStore, LOGIN_ERROR_EVENT, (err) => {
      toast.error(err.message);
      this.setState({ loading: false });
    });
    this.subscribe(authStore, USER_ERROR_EVENT, (err) => {
      toast.error(err.message);
    });
    this.subscribe(authStore, ERROR_ACCESS_CODE_EVENT, (err) => {
      this.setState({ loading: false });
      toast.error(err.message);
    });
  }

  onSubmit = (e) => {
    const { email, password, checkTerms, accessCode } = this.state;
    e.preventDefault();
    if (checkTerms) {
      this.setState({ loading: true }, () => {
        fetchValidateAccess(accessCode, { email, password });
      });
    } else {
      toast.error('You must first accept the terms and conditions and privacy policy');
    }
  };

  onChange = ({ target: { name, value } }) => {
    if (name === 'checkTerms') {
      this.setState((prevState) => ({
        checkTerms: !prevState.checkTerms,
      }));
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  backHome = () => {
    this.props.history.push('/');
  };

  onKeyPress = (e) => {
    if (e.which === 13) {
      this.onSubmit(e);
    }
  };

  render() {
    let { checkTerms, loading } = this.state;
    return (
      <MDBContainer>
        <MDBRow>
          <MDBCol md="12" xs="12" className="p-0">
            <form className="p-2">
              {/* <MDBRow>
                <MDBCol md="5" xs="5">
                  <hr className="hr-dark" />
                </MDBCol>
                <MDBCol md="2" xs="2" className="text-center">
                  OR
                </MDBCol>
                <MDBCol md="5" xs="5">
                  <hr className="hr-dark" />
                </MDBCol>
              </MDBRow> */}

              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <Icon icon={ic_mail_outline} />
                  </span>
                </div>
                <input
                  type="email"
                  name="email"
                  onChange={this.onChange}
                  className="form-control"
                  placeholder="Email"
                  aria-label="Email"
                  aria-describedby="basic-addon1"
                />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <Icon icon={ic_lock_outline} />
                  </span>
                </div>
                <input
                  type="password"
                  name="password"
                  onChange={this.onChange}
                  className="form-control"
                  placeholder="Password"
                  aria-label="Password"
                  aria-describedby="basic-addon1"
                  onKeyPress={this.onKeyPress}
                />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <Icon icon={hashtag} />
                  </span>
                </div>
                <input
                  name="accessCode"
                  onChange={this.onChange}
                  className="form-control"
                  placeholder="Access code"
                  aria-label="Access code"
                  maxLength={4}
                  aria-describedby="basic-addon1"
                  onKeyPress={this.onKeyPress}
                />
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <input
                  type="checkbox"
                  name="checkTerms"
                  value={checkTerms}
                  style={{ marginRight: '5px' }}
                  onChange={this.onChange}
                />
                <h6 style={{ marginTop: '10px', textAlign: 'center' }}>
                  You agree to our{' '}
                  <a
                    href="/terms-and-conditions"
                    style={{
                      textDecoration: 'underline',
                    }}
                    target="_blank">
                    terms and conditions
                  </a>
                  ,{' '}
                  <a
                    href="/privacy-policy"
                    style={{
                      textDecoration: 'underline',
                    }}
                    target="_blank">
                    privacy policy
                  </a>{' '}
                  and{' '}
                  <a
                    href="/terms-merchant"
                    style={{
                      textDecoration: 'underline',
                    }}
                    target="_blank">
                    merchant agreements
                  </a>
                </h6>
              </div>
            </form>
            {loading ? (
              <Loader />
            ) : (
              <div className="text-center">
                {/* <MDBBtn className="btn-auth btn-circle" onClick={this.backHome}>
                  <Icon size={20} icon={ic_keyboard_arrow_left} />
                  Home
                </MDBBtn>
                <MDBBtn className="btn-auth btn-circle" onClick={this.onSubmit}>
                  Sign Up <Icon size={24} icon={ic_keyboard_arrow_right} />
                </MDBBtn> */}
                <MDBBtnGroup size="sm" className="mb-4 btn-auth" style={{ padding: '0px' }}>
                  <MDBBtn className="btn-circle" onClick={this.backHome}>
                    <Icon size={20} icon={ic_keyboard_arrow_left} />
                    Home
                  </MDBBtn>
                  <MDBBtn className="btn-circle" onClick={this.onSubmit}>
                    Sign Up
                    <Icon size={20} icon={ic_keyboard_arrow_right} />
                  </MDBBtn>
                </MDBBtnGroup>
              </div>
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}
export default FormSignUp;
