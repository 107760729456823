import React from 'react';
import PropTypes from 'prop-types';
import { MDBContainer, MDBView, MDBMask, MDBRow, MDBCol } from 'mdbreact';
// import classnames from 'classnames';
import { TabContent, TabPane } from 'reactstrap';
import bgLanding from '../../assets/img/background.png';
import logoOkrooF from '../../assets/img/logoOkrooF.png';
// import FormLogin from './components/FormLogin';
// import FormSignUpAdmin from './components/FormSignUpAdmin';
import FormSignUp from './components/FormSignUp';

class SignUpView extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '2',
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <MDBView src={bgLanding}>
        <MDBMask className="d-flex justify-content-center align-items-center">
          <MDBContainer>
            <MDBRow className="justify-content-center">
              <MDBCol md="4">
                <div className="d-flex justify-content-center p-4">
                  <img src={logoOkrooF} width="190" alt="Bazl" className="img-fluid" />
                </div>
                <h4 className="text-primary text-center">Register Business</h4>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">{/* <FormLogin history={this.props.history} /> */}</TabPane>
                  <TabPane tabId="2">
                    <FormSignUp history={this.props.history} />
                  </TabPane>
                </TabContent>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBMask>
      </MDBView>
    );
  }
}

SignUpView.propTypes = {
  history: PropTypes.any,
};

export default SignUpView;
