import firebase from 'firebase';
import Flux from 'flux-state';
import { log } from 'pure-logger';
import * as R from 'ramda';
import {
  USER_EVENT,
  USER_ERROR_EVENT,
  LOGIN_EVENT,
  LOGIN_ERROR_EVENT,
  SIGNUP_EVENT,
  REQUEST_PASSWORD_RESET,
  LOGOUT_EVENT,
  PASSWORD_UPDATE_EVENT,
  PASSWORD_UPDATE_ERROR,
  CREATE_EVENT,
  ERROR_ACCESS_CODE_EVENT,
} from './auth-store';
import { UserModel } from './auth-models';

/**
 * @param {string} email
 * @param {string} password
 * @returns {Promise<{user: UserModel} || void>}
 **/
export const onLogin = async ({ email, password, rememberMe }) => {
  const persistence = rememberMe
    ? firebase.auth.Auth.Persistence.LOCAL
    : firebase.auth.Auth.Persistence.SESSION;
  await firebase.auth().setPersistence(persistence);
  const AUTH = firebase.auth();
  let data;
  try {
    data = await AUTH.signInWithEmailAndPassword(email, password);
  } catch (err) {
    return Flux.dispatchEvent(LOGIN_ERROR_EVENT, new Error(err.message));
  }
  const { user: firebaseUser } = data;
  let user = await fetchUser(firebaseUser.email);
  if (user.privilege === 'userApp') {
    return Flux.dispatchEvent(LOGIN_ERROR_EVENT, new Error('Business account not found'));
  }
  log('onLogin:fetchUser');
  Flux.dispatchEvent(LOGIN_EVENT, user);
};

/**
 * @param {string} email
 * @param {string} password
 * @returns {Promise<user: UserModel>}
 **/
export const onSignup = async ({ email, password }) => {
  const AUTH = firebase.auth();
  let data;
  try {
    data = await AUTH.createUserWithEmailAndPassword(email, password);
    const { user: firebaseUser } = data;
    let user = await createUser(firebaseUser);
    user = await fetchUser(firebaseUser.email);

    return Flux.dispatchEvent(LOGIN_EVENT, user);
  } catch (err) {
    log('Error en onSignUp', err);
    return Flux.dispatchEvent(LOGIN_ERROR_EVENT, new Error(err.message));
  }
};

/**
 * @param {string} email
 * @param {string} password
 * @returns {Promise<user: UserModel>}
 **/
export const onSignUpAdmin = async ({ email, password }) => {
  const AUTH = firebase.auth();
  let data;
  try {
    data = await AUTH.createUserWithEmailAndPassword(email, password);
    const { user: firebaseUser } = data;
    let user = await createUser(firebaseUser);

    return Flux.dispatchEvent(CREATE_EVENT, user);
  } catch (err) {
    log('Error en onSignUp', err);
    return Flux.dispatchEvent(LOGIN_ERROR_EVENT, new Error(err.message));
  }
};

/**
 * fetchValidateAccess - action to validate the step to register a user
 *
 * @param {string} code - accessCode to allow signUp user
 * @param {object} data - data, email and password
 */

export const fetchValidateAccess = async (code, data) => {
  const DB = firebase.firestore();
  try {
    const getCode = await DB.collection('settings')
      .where('accessCode', '==', code)
      .get();
    if (getCode.empty) {
      return Flux.dispatchEvent(ERROR_ACCESS_CODE_EVENT, { message: 'Access code is invalid' });
    }
    return onSignup(data);
  } catch (err) {
    console.log('Error get code access', err);
    Flux.dispatchEvent(ERROR_ACCESS_CODE_EVENT, { message: 'Error when consulting access code' });
  }
};

/**
 * fetches the user state necessary for the LOGIN_EVENT
 * @param {string} email
 * @returns {Promise<{user}>}
 **/
export const fetchUser = async (email) => {
  const DB = firebase.firestore();
  const usersCollection = await DB.collection('users');
  const userRef = await usersCollection.doc(email);
  let query;
  try {
    query = await userRef.get({ source: 'server' });
  } catch (e) {
    Flux.dispatchEvent(USER_ERROR_EVENT, new Error(e.message));
    throw e;
  }

  if (!query.exists) return null;
  const user = query.data();

  Flux.dispatchEvent(USER_EVENT, user);
  return user;
};

/**Logs the user out of the system *
 * @return {Promise<void>}
 **/
export const onLogout = async () => {
  const AUTH = firebase.auth();
  await AUTH.signOut();
  window.location = '/login';
  Flux.dispatchEvent(LOGOUT_EVENT, {});
};

/**
 * Creates a new user in the system
 * @param {string} firebaseUser the firebase uid
 * @return {Promise<UserModel>} user credentials or null if unexisting
 **/
export const createUser = async (firebaseUser) => {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users');

  const user = R.clone(UserModel);
  user.email = firebaseUser.email;
  user.id = firebaseUser.uid;
  user.privilege = 'merchant';
  user.name = user.email.split('@')[0];

  const userRef = await usersCollection.doc(firebaseUser.email);
  try {
    await userRef.set(user, { merge: true });
  } catch (err) {
    Flux.dispatchEvent(USER_ERROR_EVENT, new Error(err.message));
    throw err;
  }
  Flux.dispatchEvent(SIGNUP_EVENT, user);
  return user;
};

/**
 * function that dispatches an email to the user and triggers the REQUEST_RECOVER_PASSwORD
 * @param {string} email
 * @returns {Promise<{void}>}
 **/
export const requestPasswordReset = async (email) => {
  const AUTH = firebase.auth();
  AUTH.sendPasswordResetEmail(email)
    .then((send) => {
      console.log('send 1', send);
      Flux.dispatchEvent(REQUEST_PASSWORD_RESET, send);
    })
    .catch((err) => Flux.dispatchEvent(USER_ERROR_EVENT, new Error(err.message)));
};

/**
 * @param {string} code The confirmation code send via email to the user
 * @param {string} password The new password.
 * @param {string} email the email to search the collection and update the property
 * @return {Promise<void>}
 *
 */
export const updatePassword = async (code, password, email) => {
  log(`updatePassword:`, code, password, email);
  const AUTH = await firebase.auth();
  try {
    await AUTH.confirmPasswordReset(code, password);
  } catch (err) {
    console.log('PASSWORD_UPDATE_ERROR confirmPasswordReset');
    return Flux.dispatchEvent(PASSWORD_UPDATE_ERROR, err);
  }

  const updatePassword = firebase.functions().httpsCallable('updatePassword');
  try {
    await updatePassword({ email });
  } catch (err) {
    console.log('PASSWORD_UPDATE_ERROR updatePassword err', err);
  }
  console.log('PASSWORD_UPDATE_EVENT');
  Flux.dispatchEvent(PASSWORD_UPDATE_EVENT);
};

/**
 * function that dispatches an email to the user and triggers the REQUEST_RECOVER_PASSwORD
 * @param {string} url
 * @param {string} param
 * @returns {Promise<{void}>}
 **/
export const getUrlParam = (url, param) => {
  const params = [];
  const urlParams = new URLSearchParams(url);
  for (const index of urlParams.entries()) {
    params.push(index);
  }
  const findParamIndex = params.findIndex((value) => param === value[0]);
  return findParamIndex === -1 ? '' : params[findParamIndex][1];
};
