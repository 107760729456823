import React from 'react';
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBInput,
  MDBBtn,
  MDBNavLink,
  MDBNavItem,
  MDBNav,
  MDBTabPane,
  MDBTabContent,
  MDBCard,
  MDBAlert,
  MDBCardBody,
} from 'mdbreact';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import Icon from 'react-icons-kit';
import InputMask from 'react-input-mask';
import { Loader } from '../../../components/Loader';
import creditCardType from 'credit-card-type';
import VisaLogo from '../../../assets/img/Visa_logo.svg';
import ExpressLogo from '../../../assets/img/american-express.svg';
import MasterLogo from '../../../assets/img/Mastercard-logo.svg';
import DinersLogo from '../../../assets/img/diners-club.svg';
import DiscoverLogo from '../../../assets/img/Discover.svg';
import CardEmpty from '../../../assets/img/cardEmpty.svg';
import CardEmptyWhite from '../../../assets/img/cardEmpty-white.svg';
import { ic_delete } from 'react-icons-kit/md/ic_delete';
import { ic_edit } from 'react-icons-kit/md/ic_edit';
import DatePicker from 'react-date-picker';
import SidebarComponent from '../../../components/SidebarComponent';
import BankLogo from '../../../assets/img/bank.svg';
import Navbar from '../../../components/NavBar';
import View from 'react-flux-state';
import {
  disabledPaymentMethod,
  addPaymentMethod,
  // deletePaymentMethod,
  createAccountStripe,
  updateAccountStripe,
} from './profile-actions';
import {
  profileStore,
  EDIT_ACCOUNT_STRIPE_EVENT,
  EDIT_ACCOUNT_STRIPE_ERROR,
  EDIT_PROFILE_EVENT,
  EDIT_PROFILE_ERROR,
  REMOVE_ACCOUNT_STRIPE,
  ERROR_REMOVE_ACCOUNT_STRIPE,
  DISABLED_PAYMENT_METHOD_EVENT,
  DISABLED_PAYMENT_METHOD_ERROR,
  // LOCATION_MAP_EVENT
} from './profile-store';
import { authStore, USER_EVENT } from '../../auth/auth-store';
import { toast } from 'react-toastify';

const stylePhone = {
  width: '100%',
  borderRadius: 0,
  borderWidth: 0,
  borderBottomColor: '#D7D4D4',
  backgroundColor: 'transparent',
  borderBottomWidth: 1,
};

const styleBoxDebit = {
  width: '45%',
};

const codeCountry = {
  marginTop: 2,
  paddingRight: 5,
  color: 'black',
};

class BankAccountView extends View {
  constructor(props) {
    super(props);
    this.state = {
      user: authStore.getState(USER_EVENT),
      loading: false,
      errors: {},
      accountNumber: '',
      routingNumber: '',
      ssnLast: '',
      activeItem: '1',
      cardNumber: '',
      expDate: '',
      cvv: '',
      editAccount: false,
    };
  }

  componentDidMount() {
    this.subscribe(profileStore, EDIT_PROFILE_EVENT, (state) => {
      this.setState({
        loading: false,
        editAccount: false,
        errors: {},
      });
      toast.success(state.message);
    });

    this.subscribe(profileStore, EDIT_ACCOUNT_STRIPE_EVENT, () => {
      this.setState({
        loading: false,
        editAccount: false,
        errors: {},
      });
      setTimeout(() => {
        toast.success('Successfully edited account');
      }, 300);
    });

    this.subscribe(profileStore, EDIT_ACCOUNT_STRIPE_ERROR, (e) => {
      this.setState({
        loading: false,
        editAccount: false,
      });
      setTimeout(() => {
        toast.error(e.message);
      }, 300);
    });

    this.subscribe(profileStore, DISABLED_PAYMENT_METHOD_EVENT, (state) => {
      this.setState(
        {
          loading: false,
        },
        () => {
          setTimeout(() => {
            toast.success(state.message);
          }, 300);
        },
      );
    });

    this.subscribe(profileStore, DISABLED_PAYMENT_METHOD_ERROR, (state) => {
      this.setState(
        {
          loading: false,
        },
        () => {
          setTimeout(() => {
            toast.error(state.message);
          }, 300);
        },
      );
    });

    this.subscribe(authStore, USER_EVENT, (data) => {
      this.setState({
        user: data,
      });
    });

    this.subscribe(profileStore, EDIT_PROFILE_ERROR, (e) => {
      this.setState({
        loading: false,
      });
      setTimeout(() => {
        toast.error(e.message);
      }, 300);
    });

    this.subscribe(profileStore, REMOVE_ACCOUNT_STRIPE, (data) => {
      this.setState({
        loading: false,
      });
      setTimeout(() => {
        toast.success(data.message);
      }, 300);
    });

    this.subscribe(profileStore, ERROR_REMOVE_ACCOUNT_STRIPE, (e) => {
      this.setState({
        loading: false,
      });
      setTimeout(() => {
        toast.error(e.message);
      }, 300);
    });

    if (!this.state.user.dateBirth) {
      const userCopy = { ...this.state.user };
      userCopy.dateBirth = moment()
        .subtract(18, 'years')
        .format('MM/DD/YYYY');
      setTimeout(() => {
        this.setState({
          user: userCopy,
        });
      }, 300);
    }
  }

  toggle = (tab) => (e) => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
        errors: {},
      });
    }
  };

  getTypeCard = () => {
    const typeCard = creditCardType(this.state.cardNumber);
    if (!this.state.cardNumber || typeCard.length === 0) {
      return <img src={CardEmpty} alt="empty" height="45" width="45" />;
    }
    if (typeCard && typeCard[0].type === 'visa') {
      return <img src={VisaLogo} alt="Visa" height="45" width="45" />;
    }
    if (typeCard && typeCard[0].type === 'mastercard') {
      return <img src={MasterLogo} alt="Mastercard" height="45" width="45" />;
    }
    if (typeCard && typeCard[0].type === 'american-express') {
      return <img src={ExpressLogo} alt="american" height="45" width="45" />;
    }
    if (typeCard && typeCard[0].type === 'diners-club') {
      return <img src={DinersLogo} alt="diners" height="45" width="45" />;
    }
    if (typeCard && typeCard[0].type === 'discover') {
      return <img src={DiscoverLogo} alt="Discover" height="45" width="45" />;
    }
    return <img src={CardEmpty} alt="empty" height="45" width="45" />;
  };

  onChange = (e, bankData) => {
    const data = { ...this.state.user };
    if (bankData) {
      return this.setState({
        [e.target.name]: e.target.value,
      });
    }
    data[e.target.name] = e.target.value;
    return this.setState({
      user: data,
    });
  };

  deleteAccount = () => {
    this.setState(
      {
        loading: true,
      },
      () => {
        disabledPaymentMethod({ ...this.state.user }, this.state.activeItem === '1' ? true : false);
      },
    );
  };

  onClick = (onlyPaymentMethod = false) => {
    const { user, cardNumber, expDate, cvv, accountNumber, routingNumber, activeItem } = this.state;
    const dataUser = { ...user };
    dataUser.phone = dataUser.phone.replace(/\D/g, '');
    const infoAdd = {
      phoneNumber: dataUser.phone,
    };
    const infoBank = {
      accountNumber,
      routingNumber,
    };
    const infoCard = {
      cardNumber,
      expDate,
      cvv,
    };
    if (activeItem === '2') {
      if (!Object.keys(this.validate({ ...dataUser, ...infoBank, ...infoAdd })).length) {
        this.setState({
          loading: true,
        });
        if (onlyPaymentMethod) {
          return addPaymentMethod(dataUser, { ...infoBank, ...infoAdd });
        }
        return createAccountStripe(dataUser, { ...infoBank, ...infoAdd });
      }
      return this.setState({ errors: this.validate({ ...dataUser, ...infoBank, ...infoAdd }) });
    }
    if (!Object.keys(this.validate({ ...dataUser, ...infoCard, ...infoAdd }, true)).length) {
      this.setState({
        loading: true,
      });
      if (onlyPaymentMethod) {
        return addPaymentMethod(dataUser, { ...infoCard, ...infoAdd }, true);
      }
      return createAccountStripe(dataUser, { ...infoCard, ...infoAdd }, true);
    }
    return this.setState({ errors: this.validate({ ...dataUser, ...infoCard, ...infoAdd }, true) });
  };

  onEditAccount = () => {
    const dataUser = { ...this.state.user };
    dataUser.phone = dataUser.phone.replace(/\D/g, '');
    if (!Object.keys(this.validateEdit({ ...dataUser, phoneNumber: dataUser.phone })).length) {
      this.setState({
        loading: true,
      });
      return updateAccountStripe(dataUser);
    }
    return this.setState({
      errors: this.validateEdit({ ...dataUser, phoneNumber: dataUser.phone }),
    });
  };

  onDateChange = (date) => {
    const dataUser = { ...this.state.user };
    dataUser.dateBirth = moment(date).format('MM/DD/YYYY');
    this.setState({
      user: dataUser,
    });
  };

  validateEdit = (values) => {
    const errors = {};
    if (!values.nameInfo) {
      errors.nameInfo = 'First name is required';
    }
    if (!values.lastNameInfo) {
      errors.lastNameInfo = 'Last name is required';
    }

    if (!values.phoneNumber || values.phoneNumber.length !== 10) {
      errors.phoneNumber = 'The phone number must contain 10 digits';
    }

    if (!values.address) {
      errors.address = 'Address is required';
    }
    return errors;
  };

  validate = (values, card = false) => {
    const currentYear = parseInt(moment().format('YY'));
    const typeCard = creditCardType(this.state.cardNumber);
    const amexCard = typeCard.length > 0 && typeCard[0].type === 'american-express';
    const errors = {};
    if (!values.nameInfo) {
      errors.nameInfo = 'First name is required';
    }
    if (!values.lastNameInfo) {
      errors.lastNameInfo = 'Last name is required';
    }

    if (!values.phoneNumber || values.phoneNumber.length !== 10) {
      errors.phoneNumber = 'The phone number must contain 10 digits';
    }

    if (!values.address) {
      errors.address = 'Address is required';
    }
    if (!card) {
      if (values.routingNumber.length !== 9) {
        errors.routingNumber = 'Routing number must contain 9 digits';
      }
      if (values.accountNumber.length < 10) {
        errors.accountNumber = 'Account number must contain 10 or 12 digits';
      }
    } else {
      if (!values.cardNumber) {
        errors.cardNumber = 'Card number is required';
      }
      if (!values.expDate) {
        errors.expDate = 'Expiration date is required';
      }
      if (
        (values.expDate && values.expDate.length !== 5) ||
        (parseInt(values.expDate.split('/'[0])) > 12 ||
          parseInt(values.expDate.split('/')[1]) < currentYear)
      ) {
        errors.expDate = 'Expiration date is not valid';
      }
      if (!amexCard && values.cvv.length !== 3) {
        errors.cvv = 'CVV must contain 3 digits';
      }
      if (amexCard && values.cvv.length !== 4) {
        errors.cvv = 'CVV must contain 4 digits';
      }
    }

    return errors;
  };

  render() {
    const { errors, cardNumber } = this.state;
    const typeCard = creditCardType(cardNumber);
    const rgNumber = /^[0-9\b]+$/;
    const amexCard = typeCard.length > 0 && typeCard[0].type === 'american-express';
    const maskCardNumber =
      typeCard.length === 0
        ? '9999-9999-9999-9999'
        : typeCard[0].type === 'american-express'
          ? '9999-999999-99999'
          : typeCard[0].type === 'diners-club'
            ? '9999-999999-9999'
            : '9999-9999-9999-9999';
    return (
      <React.Fragment>
        <SidebarComponent>
          <Navbar nameView="Account information" />
          {this.state.loading ? (
            <Loader />
          ) : (
            <MDBContainer className="body">
              <MDBRow className="d-flex justify-content-center">
                <MDBCol md="9">
                  <MDBRow>
                    <MDBCol md="9" style={{ paddingRight: '0px', paddingLeft: '0px' }}>
                      <div>
                        {!this.state.user.stripeAccount ? (
                          <h3 className="font-weight-bold d-inline-block mb-4">
                            Required information
                          </h3>
                        ) : null}
                        {!this.state.user.stripeAccount && (
                          <MDBBtn
                            className="d-inline-block float-right m-0 btn btn-circle btn-circle-link"
                            onClick={() => this.onClick(false)}>
                            Save
                          </MDBBtn>
                        )}
                      </div>
                      {this.state.user.stripeAccount && !this.state.editAccount ? (
                        <>
                          <MDBCard className="mb-4">
                            <div className="m-3">
                              <div className="d-flex justify-content-end">
                                <MDBBtn
                                  onClick={() =>
                                    this.setState({
                                      editAccount: true,
                                    })
                                  }
                                  color="primary"
                                  className="btn btn-edit-round btn-circle"
                                  rounded>
                                  <Icon icon={ic_edit} title="EDit" />
                                </MDBBtn>
                              </div>
                              <MDBInput
                                disabled
                                label="First name"
                                name="nameInfo"
                                value={this.state.user.nameInfo}
                                className="mt-0"
                                onChange={this.onChange}
                              />
                              {errors.nameInfo && <p className="is-red-valid">{errors.nameInfo}</p>}
                              <MDBInput
                                disabled
                                label="Last name"
                                name="lastNameInfo"
                                value={this.state.user.lastNameInfo}
                                className="mt-0"
                                onChange={this.onChange}
                              />
                              {errors.lastNameInfo && (
                                <p className="is-red-valid">{errors.lastNameInfo}</p>
                              )}
                              <MDBInput
                                disabled
                                label="Address"
                                // name="lastNameInfo"
                                value={this.state.user.address}
                                className="mt-0"
                                onChange={this.onChange}
                              />
                              {errors.address && <p className="is-red-valid">{errors.address}</p>}
                              <MDBInput
                                disabled
                                label="Date of birth"
                                // name="lastNameInfo"
                                value={this.state.user.dateBirth}
                                className="mt-0"
                                onChange={this.onChange}
                              />
                              <div
                                style={{
                                  marginTop: -6,
                                }}>
                                <label
                                  // className="active disabled"
                                  style={{ fontSize: 14 }}>
                                  Phone number
                                </label>
                                <NumberFormat
                                  disabled
                                  style={stylePhone}
                                  className="mb-3"
                                  value={this.state.user.phone}
                                  format="+1 (###) ###-####"
                                />
                                {errors.phoneNumber && (
                                  <p className="is-red-valid">{errors.phoneNumber}</p>
                                )}
                              </div>
                            </div>
                          </MDBCard>
                        </>
                      ) : (
                        <>
                          <div>
                            <MDBInput
                              label="First name"
                              name="nameInfo"
                              value={this.state.user.nameInfo}
                              className="mt-0"
                              onChange={this.onChange}
                            />
                            {errors.nameInfo && <p className="is-red-valid">{errors.nameInfo}</p>}
                            <MDBInput
                              label="Last name"
                              name="lastNameInfo"
                              value={this.state.user.lastNameInfo}
                              className="mt-0"
                              onChange={this.onChange}
                            />
                            {errors.lastNameInfo && (
                              <p className="is-red-valid">{errors.lastNameInfo}</p>
                            )}
                          </div>
                          <label
                            className="active disabled"
                            style={{ fontWeight: 'bold', fontSize: 14 }}>
                            Phone number
                          </label>
                          <div className="d-flex">
                            <p style={codeCountry}>+1</p>
                            <NumberFormat
                              style={stylePhone}
                              className="mb-3"
                              name="phone"
                              placeholder="(000) 000-0000"
                              onChange={this.onChange}
                              value={this.state.user.phone}
                              format="(###) ###-####"
                            />
                          </div>
                          {errors.phoneNumber && (
                            <p className="is-red-valid">{errors.phoneNumber}</p>
                          )}
                          <div className="mb-4">
                            <div>
                              <label
                                className="active disabled"
                                style={{ fontWeight: 'bold', fontSize: 14 }}>
                                Date of birth
                              </label>
                            </div>
                            <DatePicker
                              clearIcon={null}
                              maxDate={new Date(moment().subtract(18, 'years'))}
                              value={
                                this.state.user.dateBirth && new Date(this.state.user.dateBirth)
                              }
                              onChange={this.onDateChange}
                              minDate={new Date(moment().subtract(80, 'years'))}
                              onKeyDown={(e) => e.preventDefault()}
                            />
                          </div>
                          <MDBInput
                            label="Address"
                            maxLength={100}
                            name="address"
                            value={this.state.user.address}
                            className="mt-0"
                            onChange={this.onChange}
                          />
                          {errors.address && <p className="is-red-valid">{errors.address}</p>}
                          {this.state.editAccount ? (
                            <div className="mb-5">
                              <MDBBtn
                                className="d-inline-block float-left m-0 btn btn-circle btn-circle-cancel"
                                onClick={() =>
                                  this.setState({
                                    editAccount: false,
                                  })
                                }>
                                Cancel
                              </MDBBtn>
                              <MDBBtn
                                className="d-inline-block float-right m-0 btn btn-circle btn-circle-link"
                                onClick={this.onEditAccount}>
                                Save
                              </MDBBtn>
                              <br />
                            </div>
                          ) : null}
                        </>
                      )}
                      {this.state.user.methodId &&
                      this.state.user.visibleMethod &&
                      this.state.user.stripeAccount ? (
                        <>
                          <MDBCard color="mdb-color lighten-2" text="white" className="text-center">
                            <MDBCardBody
                              style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                              }}>
                              <img
                                src={this.state.user.bankName ? BankLogo : CardEmptyWhite}
                                alt="Logo"
                                height="70"
                                width="70"
                              />
                              <h5 className="mt-2 d-inline-block">
                                {this.state.user.bankName
                                  ? `Bank: ${this.state.user.bankName}`
                                  : `Debit card: ${this.state.user.brand} **** ${this.state.user.last4Debit}`}
                              </h5>
                              <MDBBtn
                                onClick={() => this.deleteAccount()}
                                color="primary"
                                className="btn btn-delete-round btn-circle"
                                rounded>
                                <Icon icon={ic_delete} title="Delete" />
                              </MDBBtn>
                            </MDBCardBody>
                          </MDBCard>
                          <br />
                          <MDBAlert color="primary">
                            If you want to add another <b className="alert-link">bank account</b> or{' '}
                            <b className="alert-link">debit card</b>, you must delete the current
                            one
                          </MDBAlert>
                        </>
                        ) : (
                          <div>
                            <MDBNav className="nav-tabs mt-5">
                              <h3 className="font-weight-bold d-inline-block mb-4">
                              Add a debit card or bank account
                              </h3>
                              <MDBNavItem>
                                <MDBNavLink
                                  className="font-weight-bold"
                                  to="#"
                                  active={this.state.activeItem === '1'}
                                  onClick={this.toggle('1')}
                                  role="tab">
                                Debit Card
                                </MDBNavLink>
                              </MDBNavItem>
                              <MDBNavItem>
                                <MDBNavLink
                                  className="font-weight-bold"
                                  to="#"
                                  active={this.state.activeItem === '2'}
                                  onClick={this.toggle('2')}
                                  role="tab">
                                Bank Account
                                </MDBNavLink>
                              </MDBNavItem>
                            </MDBNav>
                            <MDBTabContent activeItem={this.state.activeItem}>
                              <MDBTabPane tabId="1" role="tabpanel">
                                <div className="mt-4">
                                  <div className="mb-3">{this.getTypeCard()}</div>
                                  <div className="mb-3">
                                    <label className="label-black">Card number</label>
                                    <InputMask
                                      className="form-control"
                                      placeholder="4242-4242-4242-4242"
                                      maskPlaceholder=""
                                      mask={maskCardNumber}
                                      onChange={(e) =>
                                        this.setState({
                                          cardNumber: e.target.value,
                                        })
                                      }
                                      value={this.state.cardNumber}
                                    />
                                    {errors.cardNumber && (
                                      <p className="is-red">{errors.cardNumber}</p>
                                    )}
                                  </div>

                                  <div className="d-flex justify-content-between mb-3">
                                    <div style={styleBoxDebit}>
                                      <label className="label-black">Expiration Date</label>
                                      <InputMask
                                        className="form-control"
                                        placeholder="03/24"
                                        maskPlaceholder=""
                                        mask="99/99"
                                        onChange={(e) =>
                                          this.setState({
                                            expDate: e.target.value,
                                          })
                                        }
                                        value={this.state.expDate}
                                      />
                                      {errors.expDate && <p className="is-red">{errors.expDate}</p>}
                                    </div>
                                    <div style={styleBoxDebit}>
                                      <label className="label-black">CVV</label>
                                      <InputMask
                                        className="form-control"
                                        placeholder={amexCard ? '1234' : '123'}
                                        maskPlaceholder=""
                                        mask={amexCard ? '9999' : '999'}
                                        name="cvv"
                                        onChange={(e) =>
                                          this.setState({
                                            cvv: e.target.value,
                                          })
                                        }
                                        value={this.state.cvv}
                                      />
                                      {errors.cvv && <p className="is-red">{errors.cvv}</p>}
                                    </div>
                                  </div>
                                </div>
                              </MDBTabPane>
                              <MDBTabPane tabId="2" role="tabpanel">
                                <div className="mt-4">
                                  <div className="mb-3">
                                    <label className="label-black">Routing number</label>
                                    <input
                                      value={this.state.routingNumber}
                                      maxLength={9}
                                      name="routingNumber"
                                      placeholder="110000000"
                                      className="form-control"
                                      onChange={(e) => {
                                        if (e.target.value && !rgNumber.test(e.target.value)) {
                                          return;
                                        }
                                        this.onChange(e, true);
                                      }}
                                    />
                                    {errors.routingNumber && (
                                      <p className="is-red">{errors.routingNumber}</p>
                                    )}
                                  </div>
                                  <div className="mb-3">
                                    <label className="label-black">Account number</label>
                                    <input
                                      value={this.state.accountNumber}
                                      maxLength={12}
                                      name="accountNumber"
                                      placeholder="000123456789"
                                      className="form-control"
                                      onChange={(e) => {
                                        if (e.target.value && !rgNumber.test(e.target.value)) {
                                          return;
                                        }
                                        this.onChange(e, true);
                                      }}
                                    />
                                    {errors.accountNumber && (
                                      <p className="is-red">{errors.accountNumber}</p>
                                    )}
                                  </div>
                                </div>
                              </MDBTabPane>
                            </MDBTabContent>
                            {this.state.user.stripeAccount ? (
                              <div className="mb-4">
                                <MDBBtn
                                  className="d-inline-block float-right m-0 btn btn-circle btn-circle-link"
                                  onClick={() => this.onClick(true)}>
                                Add
                                </MDBBtn>
                              </div>
                            ) : null}
                            <br />
                            <br />
                          </div>
                        )}
                    </MDBCol>
                  </MDBRow>
                  <p className="mt-4">
                    All customer transactions are subject to a 2.9% + $0.30 processing fee.
                    <br />
                    For merchant payouts, additional identity details may be required in order to
                    process <br /> the payout.
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          )}
        </SidebarComponent>
      </React.Fragment>
    );
  }
}

export default BankAccountView;
