import React from 'react';
import { View } from '@cobuildlab/react-simple-state';
import { toast } from 'react-toastify';
import { MDBContainer } from 'mdbreact';
import {
  ON_ORDER_REQUEST,
  ON_ORDER_REQUEST_ERROR,
} from './transaction-history-event';
import moment from 'moment';
import {
  // fetchPromotions,
  fetchOrders,
} from './transaction-history-action';
import SidebarComponent from '../../../components/SidebarComponent';
import TableRedemption from './components/table-redemption';
import Navbar from '../../../components/NavBar';
import { Loader } from '../../../components/Loader';

class TransactionHistoryView extends View {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.subscribe(ON_ORDER_REQUEST, (data) => {
      const OrderDate = data.map((res) => {
        res.createdDate = moment(res.createdDate.seconds * 1000).format('MM/DD/YYYY, h:mm:ss a');
        return res;
      });
      const dataOrder = OrderDate.sort(
        (a, b) => new Date(b.createdDate) - new Date(a.createdDate),
      );
      this.setState({
        orders: dataOrder,
        loading: false,
      });
    });
    this.subscribe(ON_ORDER_REQUEST_ERROR, (e) => {
      toast.error(e.message);
    });
    fetchOrders();
  }

  render() {
    let { orders, loading } = this.state;
    return (
      <React.Fragment>
        <SidebarComponent>
          <Navbar nameView="Transaction History" />
          {loading ? (
            <Loader />
          ) : (
            <MDBContainer fluid className="body">
              <TableRedemption exchanges={orders} />
            </MDBContainer>
          )}
        </SidebarComponent>
      </React.Fragment>
    );
  }
}

export default TransactionHistoryView;
