import React from 'react';
import View from 'react-flux-state';
import { MDBContainer, MDBAnimation } from 'mdbreact';
import SidebarComponent from '../../../components/SidebarComponent';
import TablePromotionAdmin from '../../../components/TablePromotionAdmin';
import Navbar from '../../../components/NavBar';
import {
  adminStore,
  PROMOTION_ERROR_EVENT,
  FETCH_PROMOTION_ADMIN_EVENT,
  CHANGE_PROMOTION_EVENT,
  CHANGE_RANK_PROMOTION_EVENT,
} from './admin-store';
import { toast } from 'react-toastify';
import { fetchPromotions, activePromo, changePromoRank } from './admin-actions';
import { Loader } from '../../../components/Loader';

class AdminView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.subscribe(adminStore, FETCH_PROMOTION_ADMIN_EVENT, (data) => {
      this.setState({
        data: data,
        loading: false,
      });
    });
    this.subscribe(adminStore, PROMOTION_ERROR_EVENT, (e) => {
      toast.error(e.message);
      this.setState({ loading: false });
    });
    this.subscribe(adminStore, CHANGE_PROMOTION_EVENT, () => {
      this.setState({ loading: false });
      toast.info('Promotion Change Succesful');
    });
    this.subscribe(adminStore, CHANGE_RANK_PROMOTION_EVENT, () => {
      this.setState({ loading: false });
      fetchPromotions();
      toast.info('Promotion Change Rank Succesful');
    });
    fetchPromotions();
  }

  changeStatus = (promo, id) => {
    this.setState({ loading: true });
    if (promo.status === 'active') {
      promo.status = 'pending';
    } else {
      if (promo.status === 'pending') {
        promo.status = 'active';
      }
    }

    activePromo(promo, id);
  };

  changeRank = (rankValue, promoId) => {
    this.setState({ loading: true });
    changePromoRank(rankValue, promoId);
  };

  render() {
    return (
      <React.Fragment>
        <SidebarComponent>
          <MDBAnimation type="fadeIn">
            <Navbar nameView="Promotion Admin" />
            <MDBContainer className="body">
              {this.state.loading === true ? (
                <Loader />
              ) : (
                <>
                  <TablePromotionAdmin
                    data={this.state.data}
                    changeStatus={this.changeStatus}
                    changeRank={this.changeRank}
                    history={this.props.history}
                  />
                </>
              )}
            </MDBContainer>
          </MDBAnimation>
        </SidebarComponent>
      </React.Fragment>
    );
  }
}

export default AdminView;
