import React from 'react';
import PropTypes from 'prop-types';
import { MDBContainer, MDBView, MDBMask, MDBRow, MDBCol } from 'mdbreact';
// import classnames from 'classnames';
import { TabContent, TabPane } from 'reactstrap';
import bgLanding from '../../assets/img/background.png';
import logoOkrooF from '../../assets/img/logoOkrooF.png';
import FormLogin from './components/FormLogin';
import FormSignUp from './components/FormSignUp';

class AuthView extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  render() {
    return (
      <MDBView src={bgLanding}>
        <MDBMask className="d-flex justify-content-center align-items-center">
          <MDBContainer>
            <MDBRow className="justify-content-center">
              <MDBCol md="4">
                <div className="d-flex justify-content-center p-4">
                  <img src={logoOkrooF} alt="Bazl" width="190" className="img-fluid" />
                </div>
                <h4 className="text-primary text-center">Please login to your account.</h4>
                {/* <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === '1',
                      })}
                      onClick={() => {
                        this.toggle('1');
                      }}>
                      Login
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === '2',
                      })}
                      // onClick={() => {
                      //   this.toggle('2');
                      // }}
                    >
                      <></>
                    </NavLink>
                  </NavItem>
                </Nav> */}
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <FormLogin history={this.props.history} />
                  </TabPane>
                  <TabPane tabId="2">
                    <FormSignUp history={this.props.history} />
                  </TabPane>
                </TabContent>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBMask>
      </MDBView>
    );
  }
}

AuthView.propTypes = {
  history: PropTypes.any,
};

export default AuthView;
