import React from 'react';
import PropTypes from 'prop-types';
import { MDBListGroup, MDBListGroupItem, MDBContainer } from 'mdbreact';
import { Link } from 'react-router-dom';
import { onLogout } from '../modules/auth/auth-actions';
import Logo from '../assets/img/logoOkroo.png';

const ContentSidebar = ({ routes }) => {
  function isCurrentRoute(pathname) {
    return pathname === window.location.pathname;
  }

  return (
    <MDBContainer className="p-0">
      <div className="pl-3 pb-4 pt-3">
        <img src={Logo} alt="Logo" width="170" />
      </div>
      <MDBListGroup>
        {routes.map(({ path, name, className }) => (
          <Link to={path} key={path}>
            <MDBListGroupItem href={null} active={isCurrentRoute(path)}>
              <span className={className}>{name}</span>
            </MDBListGroupItem>
          </Link>
        ))}
      </MDBListGroup>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          bottom: '15px',
          left: '25px',
        }}>
        <span onClick={onLogout} className="link-logout">
          Log Out
        </span>
        <Link
          target="_blank"
          to="/terms-merchant"
          className="link-terms"
          style={{ fontSize: '10px', textDecoration: 'underline' }}>
          Merchant Agreements
        </Link>
        {/* <Link
          to="/terms-merchant"
          className="link-terms"
          style={{ fontSize: '10px', textDecoration: 'underline' }}>
          Terms and Conditions Okroo merchant
        </Link> */}
      </div>
    </MDBContainer>
  );
};

ContentSidebar.propTypes = {
  routes: PropTypes.any,
};

export default ContentSidebar;
