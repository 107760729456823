import React from 'react';
import View from 'react-flux-state';
import { Link } from 'react-router-dom';
import { FormGroup, Input } from 'reactstrap';
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBInput,
} from 'mdbreact';
import { authStore, USER_EVENT } from '../../auth/auth-store';
import { fetchTotalSales } from './profile-actions';
import { profileStore, FETCH_SALES_EVENT } from './profile-store';
import SidebarComponent from '../../../components/SidebarComponent';
import Navbar from '../../../components/NavBar';
import MapContainer from './components/MapContainer';

class ProfileView extends View {
  constructor(props) {
    super(props);
    this.state = {
      user: authStore.getState(USER_EVENT),
      totalOrders: '0',
      loading: true,
    };
  }

  componentDidMount() {
    this.subscribe(profileStore, FETCH_SALES_EVENT, (data) => {
      this.setState({
        totalOrders: data,
        loading: false,
      });
    });
    fetchTotalSales();
  }

  render() {
    const { location } = this.state.user;
    const targetMap = {
      lat: location ? location.latitude : null,
      lng: location ? location.longitude : null,
    };
    return (
      <React.Fragment>
        <SidebarComponent>
          <Navbar nameView="Profile" />
          <MDBContainer className="body">
            <MDBRow className="d-flex justify-content-center">
              <MDBCol md="9">
                <MDBRow>
                  <MDBCol md="9" style={{ paddingRight: '0px', paddingLeft: '0px' }}>
                    <div>
                      <h3 className="font-weight-bold d-inline-block mb-4">
                        {this.state.user.name}
                      </h3>
                      <Link
                        to="/edit-profile"
                        className=" d-inline-block float-right m-0 btn btn-circle btn-circle-link">
                        Edit
                      </Link>
                    </div>
                    <div>
                      <MDBInput
                        disabled
                        label="Email"
                        value={this.state.user.email}
                        className="mt-0"
                      />
                      <MDBInput
                        disabled
                        label="Location"
                        value={this.state.user.locationName}
                        className="mt-0"
                      />
                    </div>
                    <h6 className="font-weight-bold">Description</h6>
                    <FormGroup>
                      <Input
                        disabled
                        type="textarea"
                        name="text"
                        value={this.state.user.description}
                        id="exampleText"
                      />
                    </FormGroup>
                    {location ? (
                      <div
                        style={{
                          width: '100%',
                          boxShadow: '0px 2px 10px 1px #00000040',
                        }}>
                        <MapContainer coordinates={location ? targetMap : null} />
                      </div>
                    ) : null}
                  </MDBCol>
                  <MDBCol md="3">
                    <MDBCard className="marginTop">
                      <MDBCardBody className="p-2">
                        <MDBCardText className="text-center">Total Orders</MDBCardText>
                        <MDBCardTitle className="text-center">
                          {this.state.totalOrders}
                        </MDBCardTitle>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </SidebarComponent>
      </React.Fragment>
    );
  }
}

export default ProfileView;
