import React from 'react';
import LinkUser from './LinkUser';
import Logo from '../assets/img/logoOkroo.png';
import PropTypes from 'prop-types';

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ruta: '',
    };
  }

  render() {
    const { nameView, termsPlace } = this.props;

    return (
      <React.Fragment>
        <div className="d-none d-md-block">
          <div className="d-flex justify-content-between nav-admin body">
            <div>
              <h2 className="m-0">{nameView}</h2>
            </div>
            <div className="mt-2">
              <LinkUser termsPlace={termsPlace} />
            </div>
          </div>
        </div>
        <div className="d-block d-md-none nav-admin-mobile">
          <img src={Logo} alt="Okroo" width="180" />
        </div>
      </React.Fragment>
    );
  }
}

LinkUser.defaultProps = {
  termsPlace: null,
};

Navbar.propTypes = {
  nameView: PropTypes.string.isRequired,
  termsPlace: PropTypes.bool,
};

export default Navbar;
