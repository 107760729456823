const getLastHour = () => {
  const end = new Date();
  end.setHours(23, 0, 0, 0);
  return end;
};

export const PromotionModel = {
  picture: null,
  displayFeed: false,
  isArchieved: false,
  status: 'pending',
  statusUser: 'active',
  name: '',
  originalPrice: '',
  discount: '0',
  okrooCommission: '0',
  salesPrice: '0',
  venueProfit: '0',
  description: '',
  createdDate: new Date(),
  totalSales: '0',
  totalRedeem: '0',
  remainRedeem: '0',
  validDate: getLastHour(),
  validDays: [],
  tags: '',
  user: '',
  notifications: [],
  location: null,
};
