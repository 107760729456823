import React from 'react';
import View from 'react-flux-state';
import DatePicker from 'react-date-picker';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBAnimation, MDBBtn } from 'mdbreact';
import { FormGroup, Input } from 'reactstrap';
import SidebarComponent from '../../../components/SidebarComponent';
import ImgAdd from '../../../assets/img/addImage.png';
import Navbar from '../../../components/NavBar';
import { PromotionModel } from './promotion-model';
import { calculatePrice, calculateVenueProfit } from './promotion-utils';
import ModalComponent from '../../../components/ModalComponent';
import {
  promotionStore,
  PROMOTION_EVENT,
  PROMOTION_ERROR_EVENT,
  FETCH_ALL_USER_EVENT,
} from './promotion-store';
import { Loader } from '../../../components/Loader';
import { createPromotion, fetchAllUsers } from './promotion-actions';
import { authStore, USER_EVENT } from '../../auth/auth-store';

class NewPromotionView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      emailUsers: [],
      modal: false,
      loading: true,
    };
  }

  componentWillMount() {
    const model = R.clone(PromotionModel);
    model.okrooCommission = '2.9';
    this.setState({
      data: model,
    });
  }

  componentDidMount() {
    this.subscribe(promotionStore, PROMOTION_EVENT, () => {
      toast.success("you're Promotion will be pending until the admin allows its sale");
      const userEvent = authStore.getState(USER_EVENT);
      if (userEvent.admin && userEvent.admin === true) {
        this.props.history.push('/promotion-administrator');
      } else {
        this.props.history.push('/promotion');
      }
    });
    this.subscribe(promotionStore, PROMOTION_ERROR_EVENT, (e) => {
      toast.error(e.messsage);
      this.props.history.push('/new-promotion');
    });
    this.subscribe(promotionStore, FETCH_ALL_USER_EVENT, (users) => {
      this.setState({
        emailUsers: users,
      });
    });
    this.setState({
      loading: false,
    });
    fetchAllUsers();
  }

  onChange = (e) => {
    e.preventDefault();
    const data = { ...this.state.data };
    data[e.target.name] = e.target.value;

    data.salesPrice = calculatePrice(data.originalPrice, data.discount ? data.discount : '0');
    data.venueProfit = calculateVenueProfit(data.salesPrice, data.okrooCommission);

    this.setState({
      data: data,
      select: true,
    });
  };

  onImageChange = (e) => {
    let reader = new FileReader();
    const data = this.state.data;
    const image = e.target.files[0];

    if (image) {
      let kiloByte = parseInt(image.size / 1024);
      if (kiloByte > 500) {
        toast.error('Image too Large');
        return false;
      }
    } else {
      toast.error('Image too Large');
      return false;
    }

    reader.onloadend = () => {
      data.picture = reader.result;
      data.image = image;
      this.setState({
        data: data,
      });
    };

    reader.readAsDataURL(image);
  };

  onFeedChange = (e) => {
    const data = this.state.data;
    data.displayFeed = e.target.checked;

    if (e.target.checked) {
      toast.info(
        'If you want to display in promotions feed, a picture of your promotion is required',
      );
    }
    this.setState({
      data: data,
    });
  };

  onDateChange = (date) => {
    const data = this.state.data;
    data.validDate = new Date(date.setHours(23, 0, 0, 0));

    this.setState({
      data,
    });
  };

  onDayChange = (e) => {
    const data = this.state.data;
    if (e.target.checked === false) {
      let index = data.validDays.indexOf(e.target.value);
      data.validDays.splice(index, 1);
    } else {
      data.validDays.push(e.target.value);
    }
  };

  onSubmit = (confirm) => {
    if (confirm) {
      const data = R.clone(this.state.data);
      data.createdDate = new Date();
      createPromotion(data);
    }
  };

  onBack = () => {
    this.props.history.push('/promotion-administrator');
  };

  validate = (values) => {
    const errors = {};
    if (!values.picture) {
      errors.picture = 'The image is necessary';
    }
    if (!values.name) {
      errors.name = 'The name is necessary';
    }
    if (values.name.length > 22) {
      errors.name = 'The name must be less than or equal to 22 characters';
    }
    // if (parseFloat(values.venueProfit) < 0.5) {
    //   errors.venueProfit = 'The venueProfit must be greater than or equal to 0.50';
    // }
    if (!values.originalPrice || parseFloat(values.originalPrice) === 0) {
      errors.originalPrice = 'The price must be greater than 0';
    }
    if (!values.user) {
      errors.user = 'The venue is necessary';
    }

    if (!values.okrooCommission || parseFloat(values.okrooCommission) < 2.9) {
      errors.okrooCommission = 'The percentage must be greater than or equal to 2.9%';
    }

    return errors;
  };

  actionValidate = () => {
    const { data } = this.state;
    const result = this.validate(data);

    this.setState({ errors: result });
  };

  render() {
    let { picture } = this.state.data;
    // const { salesPrice, validDate, venueProfit, okrooCommission } = this.state.data;
    const { salesPrice, validDate, okrooCommission } = this.state.data;
    const { emailUsers, errors, data } = this.state;
    const now = new Date();

    if (picture) {
      picture = (
        <>
          <label className="imgLabel" htmlFor="upload-photo">
            <img
              src={this.state.data.picture}
              alt="default"
              className="img-promotion"
              width="auto"
            />
          </label>
          <input
            type="file"
            name="photo"
            id="upload-photo"
            onChange={this.onImageChange}
            accept="image/*"
          />
          <small className="text-center d-flex justify-content-center">
            JPG or PNG format with a maximum of 2mb (i.e. 600x400)
          </small>
        </>
      );
    } else {
      picture = (
        <>
          <label className="Customlabel text-center" htmlFor="upload-photo">
            <img src={ImgAdd} alt="default" className="img-fluid img-label" width="20" />
          </label>
          <input
            type="file"
            name="photo"
            id="upload-photo"
            onChange={this.onImageChange}
            accept="image/*"
          />
          <small className="text-center d-flex justify-content-center">
            JPG or PNG format with a maximum of 2mb (i.e. 600x400)
          </small>
          {errors.picture && <p className="is-red">{errors.picture}</p>}
        </>
      );
    }

    return (
      <React.Fragment>
        <SidebarComponent>
          {this.state.loading === true ? (
            <Loader />
          ) : (
            <MDBAnimation type="fadeIn">
              <Navbar nameView="New Promotion" />
              <MDBContainer className="body" fluid>
                <MDBRow>
                  <MDBCol md="3" style={{ textAlign: 'center' }}>
                    {picture}
                  </MDBCol>
                  <MDBCol md="9">
                    <MDBRow>
                      <MDBCol>
                        <MDBInput
                          label="Name"
                          name="name"
                          className="mt-0 form-control"
                          onChange={this.onChange}
                          maxLength="22"
                          required
                        />
                        {errors.name && <p className="is-red">{errors.name}</p>}
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="4" sm="4">
                        <MDBInput
                          label="Original Price"
                          name="originalPrice"
                          className="mt-0"
                          onChange={this.onChange}
                          icon="dollar-sign"
                          type="number"
                          min="0"
                          required
                        />
                        {errors.originalPrice && <p className="is-red">{errors.originalPrice}</p>}
                      </MDBCol>
                      <MDBCol md="4" sm="4">
                        <MDBInput
                          label="Discount"
                          name="discount"
                          className="mt-0"
                          onChange={this.onChange}
                          icon="percentage"
                          min="0"
                          max="100"
                          type="number"
                          maxLength="3"
                        />
                      </MDBCol>
                      <MDBCol md="4" sm="4">
                        <MDBInput
                          label="Customer Price"
                          className="mt-0 total-price"
                          value={salesPrice}
                          icon="dollar-sign"
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="7" xl="7" lg="7" sm="7">
                        <div className="d-flex">
                          <MDBInput
                            label="Okroo commission"
                            name="okrooCommission"
                            className="mt-0"
                            onChange={this.onChange}
                            icon="percentage"
                            value={okrooCommission}
                            type="number"
                            maxLength="3"
                          />
                        </div>
                        {errors.okrooCommission && (
                          <p className="is-red">{errors.okrooCommission}</p>
                        )}
                      </MDBCol>
                      {/* <MDBCol md="5" xl="5" lg="5" sm="5">
                        <MDBInput
                          label="Total venue profit"
                          className="mt-0 total-price"
                          value={venueProfit}
                          icon="dollar-sign"
                        />
                        {errors.venueProfit && <p className="is-red">{errors.venueProfit}</p>}
                      </MDBCol> */}
                    </MDBRow>
                    <MDBRow>
                      <MDBCol>
                        <p className="font-weight-bold">Promotion valid until: </p>
                        <DatePicker
                          className="mt-0"
                          value={validDate}
                          onChange={this.onDateChange}
                          minDate={now}
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      </MDBCol>
                      <MDBCol>
                        <div>
                          <h6 className="font-weight-bold mb-2">Days promotion will be shown</h6>
                        </div>
                        <div className="d-flex">
                          <div className="text-center mr-3 ">
                            <h6>SUN</h6>
                            <label className="checkbox-container">
                              <small>
                                <input type="checkbox" value="SUN" onChange={this.onDayChange} />
                                <span className="custom-checkbox" />
                              </small>
                            </label>
                          </div>
                          <div className="text-center mr-3 ">
                            <h6>MON</h6>
                            <label className="checkbox-container">
                              <small>
                                <input type="checkbox" value="MON" onClick={this.onDayChange} />
                                <span className="custom-checkbox" />
                              </small>
                            </label>
                          </div>
                          <div className="text-center mr-3 ">
                            <h6>TUE</h6>
                            <label className="checkbox-container">
                              <small>
                                <input type="checkbox" value="TUE" onChange={this.onDayChange} />
                                <span className="custom-checkbox" />
                              </small>
                            </label>
                          </div>
                          <div className="text-center mr-3 ">
                            <h6>WED</h6>
                            <label className="checkbox-container">
                              <small>
                                <input type="checkbox" value="WED" onChange={this.onDayChange} />
                                <span className="custom-checkbox" />
                              </small>
                            </label>
                          </div>
                          <div className="text-center mr-3 ">
                            <h6>THU</h6>
                            <label className="checkbox-container">
                              <small>
                                <input type="checkbox" value="THU" onChange={this.onDayChange} />
                                <span className="custom-checkbox" />
                              </small>
                            </label>
                          </div>
                          <div className="text-center mr-3 ">
                            <h6>FRI</h6>
                            <label className="checkbox-container">
                              <small>
                                <input type="checkbox" value="FRI" onChange={this.onDayChange} />
                                <span className="custom-checkbox" />
                              </small>
                            </label>
                          </div>
                          <div className="text-center mr-3 ">
                            <h6>SAT</h6>
                            <label className="checkbox-container">
                              <small>
                                <input type="checkbox" value="SAT" onChange={this.onDayChange} />
                                <span className="custom-checkbox" />
                              </small>
                            </label>
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol>
                        <h6 className="font-weight-bold">Description</h6>
                        <FormGroup>
                          <Input
                            type="textarea"
                            name="description"
                            onChange={this.onChange}
                            maxLength="95"
                          />
                        </FormGroup>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="6">
                        <br />
                        <MDBInput
                          label="#Tags"
                          name="tags"
                          className="mt-0"
                          onChange={this.onChange}
                        />
                      </MDBCol>
                      <MDBCol md="6">
                        <br />
                        <select
                          className="browser-default custom-select mt-1"
                          name="user"
                          onChange={this.onChange}
                          required>
                          <option selected disabled>
                            Venue Name
                          </option>
                          {emailUsers.map((value) => (
                            <option key={value.email} value={value.email}>
                              {value.name}
                            </option>
                          ))}
                        </select>
                        {errors.user && <p className="is-red mt-2">{errors.user}</p>}
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="mt-4">
                      <MDBCol className="display-feed">
                        <div className="check-feed">
                          <label className="checkfeed-container">
                            <small>
                              <input type="checkbox" value="Monday" onClick={this.onFeedChange} />
                              <span className="feed-checkbox" />
                              <p className="font-weight-bold">Display in promotion feed</p>
                            </small>
                          </label>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol className="text-center">
                        <MDBBtn
                          color="primary"
                          style={{ padding: '5px 17px' }}
                          className="btn btn-default Ripple-parent btn btn-circle mt-4 mb-5"
                          onClick={() => this.onBack()}>
                          Back
                        </MDBBtn>
                        <ModalComponent
                          onSubmit={this.onSubmit}
                          picture={this.state.data.picture}
                          displayFeed={this.state.data.displayFeed}
                          flag={!Object.keys(this.validate(data)).length}
                          actionValidate={this.actionValidate}
                        />
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBAnimation>
          )}
        </SidebarComponent>
      </React.Fragment>
    );
  }
}

export default NewPromotionView;
