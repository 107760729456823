import firebase from 'firebase';
import Flux from 'flux-state';
import {
  PROMOTION_ERROR_EVENT,
  FETCH_PROMOTION_ADMIN_EVENT,
  CHANGE_PROMOTION_EVENT,
  CHANGE_RANK_PROMOTION_EVENT,
} from './admin-store';

export const fetchPromotions = () => {
  const DB = firebase.firestore();
  const promotionsCollection = DB.collection('promotions');
  let data = [];
  promotionsCollection
    .orderBy('createdDate', 'desc')
    .get()
    .then((promo) => {
      promo.forEach((doc) => {
        const promotion = doc.data();
        // if (promotion.displayFeed === true) {
        const {
          picture,
          displayFeed,
          status,
          statusDate,
          statusUser,
          name,
          originalPrice,
          discount,
          salesPrice,
          description,
          createdDate,
          validDate,
          validDays,
          totalSales,
          totalRedeem,
          tags,
          user,
          rank,
        } = promotion;
        data.push({
          picture,
          displayFeed,
          status,
          statusDate,
          statusUser,
          name,
          originalPrice,
          discount,
          salesPrice,
          description,
          createdDate,
          validDate,
          validDays,
          totalSales,
          totalRedeem,
          tags,
          user,
          promoID: doc.id,
          rank,
        });
        // }
      });
      fetchPromotionsEnds(data);
      // Flux.dispatchEvent(FETCH_PROMOTION_ADMIN_EVENT, data);
    })
    .catch((e) => {
      console.log('Error getting documents', e);
      Flux.dispatchEvent(PROMOTION_ERROR_EVENT, e);
    });
};

export const fetchPromotionsEnds = async (data) => {
  const DB = firebase.firestore();
  const usersCollection = DB.collection('users');

  let usersData = [];
  let users = [];
  try {
    usersData = await usersCollection.get();
  } catch (err) {
    Flux.dispatchEvent(PROMOTION_ERROR_EVENT, err);
  }

  usersData.forEach((element) => {
    users.push(element.data());
  });

  data.forEach((doc) => {
    if (users.find((element) => element.email === doc.user)) {
      const user = users.find((element) => element.email === doc.user);
      doc.nameUser = user.name;
    }
  });

  Flux.dispatchEvent(FETCH_PROMOTION_ADMIN_EVENT, data);
};

export const activePromo = async (data, id) => {
  const DB = firebase.firestore();
  const promotionsRef = DB.collection('promotions').doc(id);
  let promorionData;
  const { status } = data;

  let toDay;

  try {
    promorionData = await promotionsRef.get();
  } catch (err) {
    return Flux.dispatchEvent(PROMOTION_ERROR_EVENT, err);
  }

  if (status === 'active') {
    toDay = Date.now();
  } else {
    toDay = promorionData.data().statusDate;
  }

  try {
    promotionsRef.set(
      {
        status,
        statusDate: toDay,
      },
      {
        merge: true,
      },
    );
  } catch (error) {
    return Flux.dispatchEvent(PROMOTION_ERROR_EVENT, error);
  }

  return Flux.dispatchEvent(CHANGE_PROMOTION_EVENT);
};

export const changePromoRank = async (rankValue, id) => {
  const DB = firebase.firestore();
  const promotionsRef = DB.collection('promotions').doc(id);

  try {
    await promotionsRef.get();
  } catch (err) {
    return Flux.dispatchEvent(PROMOTION_ERROR_EVENT, err);
  }

  try {
    promotionsRef.set(
      {
        rank: rankValue,
      },
      {
        merge: true,
      },
    );
  } catch (error) {
    return Flux.dispatchEvent(PROMOTION_ERROR_EVENT, error);
  }

  return Flux.dispatchEvent(CHANGE_RANK_PROMOTION_EVENT);
};
