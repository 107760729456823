import React from 'react';
import View from 'react-flux-state';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBBadge,
  MDBAnimation,
  MDBIcon,
} from 'mdbreact';
import { toast } from 'react-toastify';
import { profileStore, FETCH_SALES_EVENT } from '../profile/profile-store';
import { fetchTotalSales } from '../profile/profile-actions';
import { fetchDetailPromo, activePromoDetail } from './promotion-actions';
import { promotionStore, PROMOTION_DETAIL_EVENT, CHANGE_PROMOTION_EVENT } from './promotion-store';
import SidebarComponent from '../../../components/SidebarComponent';
import {
  promotionRedeemStore,
  FETCH_REEDEM_EVENT,
  REEDEM_ERROR_EVENT,
} from '../promotion-reedem/promotionRedeem-store';
import { fetchExchanges } from '../promotion-reedem/promotionRedeem-action';
import Navbar from '../../../components/NavBar';
import { Loader } from '../../../components/Loader';
import { orderWeek } from './promotion-utils';

class DetailPromotionView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loading: true,
      totalSales: 0,
      exchanges: [],
      totalRedeemed: 0,
      exchangeRemaining: 0,
      purchasedQuantity: 0,
    };
  }

  componentDidMount() {
    this.subscribe(promotionStore, PROMOTION_DETAIL_EVENT, (data) => {
      data.originalPrice = parseFloat(data.originalPrice);
      data.salesPrice = parseFloat(data.salesPrice);
      this.setState(
        {
          data: data,
          loading: false,
        },
        () => {
          fetchExchanges(data.user, data.id);
          fetchTotalSales(data.user, data.id);
        },
      );
    });
    this.subscribe(promotionStore, CHANGE_PROMOTION_EVENT, () => {
      // this.props.history.push(`/promotion-administrator`);
    });
    this.subscribe(profileStore, FETCH_SALES_EVENT, (data) => {
      const exchRemainingCount = data
        .map((res) => res.quantityAvailable)
        .reduce((a, b) => a + b, 0);

      const totalRedeemed = data.map((res) => res.redeem).reduce((a, b) => a + b, 0);
      const purchasedCount = data.map((res) => res.purchasedQuantity).reduce((a, b) => a + b, 0);
      this.setState({
        totalSales: data.length,
        loading: false,
        totalRedeemed,
        purchasedQuantity: purchasedCount,
        exchangeRemaining: exchRemainingCount,
      });
    });
    this.subscribe(promotionRedeemStore, FETCH_REEDEM_EVENT, (data) => {
      this.setState({
        exchanges: data,
        loading: false,
      });
    });
    this.subscribe(promotionRedeemStore, REEDEM_ERROR_EVENT, (e) => {
      toast.error(e.message);
    });
    fetchDetailPromo(this.props.match.params.id);
  }

  pausedPromotion = () => {
    const data = this.state.data;

    if (data.status === 'active') {
      data.status = 'pending';
    } else if (data.status === 'pending') {
      data.status = 'active';
    }
    this.setState({
      loading: false,
    });

    activePromoDetail(data, this.props.match.params.id);
  };

  render() {
    const {
      id,
      picture,
      name,
      // status,
      originalPrice,
      salesPrice,
      okrooCommission,
      venueProfit,
      discount,
      description,
      validDate,
      validDays,
      tags,
      notifications,
    } = this.state.data;
    let dateValid;
    let daysValid;

    if (validDays === undefined) {
      daysValid = <h6 className="mr-4">0</h6>;
    } else {
      const validDaysAux = [];
      orderWeek.forEach((elem1, i) => {
        validDays.forEach((elem2, i) => {
          if (elem2 === elem1) {
            validDaysAux.push(elem2);
          }
        });
      });

      daysValid = validDaysAux.map((day) => {
        return (
          <h6 style={{ padding: '5px', borderStyle: 'groove' }} key={day}>
            {day}
          </h6>
        );
      });
    }

    if (validDate) {
      dateValid = (
        <small className="text-black-50">
          {moment.unix(validDate.seconds, 'DD/MM/YYYY').format('LL')}
        </small>
      );
    } else {
      dateValid = <small className="text-black-50">DD/MM/YYYY</small>;
    }

    const pricePromo = `${
      parseInt(discount) > 0 && parseInt(okrooCommission) === 0 ? 'Final ' : ''
    }Price to diplay ${parseInt(discount) > 0 ? '(with discount)' : ''}:`;

    return (
      <React.Fragment>
        <SidebarComponent>
          <MDBAnimation type="fadeIn">
            <Navbar nameView="Promotion Details" />
            <MDBContainer className="body">
              {this.state.loading === true ? (
                <Loader />
              ) : (
                <>
                  <MDBRow>
                    <MDBCol md="3">
                      <div
                        className="img-promotion-detail"
                        style={{ backgroundImage: `url(${picture})` }}
                      />
                    </MDBCol>
                    <MDBCol md="9">
                      <MDBRow>
                        <MDBCol md="8">
                          <h2 className="font-weight-bold mb-2">{name}</h2>
                          <div className="d-flex mb-3">
                            <div className="mr-5">
                              <h6 className="font-weight-bold">{pricePromo}</h6>
                              <small className="text-black-50">
                                $ {salesPrice ? salesPrice.toFixed(2) : '0'}
                              </small>
                            </div>
                            <div className="ml-5">
                              <h6 className="font-weight-bold">Original Price:</h6>
                              <small className="text-black-50">
                                $ {originalPrice ? originalPrice.toFixed(2) : '0'}
                              </small>
                            </div>
                          </div>
                          <div className="d-flex mb-3">
                            <div className="mr-5">
                              <h6 className="font-weight-bold">Promotion valid until</h6>
                              {dateValid}
                            </div>
                            {parseInt(okrooCommission) > 0 ? (
                              <div className="ml-5">
                                <h6 className="font-weight-bold">Venue profit:</h6>$ {venueProfit}
                                <p className="small text-black-50">
                                  The amount shown is approximate and can vary according to the fees
                                  of the payment platform, for more information follow this{' '}
                                  <a
                                    href="https://stripe.com/en-gb-us/pricing"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    link
                                  </a>
                                  .
                                </p>
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <h6 className="font-weight-bold">Description</h6>
                            <p>{description}</p>
                          </div>
                          <div className="d-flex mb-3">
                            <h5 className="mr-4">
                              <MDBBadge pill color="warning">
                                {discount}% Off
                              </MDBBadge>
                            </h5>
                            <h5 className="mr-4">
                              <MDBBadge pill color="warning">
                                {okrooCommission}% Okroo + $0.30
                              </MDBBadge>
                            </h5>
                          </div>
                          <h6 className="font-weight-bold">Day(s) of promotion</h6>
                          <div className="d-flex mb-4">{daysValid}</div>

                          <h6 className="font-weight-bold">#Tag</h6>
                          <div className="d-flex mb-4">
                            <small className="mr-2">{tags}</small>
                          </div>
                          <div className="text-center mt-4 mb-4">
                            {/* {pauseBtn} */}
                            <Link
                              to={`/promotion-administrator`}
                              className="btn btn-circle btn-circle-link">
                              Back
                            </Link>
                          </div>
                        </MDBCol>
                        <MDBCol md="4">
                          <MDBCard className="mb-3">
                            <MDBCardBody className="p-2">
                              <MDBCardText className="text-center">Total Sales</MDBCardText>
                              <MDBCardTitle className="text-center">
                                {this.state.totalSales}
                              </MDBCardTitle>
                            </MDBCardBody>
                          </MDBCard>
                          <MDBCard className="mb-3">
                            <MDBCardBody className="p-2">
                              <MDBCardText className="text-center">Quantity Sold</MDBCardText>
                              <MDBCardTitle className="text-center">
                                {this.state.purchasedQuantity}
                              </MDBCardTitle>
                            </MDBCardBody>
                          </MDBCard>
                          <MDBCard className="mb-3">
                            <MDBCardBody className="p-2">
                              <MDBCardText className="text-center">
                                Redemption Remaining
                              </MDBCardText>
                              <MDBCardTitle className="text-center">
                                {this.state.exchangeRemaining}
                              </MDBCardTitle>
                            </MDBCardBody>
                          </MDBCard>
                          <MDBCard>
                            <MDBCardBody className="p-2">
                              <MDBCardText className="text-center">Total Redeemed</MDBCardText>
                              <MDBCardTitle className="text-center">
                                {this.state.totalRedeemed}
                              </MDBCardTitle>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="3"></MDBCol>
                    <MDBCol md="9">
                      <MDBCol md="8">
                        <hr />
                        <div className="text-center d-flex flex-column">
                          <div>Firebase Code</div>
                          <div>{id}</div>
                        </div>
                        {notifications !== undefined ? (
                          notifications.map((item, index) => (
                            <div key={index} className="d-flex align-items-center">
                              {item.like ? (
                                <MDBIcon className="mr-1" icon="thumbs-up" />
                              ) : (
                                <MDBIcon className="mr-1" icon="thumbs-down" />
                              )}
                              <div>
                                <h6 className="font-weight-bold mb-0">{item.userName}</h6>
                                <p
                                  style={{
                                    fontSize: '10px',
                                    fontStyle: 'oblique',
                                    margin: '0px 0px 5px 0px',
                                  }}>
                                  {moment.unix(item.date.seconds, 'DD/MM').format('LL')}
                                </p>
                                <p
                                  style={{
                                    fontSize: '13px',
                                    fontStyle: 'oblique',
                                    margin: '0px 0px 7px 0px',
                                  }}>
                                  {item.description}
                                </p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <></>
                        )}
                      </MDBCol>
                      <MDBCol md="4"></MDBCol>
                    </MDBCol>
                  </MDBRow>
                </>
              )}
            </MDBContainer>
          </MDBAnimation>
        </SidebarComponent>
      </React.Fragment>
    );
  }
}

export default DetailPromotionView;
